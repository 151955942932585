import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/permission-guard/RoleGuard';
import getMenuItems from 'menu-items';

// sample page routing

const OrderManagePage = Loadable(lazy(() => import('views/pages/order/OrderManagePage')));

const UserNotificationsPage = Loadable(lazy(() => import('views/pages/user/UserNotificationsPageOld')));
const UserManagePage = Loadable(lazy(() => import('views/pages/user/UserManagePage')));
const UserAddPage = Loadable(lazy(() => import('views/pages/user/UserAddPage')));

const ClientManagePage = Loadable(lazy(() => import('views/pages/client/ClientManagePage')));
const ClientAddPage = Loadable(lazy(() => import('views/pages/client/ClientAddPage')));

const ShippingManagePage = Loadable(lazy(() => import('views/pages/shipping/ShippingManagePage')));

const MountingSchedulerPage = Loadable(lazy(() => import('views/pages/mountingScheduler/MountingSchedulerPage')));

const VersionLogPage = Loadable(lazy(() => import('views/pages/version/VersionLogPage')));

const ChatPage = Loadable(lazy(() => import('views/pages/chat/ChatPage')));

const NotificationsPage = Loadable(lazy(() => import('views/pages/user/UserNotificationsPage')));

// ==============================|| MAIN ROUTING ||============================== //

const getMainRoutes = async () => {
    const menuItems = await getMenuItems();

    const routes = {
        path: '/',
        element: (
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        ),
        children: [
            // Order routes
            {
                path: '/order/:orderId',
                element: <OrderManagePage />
            },
            // Notification routes
            {
                path: '/user/notificationsOld',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <UserNotificationsPage />
                    </RoleGuard>
                )
            },
            // User routes
            {
                path: '/user/manage/:userId',
                element: (
                    <RoleGuard isAdminOnly>
                        <UserManagePage />
                    </RoleGuard>
                )
            },
            {
                path: '/user/manage',
                element: <UserManagePage />
            },
            {
                path: 'user/add',
                element: (
                    <RoleGuard isAdminOnly>
                        <UserAddPage />
                    </RoleGuard>
                )
            },
            // Client routes
            {
                path: '/client/manage/:clientId',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <ClientManagePage />
                    </RoleGuard>
                )
            },
            {
                path: '/client/add',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <ClientAddPage />
                    </RoleGuard>
                )
            },
            // Shipping routes
            {
                path: '/shipping/:shippingId',
                element: (
                    <RoleGuard probhitedRoles={['client', 'mounting_worker']}>
                        <ShippingManagePage />
                    </RoleGuard>
                )
            },
            // Mounting routes
            {
                path: '/mounting/scheduler',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <MountingSchedulerPage />
                    </RoleGuard>
                )
            },
            {
                path: '/mounting/scheduler/:calendarId',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <MountingSchedulerPage />
                    </RoleGuard>
                )
            },
            {
                path: '/chat',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <ChatPage />
                    </RoleGuard>
                )
            },
            {
                path: '/chat/:orderId',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <ChatPage />
                    </RoleGuard>
                )
            },
            {
                path: '/notifications',
                element: (
                    <RoleGuard probhitedRoles={['client']}>
                        <NotificationsPage />
                    </RoleGuard>
                )
            },
            // Version Log Routes
            { path: '/version', element: <VersionLogPage /> },
            // Dynamic routes
            ...menuItems.items
                .filter((item) => item.routes !== false)
                .flatMap((item) => {
                    if (item.children) {
                        return item.children.map((children) => {
                            if ('isAdminOnly' in children && children.isAdminOnly) {
                                return {
                                    path: children.url,
                                    element: <RoleGuard isAdminOnly={children.isAdminOnly}>{children.component!}</RoleGuard>
                                };
                            } else if ('allowedRoles' in children) {
                                return {
                                    path: children.url,
                                    element: <RoleGuard allowedRoles={children.allowedRoles!}>{children.component!}</RoleGuard>
                                };
                            } else if ('probhitedRoles' in children) {
                                return {
                                    path: children.url,
                                    element: <RoleGuard probhitedRoles={children.probhitedRoles!}>{children.component!}</RoleGuard>
                                };
                            } else {
                                return {
                                    path: children.url,
                                    element: <>{children.component!}</>
                                };
                            }
                        });
                    } else {
                        if ('allowedRoles' in item) {
                            return {
                                path: item.url,
                                element: <RoleGuard allowedRoles={item.allowedRoles!}>{item.component!}</RoleGuard>
                            };
                        } else if ('probhitedRoles' in item) {
                            return {
                                path: item.url,
                                element: <RoleGuard probhitedRoles={item.probhitedRoles!}>{item.component!}</RoleGuard>
                            };
                        } else {
                            return {
                                path: item.url,
                                element: <>{item.component!}</>
                            };
                        }
                    }
                })
        ]
    };
    return routes;
};

export default getMainRoutes;
