import { Button, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import Swal from 'sweetalert2';
import {
    useDeleteOrderFilesSurveyMutation,
    useFetchOrderFilesFromSurveyQuery,
    useLazyDownloadAllSurveyFilesQuery,
    useUploadOrderFilesFromSurveyMutation
} from 'store/api/orders.api';
import Cover from 'ui-component/Cover';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';
import { OrderFileDisplay } from './OrderFileDisplayWithAdditions';
import { useDropzone } from 'react-dropzone';
import AnimateButton from '../extended/AnimateButton';
import { downloadUrl } from 'helpers/files.helper';
import { useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import { handlePermissionCheck } from 'helpers/global.helper';

const OrderSurveyFiles = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);

    const { data: orderFiles, isFetching: isFetchingFiles } = useFetchOrderFilesFromSurveyQuery({ orderId: orderId! }, { skip: !orderId });
    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    const [deleteFile, { isLoading: isDeleting }] = useDeleteOrderFilesSurveyMutation();
    const [uploadFile, { isLoading: isUploading }] = useUploadOrderFilesFromSurveyMutation();
    const [downloadAllFiles] = useLazyDownloadAllSurveyFilesQuery();

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop: (acceptedFiles) => {
            uploadFile({ orderId: orderId!, uploadedFiles: acceptedFiles, userId: user?.id! });
        },
        noClick: true
    });

    // const fn = async (data) =>
    //     await toast.promise(
    //         changeFactoryFileStatus({
    //             comment: data.comment,
    //             fileId: improvedFile.id,
    //             orderId,
    //             status: 'disproved',
    //             uploadedFiles: data.files,
    //             userId: user?.id!
    //         }),
    //         { pending: getMessage('uploadPending'), success: getMessage('uploaded') },
    //         { autoClose: 1000 }
    //     );

    if (!orderId) return <Cover />;

    return (
        <Stack direction={'column'} {...getRootProps()} sx={{ border: '1px dashed rgba(0,0,0,0.2)', p: 1 }}>
            <Stack gap={2} direction={{ xs: 'column', sm: 'row' }} sx={{ flexWrap: 'wrap', minHeight: 100 }}>
                <input {...getInputProps()} />
                {orderFiles &&
                    orderFiles.length > 0 &&
                    orderFiles.map((file) => {
                        return (
                            <Stack
                                gap={2}
                                direction={{ xs: 'column', sm: 'row' }}
                                sx={{
                                    p: 2,
                                    borderRadius: 2,
                                    height: '100%',
                                    opacity: isUploading || isFetchingFiles || isDeleting ? 0 : 1,
                                    width: { xs: '100%', sm: 'initial' }
                                }}
                            >
                                <OrderFileDisplay
                                    file={file}
                                    sx={{ width: { sx: '100%', sm: 160, md: 200 } }}
                                    handleDelete={() =>
                                        Swal.fire({
                                            title: `Are you sure you want to delete the file?`,
                                            text: 'Once deleted, you will not be able to recover those files',
                                            icon: 'warning',
                                            showCancelButton: true
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                deleteFile({ fileId: file.id, orderId, userId: user?.id! });
                                            }
                                        })
                                    }
                                />
                            </Stack>
                        );
                    })}
                {(isUploading || isFetchingFiles || isDeleting) && (
                    <CircularProgress
                        sx={{
                            opacity: '1',
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                )}
            </Stack>
            <Stack
                direction={{ xs: 'row', sm: 'row' }}
                sx={{ justifyContent: { xs: 'center', sm: 'start' }, alignItems: { xs: 'center', sm: 'initial' } }}
                gap={2}
            >
                {(handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') || user?.role === 'mounting_worker') && (
                    <AnimateButton>
                        <Button variant={'contained'} onClick={open}>
                            Upload a file
                        </Button>
                    </AnimateButton>
                )}
                <Button
                    variant={'contained'}
                    disabled={orderFiles?.length === 0}
                    onClick={async () => {
                        const file = await downloadAllFiles({ orderId }).then((result) => result.data?.file);
                        if (file) downloadUrl({ src: file, name: 'files' });
                    }}
                >
                    Download all
                </Button>
            </Stack>
        </Stack>
    );
};

export default OrderSurveyFiles;
