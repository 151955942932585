import { Box, BoxProps, Typography } from '@mui/material';
import { handleGetStatusColor } from 'helpers/orders.helper';
import { isUserSuperAdmin } from 'helpers/users.helper';
import useAuth from 'hooks/useAuth';
import { atom, useAtom } from 'jotai';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DataGrid, { Column, DataGridHandle, RowsChangeData, SelectColumn, SortColumn } from 'react-data-grid';
import { Link } from 'react-router-dom';
import { useFetchClientsQuery } from 'store/api/clients.api';
import { useFetchUsersQuery } from 'store/api/user.api';
import { CellExpanderFormatter } from 'ui-component/tables/CellExpanderFormatter';
import { useImmer } from 'use-immer';
import { ShippingGetOrders, useLazyFetchShippingProjectsOrdersQuery } from '../../store/api/orders.api';
import { Shipping, ShippingProject } from '../../types/orders.types';

// Display Mode Disables:
//     Selection
//     Filters
//     Deletions
//     Sorting
//     Order Slider

interface Props extends BoxProps {
    tableData: ShippingGetOrders;
    enableDisplayMode?: boolean;
    virtualized?: boolean;
}

export const filterCriteriaAtom = atom<{
    shippingNum: string;
    factory: string;
    country: string;
    status: string;
}>({
    shippingNum: '',
    factory: 'All',
    country: 'All',
    status: 'All'
});

export const selectedRowsAtom = atom<Set<string> | null>(null);

type ShippingRow =
    | {
          type: 'MASTER';
          expanded: boolean;
          shipping: Shipping;
      }
    | {
          type: 'DETAIL';
          shipping: Shipping;
      };

const ShippingTable = ({ tableData, enableDisplayMode, virtualized, ...props }: Props) => {
    const createRows = (): ShippingRow[] => {
        const shippings: ShippingRow[] = [];
        for (let i = 0; i < tableData.rows.length; i++) shippings.push({ expanded: false, shipping: tableData.rows[i], type: 'MASTER' });
        return shippings;
    };

    const [data, setData] = useImmer<ShippingRow[]>(createRows);
    const [filteredData, setFilteredData] = useImmer<ShippingRow[]>([]);

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([{ columnKey: 'shippingNum', direction: 'ASC' }]);
    const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
        setSortColumns(sortColumns.slice(-1));
    }, []);

    const [fetchShippingProjects] = useLazyFetchShippingProjectsOrdersQuery();

    const [selectedRows, setSelectedRows] = useAtom(selectedRowsAtom);
    const [filterCriteria, setFilterCriteria] = useAtom(filterCriteriaAtom);

    const column: readonly Column<ShippingRow>[] = [
        {
            ...SelectColumn,
            cellClass: ({ type }) => (type === 'DETAIL' ? 'hidden-select' : '')
        },
        {
            key: 'expanded',
            name: '',
            minWidth: 30,
            width: 30,
            sortable: true,
            cellClass: ({ type }) => (type === 'DETAIL' ? 'expanded-class' : ''),
            colSpan(args) {
                return args.type === 'ROW' && args.row.type === 'DETAIL' ? 8 : undefined;
            },
            formatter({ row, rowIdx, onRowChange }) {
                if (row.type === 'DETAIL') {
                    return <DetailsGrid shipping={row.shipping} />;
                }

                return (
                    <CellExpanderFormatter
                        expanded={row.expanded}
                        tabIndex={row.shipping.shippingID}
                        onCellExpand={() => {
                            let requestData = {
                                addedProjects: row.shipping.addedProjects,
                                shippingId: row.shipping.shippingID
                            };

                            if (!row.expanded)
                                fetchShippingProjects(requestData).then((res) => {
                                    if (res.data) {
                                        onRowChange({
                                            ...row,
                                            expanded: !row.expanded,
                                            shipping: { ...row.shipping, projects: res.data!.projects }
                                        });
                                    }
                                });
                            else
                                onRowChange({
                                    ...row,
                                    expanded: !row.expanded
                                });
                        }}
                    />
                );
            }
        },
        {
            key: 'shippingNum',
            name: 'No',
            sortable: true,
            formatter(props) {
                const value = props.row.shipping;
                return (
                    <Typography
                        variant="body1"
                        display={'inline'}
                        fontWeight={600}
                        lineHeight={'20px'}
                        sx={{
                            my: 'auto',
                            cursor: 'pointer',
                            textDecoration: 'inherit',
                            color: 'inherit'
                        }}
                        component={Link}
                        to={`/shipping/${value.shippingID}`}
                    >
                        {value.shippingNum}
                    </Typography>
                );
            }
        },
        {
            key: 'factory',
            name: 'Factory',
            sortable: true,
            formatter(props) {
                const value = props.row.shipping;
                return value.factory && value.factory.abbr ? <>{value.factory.abbr}</> : <>N/A</>;
            }
        },
        {
            key: 'country',
            name: 'Country',
            sortable: true,
            formatter(props) {
                const value = props.row.shipping;
                return value.country && value.country.abbr ? <>{value.country.abbr}</> : <>N/A</>;
            }
        },
        {
            key: 'projectNum',
            name: 'Projects',
            sortable: true,
            formatter(props) {
                const value = props.row.shipping;
                return <>{value.projectNum}</>;
            }
        },
        {
            key: 'dispatchment',
            name: 'Dispatchment',
            sortable: true,
            formatter(props) {
                const value = props.row.shipping;
                return value.dispatchment ? <>{moment(value.dispatchment, 'X').format()}</> : <>N/A</>;
            }
        },
        {
            key: 'delivery',
            name: 'Delivery',
            sortable: true,
            formatter(props) {
                const value = props.row.shipping;
                return value.delivery ? <>{moment(value.delivery, 'X').format()}</> : <>N/A</>;
            }
        },
        {
            key: 'status',
            name: 'Status',
            sortable: true,
            formatter(props) {
                const value = props.row.shipping;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <Typography
                            sx={{
                                bgcolor: handleGetStatusColor(value.status_val),
                                color: 'white',
                                borderRadius: '50px',
                                textTransform: 'capitalize',
                                fontSize: '12px !important',
                                wordWrap: 'none',
                                wordBreak: 'keep-all',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                padding: '2px 10px',
                                maxWidth: 150,
                                width: '100%'
                            }}
                        >
                            {value.status}
                        </Typography>
                    </Box>
                );
            }
        }
    ];

    const sortedRows = useMemo((): readonly ShippingRow[] => {
        if (sortColumns.length === 0) return filteredData;
        const { columnKey, direction } = sortColumns[0];

        let sortedRows: ShippingRow[] = [...filteredData];

        switch (columnKey) {
            case 'shippingNum':
                sortedRows = sortedRows.sort((a, b) => {
                    return parseInt(a.shipping[columnKey]) > parseInt(b.shipping[columnKey]) ? -1 : 1;
                });
                break;
            case 'factory':
                sortedRows = sortedRows.sort((a, b) => {
                    return a.shipping['factoryId'].toString().localeCompare(b.shipping['factoryId'].toString());
                });
                break;
            case 'country':
                sortedRows = sortedRows.sort((a, b) => {
                    return a.shipping['countryId'] > b.shipping['countryId'] ? 1 : -1;
                });
                break;
            case 'projectNum':
                sortedRows = sortedRows.sort((a, b) => {
                    return a.shipping[columnKey] > b.shipping[columnKey] ? 1 : -1;
                });
                break;
            case 'status':
                sortedRows = sortedRows.sort((a, b) => {
                    return a.shipping[columnKey].localeCompare(b.shipping[columnKey]);
                });
                break;
            case 'dispatchment':
                sortedRows = sortedRows.sort((a, b) => {
                    return a.shipping[columnKey] > b.shipping[columnKey] ? 1 : -1;
                });
                break;
            case 'delivery':
                sortedRows = sortedRows.sort((a, b) => {
                    return a.shipping[columnKey].toString().localeCompare(b.shipping[columnKey].toString());
                });
                break;
            default:
        }
        return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
    }, [filteredData, sortColumns]);

    useEffect(() => {
        if (!data) return;
        // Combine the filtering logic here using both filter criteria
        const filtered = data.filter((item) => {
            const searchTerm = filterCriteria.shippingNum.toLowerCase();
            const shippingNum = item.shipping.shippingNum.toLowerCase();
            const dispatchment = moment(item.shipping.dispatchment, 'X').format('DD/MM/YYYY');
            const factory = filterCriteria.factory;
            const country = filterCriteria.country;
            const status = filterCriteria.status;

            return (
                ((shippingNum && shippingNum.includes(searchTerm)) || (dispatchment && dispatchment.includes(searchTerm))) &&
                (factory === 'All' || item.shipping.factoryId.toString() === factory) &&
                (country === 'All' || (item.shipping.countryId && item.shipping.countryId.toString() === country)) &&
                (status === 'All' || item.shipping.status_val === status)
            );
        });

        setFilteredData(filtered);
    }, [filterCriteria, data]);

    const onRowsChange = (rows: ShippingRow[], { indexes }: RowsChangeData<ShippingRow>) => {
        const row = rows[indexes[0]];
        if (row.type === 'MASTER') {
            if (row.expanded) {
                rows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    shipping: row.shipping
                });
            } else {
                rows.splice(indexes[0] + 1, 1);
            }
            setData(rows);
        }
    };

    return (
        <Box {...props} sx={{ height: 'calc(100vh - 157px)', ...props.sx }}>
            <DataGrid
                style={{
                    height: '100%',
                    scrollbarGutter: 'stable',
                    blockSize: '100%'
                }}
                columns={column}
                rows={sortedRows}
                onRowsChange={onRowsChange}
                // @ts-ignore
                rowHeight={(row) => (row.row.type === 'DETAIL' ? 70 + row.row.shipping.projects.length * 35 : 35)}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                rowKeyGetter={(row) => row.shipping.shippingID.toString()}
                className="fill-grid rdg-light"
                sortColumns={sortColumns}
                onSortColumnsChange={onSortColumnsChange}
                // @ts-ignore
                onCellKeyDown={(_: any, event: any) => {
                    if (event.isDefaultPrevented()) {
                        // skip parent grid keyboard navigation if nested grid handled it
                        event.preventGridDefault();
                    }
                }}
            />
        </Box>
    );
};

function DetailsGrid({ shipping }: { shipping: Shipping }) {
    const { user } = useAuth();

    const { data: users } = useFetchUsersQuery({ userId: user?.id! }, { skip: !user });
    const { data: clients } = useFetchClientsQuery(null);

    const shippingColumns: readonly Column<ShippingProject>[] = [
        {
            key: 'projectNum',
            name: 'Project No',
            width: 200,
            formatter: ({ row }) => {
                return (
                    <Typography
                        variant="body1"
                        display={'inline'}
                        fontWeight={600}
                        lineHeight={'20px'}
                        sx={{
                            my: 'auto',
                            cursor: 'pointer',
                            textDecoration: 'inherit',
                            color: 'inherit'
                        }}
                        component={Link}
                        to={`/order/${row.projectId}`}
                    >
                        {row.projectNum}
                    </Typography>
                );
            }
        },
        {
            key: 'owner',
            name: 'Owner',
            formatter: ({ row }) => {
                return (
                    <>
                        {user && isUserSuperAdmin(user?.role!) ? (
                            <Typography
                                variant="body1"
                                display={'inline'}
                                fontWeight={600}
                                lineHeight={'20px'}
                                sx={{
                                    my: 'auto',
                                    cursor: 'pointer',
                                    textDecoration: 'inherit',
                                    color: 'inherit'
                                }}
                                component={Link}
                                to={`/user/manage/${row.owner}`}
                            >
                                {users && users.users.find((user) => user.id === row.owner)?.real_name}
                            </Typography>
                        ) : (
                            <Typography variant="body1" display={'inline'} fontWeight={400}>
                                {users && users.users.find((user) => user.id === row.owner)?.real_name}
                            </Typography>
                        )}
                    </>
                );
            },
            width: 100
        },
        {
            key: 'client',
            name: 'Client',
            formatter: ({ row }) => {
                return (
                    <>
                        {user && isUserSuperAdmin(user?.role!) ? (
                            <Typography
                                variant="body1"
                                display={'inline'}
                                fontWeight={600}
                                lineHeight={'20px'}
                                sx={{
                                    my: 'auto',
                                    cursor: 'pointer',
                                    textDecoration: 'inherit',
                                    color: 'inherit'
                                }}
                                component={Link}
                                to={`/client/manage/${row.client}`}
                            >
                                {clients && clients.find((client) => client.id === row.client)?.title}
                            </Typography>
                        ) : (
                            <Typography variant="body1" display={'inline'} fontWeight={400}>
                                {clients && clients.find((client) => client.id === row.client)?.title}
                            </Typography>
                        )}
                    </>
                );
            }
        },
        {
            key: 'shipAddr',
            name: 'Shipping Address'
        },
        {
            key: 'projectPartialStatus',
            name: 'Shipment Status'
        }
    ];

    const gridRef = useRef<DataGridHandle>(null);

    return (
        <DataGrid
            ref={gridRef}
            rows={shipping.projects || []}
            columns={shippingColumns}
            rowKeyGetter={(row) => row.projectId}
            style={{ blockSize: 300 }}
            className="fill-grid rdg-light"
        />
    );
}

export default ShippingTable;
