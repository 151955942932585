// material-ui
import { Avatar, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { Chat, Notifications } from '@mui/icons-material';
import { IconMenu2 } from '@tabler/icons';
import config from 'config';
import { handlePermissionCheck } from 'helpers/global.helper';
import useAuth from 'hooks/useAuth';
import { capitalize } from 'lodash';
import getMenuItems from 'menu-items';
import { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFetchChatUnreadMessagesCountQuery } from 'store/api/chat.api';
import { useFetchUserNotificationsQuery } from 'store/api/notifications.api';
import { useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import MenuButton from 'ui-component/menu/MenuButton';
import HorizontalBar from '../HorizontalBar';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const { user } = useAuth();
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const { layout } = useConfig();
    const { pathname } = useLocation();

    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    const { data: unreadNotifications } = useFetchUserNotificationsQuery(
        { userId: user?.id!, status: 'unread' },
        { skip: !user, pollingInterval: config.notificationsPollingInterval }
    );

    const { data: unreadMessages } = useFetchChatUnreadMessagesCountQuery(
        { userId: user?.id! },
        { skip: !user, pollingInterval: config.notificationsPollingInterval }
    );

    //Responsible for url tab name
    const [name, setName] = useState<ReactNode | null>(null);
    useEffect(() => {
        const url = pathname.split('/').slice(0, 2).join('/');
        getMenuItems().then(({ items }) => {
            for (const item of items)
                if (item.url === url) {
                    setName(item.title || capitalize(item.id));
                    return;
                }
        });
    }, [pathname]);

    return (
        <>
            <Helmet>
                <title>{`${name ?? 'Metalform'} - MPS`}</title>
            </Helmet>
            {/* logo & toggler button */}
            <Box
                sx={{
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box
                    component="span"
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', xl: 'flex' }
                    }}
                >
                    <LogoSection />
                </Box>

                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out'
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                    >
                        <IconMenu2 stroke={1.5} size="20px" color="black" />
                    </Avatar>
                )}
            </Box>

            {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notifications */}
            {userFieldsVisibility && handlePermissionCheck(userFieldsVisibility, 'globalNotifications') && (
                <Link to={`/notifications`} style={{ textDecoration: 'none' }}>
                    <MenuButton
                        selected={pathname === '/notifications'}
                        Icon={Notifications}
                        chipLabel={unreadNotifications ? unreadNotifications.notifs.length.toString() : '0'}
                        label={matchDownLg ? undefined : 'Notifications'}
                        onClick={() => {}}
                    />
                </Link>
            )}

            {/* chat */}
            {userFieldsVisibility && handlePermissionCheck(userFieldsVisibility, 'globalChat') && (
                <Link to={`/chat`} style={{ textDecoration: 'none' }}>
                    <MenuButton
                        selected={pathname === '/chat'}
                        Icon={Chat}
                        chipLabel={unreadMessages ? unreadMessages.toString() : '0'}
                        label={matchDownLg ? undefined : 'Chat'}
                        onClick={() => {}}
                    />
                </Link>
            )}

            <ProfileSection />
        </>
    );
};

export default Header;
