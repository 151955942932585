// material-ui
import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { BoxProps } from '@mui/system';

// ==============================|| LOADER ||============================== //

const Loader = (props: BoxProps) => (
    <Box
        {...props}
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1301,
            width: '100%',
            ...props.sx
        }}
    >
        <LinearProgress color="primary" />
    </Box>
);

export default Loader;
