import React, { ReactNode, Ref } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

interface SubCardProps {
    children: ReactNode | string | null;
    content?: boolean;
    className?: string;
    contentClass?: string;
    darkTitle?: boolean;
    secondary?: ReactNode | string;
    sx?: {};
    contentSX?: {};
    title?: ReactNode | string;
    dense?: boolean;
    original?: boolean;
}

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = React.forwardRef(
    (
        {
            children,
            dense = true,
            original = true,
            className,
            content,
            contentClass,
            darkTitle,
            secondary,
            sx = {},
            contentSX = {},
            title,

            ...others
        }: SubCardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const p = dense ? 1.5 : 2.5;
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                sx={{
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 15 : theme.palette.grey[200],
                    ':hover': {
                        boxShadow: theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    },
                    ...sx
                }}
                {...others}
            >
                {/* card header and action */}
                {!darkTitle && title && <CardHeader sx={{ p }} title={<Typography variant="h5">{title}</Typography>} action={secondary} />}
                {darkTitle && title && <CardHeader sx={{ p }} title={<Typography variant="h4">{title}</Typography>} action={secondary} />}

                {/* card content */}
                {content && (
                    <CardContent sx={{ py: 0, px: 1.5, ...contentSX }} className={contentClass || ''}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

SubCard.defaultProps = {
    content: true
};

export default SubCard;
