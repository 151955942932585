export const handleGetStatusColor = (status: string) => {
    switch (status) {
        case 'Confirmed':
            return '#3f9c35';
        case 'Completed':
            return '#3f9c35';
        case 'Request':
            return '#f0ab00';
        case 'On Hold':
            return '#483d8b';
        case 'waiting_for_pickup':
            return '#F0AB00';
        case 'passive_request':
            return '#873e23';
        case 'cancelled':
            return '#c1c1c1';
        case 'Passive Request':
            return '#873e23';
        case 'Cancelled':
            return '#c1c1c1';
        case 'en_route':
            return '#00B9E4';
        case 'delivered':
            return '#3F9C35';
        case 'Mounting By Us':
            return '#ff7b0f';
        case 'Supply Only':
            return '#f0ab00';
        case 'N/A':
            return '#c1c1c1';
        case 'No Jobs Planned':
            return '#f03000';
        case 'Mounting Done':
            return '#3f9c35';
        case 'drawings-count':
            return '#c1c1c1';
        case 'Waiting':
            return '#00b9e4';
        case 'Not Asked For':
            return '#c1c1c1';
        case 'Ordered':
            return '#f0ab00';
        case 'Approved':
            return '#3f9c35';
        case '1/2 Approved':
            return '#2b6924';
        case 'Unapproved':
            return '#f03000';
        case 'Drawing Uploaded':
            return '#00b9e4';
        case 'Planned':
            return '#00b9e4';
        case 'Pending':
            return '#f0ab00';
        case 'Requested':
            return '#f0ab00';
        case 'Sent To Client':
            return '#f67b29';
        case 'Revision Pending':
            return '#f03000';
        default:
            return '#000000';
    }
};
