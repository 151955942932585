import { orderFields } from './../../utils/data/orders';
import { Country, Crew, Factory, MinimalProduct, Email } from 'types/global.types';
import { api } from './api';
import { Version } from 'types/system.types';
import { OrderFields, Product, ProductInput } from 'types/orders.types';
export const SystemApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // Factory
        fetchFactories: builder.query<Factory[], any>({
            providesTags: ['Factories'],
            query: () => {
                return { url: '/v1/system-settings/list_factory', method: 'GET' };
            }
        }),
        fetchUserFactories: builder.query<Factory[], { userId: number }>({
            providesTags: ['Factories'],
            query: (props) => {
                const formData = new FormData();
                formData.append('userId', props.userId.toString());
                return { url: '/v1/system-settings/list_all_user_factories', method: 'POST', body: formData };
            }
        }),
        addFactory: builder.mutation<undefined, { factory_name: string; factory_name_abbreviation: string }>({
            invalidatesTags: ['Factories'],
            query: (props) => {
                return { url: '/v1/system-settings/create_new_factory', body: props, method: 'POST' };
            }
        }),
        deleteFactory: builder.mutation<undefined, { factory_id: number }>({
            invalidatesTags: ['Factories'],
            query: (props) => {
                return { url: '/v1/system-settings/delete_factory', body: props, method: 'DELETE' };
            }
        }),
        editFactory: builder.mutation<undefined, { factory_id: number; edited_factory_name: string; edited_abbreviation_name: string }>({
            invalidatesTags: ['Factories'],
            query: (props) => {
                return { url: '/v1/system-settings/update_factory', body: props, method: 'PUT' };
            }
        }),
        // Country
        fetchCountries: builder.query<Country[], any>({
            query: () => {
                return { url: '/v1/system-settings/list_country', method: 'GET' };
            },
            providesTags: ['Countries']
        }),
        addCountry: builder.mutation<undefined, { country_name: string; country_abbreviation: string }>({
            query: (props) => {
                return { url: '/v1/system-settings/create_new_country', method: 'POST', body: props };
            },
            invalidatesTags: ['Countries']
        }),
        deleteCountry: builder.mutation<undefined, { country_id: number }>({
            query: (props) => {
                return { url: '/v1/system-settings/delete_country', method: 'DELETE', body: props };
            },
            invalidatesTags: ['Countries']
        }),
        editCountry: builder.mutation<undefined, { country_id: number; edited_country_name: string; edited_abbreviation_name: string }>({
            query: (props) => {
                return { url: '/v1/system-settings/update_country', method: 'PUT', body: props };
            },
            invalidatesTags: ['Countries']
        }),
        // Mounting Crews
        fetchMountingCrews: builder.query<Crew[], any>({
            providesTags: ['Crews', 'MountingEvents'],
            query: () => {
                return { url: '/v1/system-settings/list_crew', method: 'GET' };
            }
        }),
        addMountingCrews: builder.mutation<
            undefined,
            {
                crew_name: string;
                crew_name_abbreviation: string;
                email: string;
                country: number;
                api_credentials: string;
                calendar_id: string;
                color: string;
                start_of_week: number;
                days_off: number[];
            }
        >({
            invalidatesTags: ['Crews', 'MountingEvents'],
            query: (props) => {
                return { url: '/v1/system-settings/create_new_crew', body: props, method: 'POST' };
            }
        }),
        deleteMountingCrews: builder.mutation<undefined, { crew_id: number }>({
            invalidatesTags: ['Crews', 'MountingEvents'],
            query: (props) => {
                return { url: '/v1/system-settings/delete_crew', body: props, method: 'DELETE' };
            }
        }),
        editMountingCrews: builder.mutation<
            undefined,
            {
                crew_id: number;
                edited_crew_name: string;
                edited_crew_abbreviation_name: string;
                edited_email: string;
                edited_country: number;
                edited_google_api: string;
                edited_calendar_id: string;
                edited_color: string;
                start_of_week: number;
                days_off: number[];
            }
        >({
            invalidatesTags: ['Crews', 'MountingEvents'],
            query: (props) => {
                return { url: '/v1/system-settings/update_crew', body: props, method: 'PUT' };
            }
        }),
        // Products
        fetchProducts: builder.query<MinimalProduct[], any>({
            providesTags: ['Products'],
            query: () => {
                return { url: '/v1/product/get_all_products', method: 'POST' };
            }
        }),
        fetchGlobalInputs: builder.query<ProductInput[], any>({
            providesTags: ['Products'],
            query: () => {
                return { url: '/v1/product/get_global_inputs', method: 'POST' };
            }
        }),
        addProduct: builder.mutation<undefined, { product: Product }>({
            invalidatesTags: ['Products'],
            query: (props) => {
                const formData = new FormData();

                formData.append('productTitle', props.product.productTitle);

                const stringInputs = JSON.stringify(props.product.inputs);
                formData.append('inputs', stringInputs);

                if (props.product.file) formData.append('file', props.product.file);

                return { url: '/v1/product/create_product', body: formData, method: 'POST' };
            }
        }),
        editProduct: builder.mutation<undefined, { product: Product }>({
            invalidatesTags: ['Products'],
            query: (props) => {
                const formData = new FormData();
                console.log({ props });

                formData.append('productTitle', props.product.productTitle);

                const stringInputs = JSON.stringify(props.product.inputs);
                formData.append('inputs', stringInputs);
                if (props.product.productId) formData.append('productId', props.product.productId.toString());

                if (props.product.file) formData.append('file', props.product.file);
                return { url: '/v1/product/update_product', body: formData, method: 'POST' };
            }
        }),
        checkDeleteProductUsed: builder.query<string, { productId: number }>({
            query: (props) => {
                return { url: '/v1/product/delete_product', body: props, method: 'POST' };
            }
        }),
        deleteProduct: builder.mutation<undefined, { productId: number }>({
            invalidatesTags: ['Products'],
            query: (props) => {
                return { url: '/v1/product/delete_product_confirmation', body: props, method: 'POST' };
            }
        }),
        fetchProductInformation: builder.query<
            // {
            //     id: number;
            //     img_url: string;
            //     title: string;
            //     inputs: // {
            //     //     id: number;
            //     //     inputTitle: string;
            //     //     productId: string;
            //     //     inputType: 'select' | 'text';
            //     //     inputValues?: string[];
            //     //     width: number;
            //     // }
            //     ProductInput[];
            // },
            Product,
            { productId: number }
        >({
            providesTags: ['Products'],
            query: (props) => {
                return { url: '/v1/product/get_products_information', body: props, method: 'POST' };
            }
        }),
        // Version log
        fetchVersion: builder.query<Version, { user_id: number }>({
            providesTags: ['Version'],
            query: ({ user_id }) => {
                return { url: '/v1/version/get_version', method: 'POST', body: { user_id } };
            }
        }),
        saveVersion: builder.mutation<
            undefined,
            {
                htmlString: string;
                version: string;
            }
        >({
            invalidatesTags: ['Version'],
            query: (props) => {
                return {
                    url: '/v1/version/update_version',
                    method: 'POST',
                    body: {
                        information: props.htmlString,
                        version: props.version
                    }
                };
            }
        }),
        toggleVersionNotifications: builder.mutation<
            undefined,
            {
                user_id: number;
                disabled_user_notifications?: boolean;
            }
        >({
            invalidatesTags: ['Version'],
            query: (props) => {
                return {
                    url: '/v1/version/toggle_user_notifications',
                    method: 'POST',
                    body: {
                        disabled_user_notifications: props.disabled_user_notifications,
                        user_id: props.user_id
                    }
                };
            }
        }),
        // Email Settings
        fetchAllEmailSettings: builder.query<Email[], any>({
            providesTags: ['Emails'],
            query: () => {
                return { url: '/v1/settings/emails_settings', method: 'GET' };
            }
        }),
        fetchEmailSettings: builder.query<
            {
                id: number;
                order_id: string;
                title: string;
                author: string;
            },
            { id: number }
        >({
            providesTags: ['Emails'],
            query: ({ id }) => {
                return {
                    url: 'v1/settings/single_email_settings',
                    method: 'POST',
                    body: JSON.stringify({ id })
                };
            }
        }),
        saveEmailSettings: builder.mutation<Email, { id: number; email_object: string; email_html: string }>({
            query: (props) => {
                return { url: '/v1/settings/save_email', method: 'POST', body: props };
            },
            invalidatesTags: ['Emails']
        }),
        saveEmailDescription: builder.mutation<Email, { id: number; subject: string; title: string }>({
            query: (props) => {
                return { url: '/v1/settings/save_email_description', method: 'POST', body: props };
            },
            invalidatesTags: ['Emails']
        }),
        // Order fields visability
        fetchOrderFieldsVisibility: builder.query<{ client: OrderFields; mountingWorker: OrderFields }, any>({
            query: () => {
                return { url: '/v1/system/block_visibility', method: 'POST' };
            }
        }),
        fetchUserOrderFieldsVisability: builder.query<OrderFields | boolean, { userId: number }>({
            query: ({ userId }) => {
                return { url: '/v1/system/user_block_visibility', method: 'POST', body: { user_id: userId } };
            }
        }),
        blockOrderFieldsVisibility: builder.mutation({
            query: (fields) => {
                return { url: '/v1/system/update_block_visibility', method: 'POST', body: fields };
            }
        }),
        // Other
        getSystemInitStatus: builder.query<boolean, any>({
            providesTags: ['SystemInit'],
            query: () => {
                return { url: '/v1/system/get_system_init', method: 'POST' };
            }
        }),
        setSystemInitStatus: builder.mutation<any, { userId: number; initStatus: boolean }>({
            invalidatesTags: ['SystemInit'],
            query: (fields) => {
                return { url: '/v1/system/change_system_init', method: 'POST', body: fields };
            }
        })
    })
});

export const {
    // Factory
    useAddFactoryMutation,
    useFetchUserFactoriesQuery,
    useDeleteFactoryMutation,
    useEditFactoryMutation,
    useFetchFactoriesQuery,
    // Country
    useAddCountryMutation,
    useDeleteCountryMutation,
    useEditCountryMutation,
    useFetchCountriesQuery,
    // Mounting crews
    useAddMountingCrewsMutation,
    useDeleteMountingCrewsMutation,
    useEditMountingCrewsMutation,
    useFetchMountingCrewsQuery,
    // Products
    useLazyFetchGlobalInputsQuery,
    useLazyFetchProductInformationQuery,
    useFetchProductsQuery,
    useAddProductMutation,
    useEditProductMutation,
    useDeleteProductMutation,
    useLazyCheckDeleteProductUsedQuery,
    // Version
    useFetchVersionQuery,
    useSaveVersionMutation,
    useToggleVersionNotificationsMutation,
    // Emails
    useFetchAllEmailSettingsQuery,
    useLazyFetchEmailSettingsQuery,
    useSaveEmailSettingsMutation,
    useSaveEmailDescriptionMutation,
    // Order Field Visability
    useFetchOrderFieldsVisibilityQuery,
    useFetchUserOrderFieldsVisabilityQuery,
    useBlockOrderFieldsVisibilityMutation,
    // Other
    useGetSystemInitStatusQuery,
    useSetSystemInitStatusMutation
} = SystemApi;
