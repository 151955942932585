import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, Paper, Tooltip, Grid2 as Grid, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import { cloneDeep } from 'lodash';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { SystemMessage } from 'react-chat-elements';
import { useDropzone } from 'react-dropzone';
import { InView } from 'react-intersection-observer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    useFetchChatMessagesQuery,
    useFetchChatOrderInformationQuery,
    useFetchChatOrderParticipantsQuery,
    useFetchChatOrderQuery,
    useNewChatMessageMutation
} from 'store/api/chat.api';
import { ChatMessage } from 'types/chat.types';
import ChatInputField from 'ui-component/chat/ChatInputField';
import ChatMessageItem from 'ui-component/chat/items/ChatMessageItem';
import FileList from 'ui-component/files/FileList';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';

const OrderChat = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);
    const [inView, setInView] = useState(false);

    const { order } = useFetchChatOrderQuery(
        {
            orderId: orderId!,
            userId: user?.id!
        },
        {
            skip: !user || !orderId,
            selectFromResult: ({ data }) => {
                if (data) return { order: data[0] };
                else return { order: undefined };
            }
        }
    );
    const { messages } = useFetchChatMessagesQuery(
        { orderId: orderId!, participantId: order?.participant_id!, roomId: order?.id! },
        {
            selectFromResult: ({ data }) => ({
                messages: data || undefined
            }),
            pollingInterval: 10000,
            skip: !orderId || !order || !inView
        }
    );

    console.log({ orderId, user, order });

    const { data: chatParticipans } = useFetchChatOrderParticipantsQuery(
        { orderId: orderId!, roomId: order?.post_id!, userId: user?.id! },
        { skip: !orderId || !user || !order }
    );

    const [newChatMessage] = useNewChatMessageMutation();

    // form data
    const [text, setText] = useState('');
    const [files, setFiles] = useState<File[]>([]);
    const [replyData, setReplyData] = useState<{
        id?: number;
        title?: string;
        message?: string;
    } | null>(null);

    // misc
    const [key, setKey] = useState(0);

    // dropzone
    const { getRootProps, getInputProps } = useDropzone({
        noClick: true,
        onDrop: (acceptedFiles) => {
            acceptedFiles.map((file) => {
                setFiles((oldFiles) => [
                    ...oldFiles,
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                ]);
            });
        }
    });

    const chatContainerRef = useRef<any>(null);

    useEffect(() => {
        if (!chatContainerRef || !chatContainerRef.current) return;
        // chatContainerRef.current.scrollTop = 100;
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [messages, chatContainerRef.current]);

    const handleClearForm = () => {
        setText('');
        setFiles([]);
        setReplyData(null);
        setKey(key + 1);
    };

    const handleDeleteFile = (index: number) => {
        const filesCopy = cloneDeep(files);
        filesCopy.splice(index, 1);
        setFiles(filesCopy);
    };

    const handleReply = (message: ChatMessage) => {
        setReplyData({
            id: message.message_id,
            message: message.message,
            title: message.display_name
        });
    };

    const handleSubmit = async (event?: SyntheticEvent) => {
        if (event !== undefined) {
            event.preventDefault();
        }
        newChatMessage({ files, message: text, orderId: order?.post_id!, userId: user?.id!, replyToId: replyData?.id });
        handleClearForm();
    };

    const handleButtonState = () => {
        if (text || files.length > 0) {
            return false;
        } else return true;
    };

    const onEnterPress = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    return (
        <InView onChange={(inView) => setInView(inView)}>
            {({ inView, ref, entry }) => (
                <Grid
                    container
                    ref={ref}
                    sx={{
                        mx: 2,
                        boxShadow: 'none',
                        backgroundImage: 'none',
                        borderRadius: '8px',
                        border: 'none rgba(224, 224, 224, 0.596)',
                        backgroundColor: 'rgb(248, 250, 252)',
                        marginTop: '20px',
                        margin: 0
                    }}
                >
                    <Grid size={12}>
                        <PerfectScrollbar
                            containerRef={(ref) => {
                                chatContainerRef.current = ref;
                            }}
                            component="div"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,
                                marginBottom: 3,
                                paddingRight: 1,
                                paddingLeft: 1,
                                paddingTop: 5,
                                flexGrow: 1,
                                maxHeight: 400,
                                minHeight: 400
                            }}
                        >
                            {messages && messages.map((message, i) => <ChatMessageItem message={message} handleReply={handleReply} />)}
                            <br id="end-messages" />
                            {messages && messages.length === 0 && (
                                // @ts-ignore
                                <SystemMessage text={'Order has no messages.'} />
                            )}
                            {/* @ts-ignore */}
                            {messages === null && <SystemMessage text="Click Order to Chat" />}
                        </PerfectScrollbar>
                    </Grid>
                    <Grid size={12}>
                        <form
                            onSubmit={handleSubmit}
                            style={{ borderRadius: 0 }}
                            onPaste={(event) => {
                                if (event.clipboardData?.files.length === undefined) {
                                    return;
                                }
                                for (const file of event.clipboardData.files) {
                                    Object.assign(file, {
                                        preview: URL.createObjectURL(file)
                                    });
                                    setFiles((oldFiles) => [...oldFiles, file]);
                                }
                            }}
                        >
                            <Box {...getRootProps()}>
                                <input {...getInputProps()} />
                                {replyData && (
                                    <Box
                                        sx={{
                                            display: 'Flex',
                                            p: 1,
                                            backgroundColor: '#eeeeee',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        {/* <MessageBox position="left" title={replyData?.title} text={replyData?.message} type="text" /> */}
                                        <Typography>
                                            Replying to{' '}
                                            <Typography component={'span'} sx={{ fontWeight: 600 }}>
                                                {replyData.title}
                                            </Typography>
                                        </Typography>
                                        <Tooltip title="Stop replying">
                                            <IconButton
                                                sx={{ ml: 'auto' }}
                                                onClick={() => {
                                                    setReplyData(null);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                                <Paper
                                    elevation={5}
                                    sx={{
                                        display: 'flex',
                                        p: 2,
                                        borderRadius: '0 0 8px 8px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flexGrow: '1',
                                            maxWidth: '85%'
                                        }}
                                    >
                                        {chatParticipans && (
                                            <ChatInputField
                                                mentionUsers={chatParticipans}
                                                setValue={setText}
                                                value={text}
                                                onEnterPress={onEnterPress}
                                            />
                                        )}
                                        <Box sx={{ minHeight: 40 }}>
                                            <FileList sx={{ mt: 2 }} files={files} handleRemove={handleDeleteFile} />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexGrow: '1',
                                            maxWidth: '15%',
                                            flexDirection: { xs: 'column' },
                                            gap: 2
                                        }}
                                    >
                                        <Box>
                                            <input
                                                type="file"
                                                multiple
                                                style={{ display: 'none' }}
                                                id="raised-button-file"
                                                onChange={(event) => {
                                                    if (event.target.files?.length === undefined) {
                                                        return;
                                                    }

                                                    for (const file of event.target.files) {
                                                        Object.assign(file, {
                                                            preview: URL.createObjectURL(file)
                                                        });
                                                        setFiles((oldFiles) => [...oldFiles, file]);
                                                    }
                                                }}
                                            />
                                            <label htmlFor="raised-button-file">
                                                <IconButton color="primary" component="span">
                                                    <AttachFileIcon />
                                                </IconButton>
                                            </label>
                                        </Box>
                                        <Box>
                                            <IconButton
                                                type="submit"
                                                color="primary"
                                                disabled={handleButtonState()}
                                                title={true ? 'Please wait, message is being sent' : 'Send'}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            )}
        </InView>
    );
};

export default OrderChat;
