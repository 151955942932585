import { Box, Typography } from '@mui/material';
import { handleGetStatusColor } from 'helpers/orders.helper';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ShippingGetAll } from '../../store/api/orders.api';

import DataGrid, { Column } from 'react-data-grid';

interface Props {
    tableData: ShippingGetAll;
    enableDisplayMode?: boolean;
    virtualized?: boolean;
}

export interface Row {
    ship_id: string;
    ship_number: string;
    ship_date: string;
    ship_status: string;
    partial_status: string;
    partial_shipment_contents: string;
    partial_reason: string;
}

const OrderShippingsTable = ({ tableData }: Props) => {
    const { user } = useAuth();

    const initialTableData: Row[] = createRows();
    const [rows, setRows] = useState<Row[]>(initialTableData);

    const column: readonly Column<Row>[] = [
        {
            key: 'ship_number',
            name: 'Number',
            width: 100,
            cellClass: 'header-align',
            headerCellClass: 'header-align',
            formatter(props) {
                const value = props.row;
                return (
                    <Link to={`/shipping/${value.ship_id}`} style={{ textDecoration: 'inherit', display: 'inline-block' }}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ display: 'flex' }}>{value.ship_number}</Typography>
                        </Box>
                    </Link>
                );
            }
        },
        {
            key: 'ship_date',
            name: 'Date',
            width: 150,
            cellClass: 'header-align',
            headerCellClass: 'header-align'
        },
        {
            key: 'ship_status',
            name: 'Status',
            width: 150,
            cellClass: 'header-align',
            headerCellClass: 'header-align',
            formatter(props) {
                const value = props.row;
                return (
                    <Typography
                        sx={{
                            bgcolor: handleGetStatusColor(value.ship_status),
                            color: 'white',
                            borderRadius: '50px',
                            textTransform: 'capitalize',
                            fontSize: '12px !important',
                            wordWrap: 'none',
                            wordBreak: 'keep-all',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            padding: '2px 10px',
                            display: 'inline-block',
                            width: '100%'
                        }}
                    >
                        {handleStatusText(value.ship_status)}
                    </Typography>
                );
            }
        },
        {
            key: 'partial_status',
            name: 'Is Partial',
            width: 150,
            cellClass: 'header-align',
            headerCellClass: 'header-align'
        },
        {
            key: 'partial_shipment_contents',
            name: 'Shipment Content'
        },
        {
            key: 'partial_reason',
            name: 'Change Of Shipment Reason'
        }
    ];

    function handleStatusText(inputString: string) {
        switch (inputString) {
            case 'waiting_for_pickup':
                return 'Waiting for Pickup';
            case 'delivered':
                return 'Delivered';
            case 'en_route':
                return 'En Route';
        }
    }

    function createRows(): Row[] {
        const rows: Row[] = [];

        for (let i = 0; i < tableData.rows.length; i++) {
            const tableDataRow = tableData.rows[i];

            // Check if tableDataRow conforms to the Row interface
            if ('ship_id' in tableDataRow) {
                const newRow: Row = {
                    ship_id: tableDataRow.ship_id,
                    ship_number: tableDataRow.ship_number,
                    ship_status: tableDataRow.ship_status,
                    ship_date: tableDataRow.ship_date,
                    partial_status: tableDataRow.partial_status,
                    partial_shipment_contents: tableDataRow.partial_shipment_contents,
                    partial_reason: tableDataRow.partial_reason
                };

                rows.push(newRow);
            }
        }

        return rows;
    }

    useEffect(() => {
        setRows(createRows());
    }, [tableData]);

    return (
        <>
            <DataGrid
                style={{
                    height: '100%',
                    minHeight: 200
                }}
                columns={column}
                rows={rows}
                className="fill-grid rdg-light"
            />
        </>
    );
};

export default OrderShippingsTable;
