import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Folder } from '@mui/icons-material';
import { Autocomplete, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { nanoid } from '@reduxjs/toolkit';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
    useAddOrUpdateJobMutation,
    useDeleteMountingJobMutation,
    useFetchCalendarInformationQuery,
    useFetchJobsQuery,
    useLazyFetchClientInfoQuery,
    useLazyFetchFilesForEventQuery,
    useSyncGoogleCalendarMutation
} from 'store/api/mounting.api';
import { FileSchema } from 'types/file.types';
import { Event, Project, clientSchema, crewSchema, projectSchema } from 'types/mounting.types';
import MainCard from 'ui-component/cards/MainCard';
import ModalFormCard from 'ui-component/cards/ModalCard';
import FileList from 'ui-component/files/FileList';
import { dateFormat } from 'utils/data/constant';
import { getMessage } from 'utils/messages';
import { z } from 'zod';

interface Props {
    event: Event;
    calendarId: string | undefined;
}

const EventEditForm = NiceModal.create(({ calendarId, event }: Props) => {
    const modal = useModal();
    const { user } = useAuth();

    const { data: crews } = useFetchCalendarInformationQuery({ userId: user!.id, calendarId: calendarId });
    const { data: jobs } = useFetchJobsQuery(
        {
            region: crews?.map((item) => parseInt(item.id)),
            userID: user!.id
        },
        { skip: !crews }
    );

    const [fetchFilesForEvent] = useLazyFetchFilesForEventQuery();
    const [syncGoogleCalendar] = useSyncGoogleCalendarMutation();
    const [fetchClientInfo] = useLazyFetchClientInfoQuery();
    const [addOrUpdateMountingJob] = useAddOrUpdateJobMutation();
    const [deleteMountingJob] = useDeleteMountingJobMutation();

    const [userInformation, setUserInformation] = useState<{
        client?: {
            ID: string;
            post_author: string;
            post_date: string;
            post_date_gmt: string;
            post_content: string;
            post_title: string;
            post_excerpt: string;
            post_status: string;
            comment_status: string;
            ping_status: string;
            post_password: string;
            post_name: string;
            to_ping: string;
            pinged: string;
            post_modified: string;
            post_modified_gmt: string;
            post_content_filtered: string;
            post_parent: string;
            guid: string;
            menu_order: string;
            post_type: string;
            post_mime_type: string;
            comment_count: string;
            meta_id: string;
            post_id: string;
            meta_key: string;
            meta_value: string;
        }[];
        company?: {
            ID: string;
            post_author: string;
            post_date: string;
            post_date_gmt: string;
            post_content: string;
            post_title: string;
            post_excerpt: string;
            post_status: string;
            comment_status: string;
            ping_status: string;
            post_password: string;
            post_name: string;
            to_ping: string;
            pinged: string;
            post_modified: string;
            post_modified_gmt: string;
            post_content_filtered: string;
            post_parent: string;
            guid: string;
            menu_order: string;
            post_type: string;
            post_mime_type: string;
            comment_count: string;
            meta_id: string;
            post_id: string;
            meta_key: string;
            meta_value: string;
        }[];
        representatives?: {
            ID: string;
            post_author: string;
            post_date: string;
            post_date_gmt: string;
            post_content: string;
            post_title: string;
            post_excerpt: string;
            post_status: string;
            comment_status: string;
            ping_status: string;
            post_password: string;
            post_name: string;
            to_ping: string;
            pinged: string;
            post_modified: string;
            post_modified_gmt: string;
            post_content_filtered: string;
            post_parent: string;
            guid: string;
            menu_order: string;
            post_type: string;
            post_mime_type: string;
            comment_count: string;
            meta_id: string;
            post_id: string;
            meta_key: string;
            meta_value: string;
        }[];
    }>({});

    const client = jobs?.clients.filter((obj) => obj.id === parseInt(event.client))[0];
    const crew = crews?.filter((obj) => obj.id === event.region)[0];
    const project = jobs?.projects.filter((obj) => obj.id === event.project)[0];

    const formSchema = z.object({
        eventTitle: z.string().min(2),
        selectedStart: z.any(),
        selectedEnd: z.any(),
        client: z.nullable(clientSchema),
        project: z.nullable(projectSchema),
        crew: crewSchema,
        jobComment: z.string(),
        jobAddress: z.string(),
        clientFiles: z.array(FileSchema),
        designerFiles: z.array(FileSchema),
        documentationFiles: z.array(FileSchema),
        factoryFiles: z.array(FileSchema)

        // selectedWorkers: z.array(workerSchema),
        // clientFiles: z.array(FileSchema),
        // factoryFiles: z.array(FileSchema),
        // designerFiles: z.array(FileSchema),
        // documentationFiles: z.array(FileSchema),
        // existingSelected: z.boolean()
    });

    type Form = z.infer<typeof formSchema>;

    const {
        register,
        handleSubmit,
        watch,
        control,
        reset,
        getValues,
        setValue,
        formState: { errors }
    } = useForm<Form>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            eventTitle: event.Subject,
            jobAddress: event.address,
            jobComment: event.comment,
            client: client,
            crew: crew,
            selectedEnd: moment(event.EndTime),
            selectedStart: moment(event.StartTime),
            project: project,
            clientFiles: event.clientFiles || [],
            designerFiles: event.designerFiles || [],
            documentationFiles: event.documentationFiles || [],
            factoryFiles: event.factoryFiles || []
        }
    });

    // Event create/update
    const createOrUpdateEvent: SubmitHandler<Form> = (data) => {
        if (!user || !jobs || !crews) return;
        let local_id = nanoid();
        //Format the comment input in to line breaks with files list before sending it to google
        let formattedComment;
        let clientInformation = '';
        if (userInformation && Object.keys(userInformation).length > 0) {
            let userKey = Object.keys(userInformation);
            if (userKey[0] === 'client') {
                if (!userInformation.client) return;
                let firstName = userInformation.client.find((i) => i.meta_key === 'first_name');
                let lastName = userInformation.client.find((i) => i.meta_key === 'last_name');
                let phone = userInformation.client.find((i) => i.meta_key === 'person_phone_number');
                let email = userInformation.client.find((i) => i.meta_key === 'person_e-mail_address');
                clientInformation = `<b>Client information:</b>\n\n<b>Name:</b> ${
                    firstName && firstName.meta_value ? firstName.meta_value : ''
                } ${lastName && lastName.meta_value ? lastName.meta_value : ''}\n<b>Phone:</b> ${
                    phone && phone.meta_value ? phone.meta_value : ''
                }\n<b>Email:</b> ${email && email.meta_value ? email.meta_value : ''}\n`;
            } else {
                if (!userInformation.company) return;
                let companyName = userInformation.company.find((i) => i.meta_key === 'company_name');
                if (!companyName) return;
                clientInformation = `<b>Company name:</b> ${companyName.meta_value}\n`;
                if (userInformation.representatives) {
                    clientInformation += `<b>Representives:</b>\n\n`;
                    // userInformation.representatives.forEach((x) => {
                    let firstName = userInformation.representatives.find((i) => i.meta_key === 'first_name');
                    let lastName = userInformation.representatives.find((i) => i.meta_key === 'last_name');
                    let phone = userInformation.representatives.find((i) => i.meta_key === 'person_phone_number');
                    let email = userInformation.representatives.find((i) => i.meta_key === 'person_e-mail_address');
                    clientInformation += `<b>Name:</b> ${firstName && firstName.meta_value ? firstName.meta_value : ''} ${
                        lastName && lastName.meta_value ? lastName.meta_value : ''
                    }\n<b>Phone:</b> ${phone && phone.meta_value ? phone.meta_value : ''}\n<b>Email:</b> ${
                        email && email.meta_value ? email.meta_value : ''
                    }\n\n`;
                    // });
                } else {
                    let name = userInformation.company.find((i) => i.meta_key === 'company_name');
                    let phone = userInformation.company.find((i) => i.meta_key === 'company_number');
                    let email = userInformation.company.find((i) => i.meta_key === 'company_email_address');
                    clientInformation += `<b>Name:</b> ${name && name.meta_value ? name.meta_value : ''}\n<b>Phone:</b> ${
                        phone && phone.meta_value ? phone.meta_value : ''
                    }\n<b>Email:</b> ${email && email.meta_value ? email.meta_value : ''}\n\n`;
                }
            }
        }
        if (data.project) {
            formattedComment = `Go to MPS Calendar: ${`${window.location.href}`}\nGo to MPS Order: ${`${window.location.protocol}//${window.location.hostname}/wp-admin/post.php?post=${data.project.id}&action=edit`}\n\n${
                clientInformation ? clientInformation : ''
            }${data.jobComment && `<b>Details for mounters</b>`}\n${data && data.jobComment ? data.jobComment : ''}\n\n${
                data.clientFiles && data.clientFiles.length > 0 ? '<b>Client Files</b><ol>' : ''
            }${
                data.clientFiles && data.clientFiles.length > 0
                    ? data.clientFiles
                          .map((item) => {
                              return `<li>${item.src}</li>`;
                          })
                          .join('')
                    : ''
            }${data.clientFiles && data.clientFiles.length > 0 ? '</ol>' : ''}${
                data.factoryFiles && data.factoryFiles.length > 0 ? '<b>Factory Files</b><ol>' : ''
            }${
                data.factoryFiles && data.factoryFiles.length > 0
                    ? data.factoryFiles
                          .map((item) => {
                              return `<li>${item.src}</li>`;
                          })
                          .join('')
                    : ''
            }${data.factoryFiles && data.factoryFiles.length > 0 ? '</ol>' : ''}${
                data.designerFiles && data.designerFiles.length > 0 ? '<b>Designer Files</b><ol>' : ''
            }${
                data.designerFiles && data.designerFiles.length > 0
                    ? data.factoryFiles
                          .map((item) => {
                              return `<li>${item.src}</li>`;
                          })
                          .join('')
                    : ''
            }${data.designerFiles && data.designerFiles.length > 0 ? '</ol>' : ''}${
                data.documentationFiles && data.documentationFiles.length > 0 ? '<b>Documentation Files</b><ol>' : ''
            }\n${
                data.documentationFiles && data.documentationFiles.length > 0
                    ? data.documentationFiles
                          .map((item) => {
                              return `<li>${item.src}</li>`;
                          })
                          .join('')
                    : ''
            }${data.documentationFiles && data.documentationFiles.length > 0 ? '</ol>' : ''}`;
        } else {
            formattedComment = `${data.jobComment && `<b>Details for mounters</b>`}\n${data.jobComment}\n\n`;
        }
        const factoryFileIds = data.factoryFiles ? data.factoryFiles.map((obj) => obj.id) : [];
        const documentationFileIds = data.documentationFiles ? data.documentationFiles.map((obj) => obj.id) : [];
        const clientFileIds = data.clientFiles ? data.clientFiles.map((obj) => obj.id) : [];
        const designerFileIds = data.designerFiles ? data.designerFiles.map((obj) => obj.id) : [];
        const allIds = factoryFileIds.concat(documentationFileIds, clientFileIds, designerFileIds);
        let timeA = moment(data.selectedStart);
        let timeB = moment(data.selectedEnd);
        let dayNumber = Math.abs(Math.floor(moment.duration(timeA.diff(timeB)).asDays()));
        let dateIntervals: any[] = [];
        if (dayNumber === 1) {
            if (!data.project) return;
            let dateObject = {
                region: data.crew?.id,
                eventAuthor: user?.id,
                // selectedEvent: event.Id && props.selectedLocalId === null ? props.selectedEvent : '',
                selectedEvent: '',
                eventTitle: data.eventTitle ? data.eventTitle : data.project.title,
                startDate: moment(data.selectedStart).format('YYYY-MM-DD HH:mm'),
                endDate: moment(data.selectedEnd).format('YYYY-MM-DD HH:mm'),
                client: data.client ? data.client.id : '',
                project: data.project ? data.project.id : '',
                // workers: data.selectedWorkers ? data.sele ctedWorkers : '',
                comment: data.jobComment,
                googleComment: formattedComment,
                address: data.jobAddress ? data.jobAddress : '',
                files: allIds,
                local_id: local_id
            };
            dateIntervals.push(dateObject);
        } else {
            for (let i = 0; i < dayNumber; i++) {
                if (i === 0) {
                    let startDay = moment(data.selectedStart).format('YYYY-MM-DD HH:mm');
                    let endDay = moment(data.selectedStart).set('hour', 22).set('minute', 0).format('YYYY-MM-DD HH:mm');
                    let dateObject = {
                        region: data.crew?.id,
                        eventAuthor: user.id,
                        // selectedEvent: props.selectedEvent && props.selectedLocalId === null ? props.selectedEvent : '',
                        selectedEvent: '',
                        eventTitle: data.eventTitle ? data.eventTitle : project!.title,
                        startDate: startDay,
                        endDate: endDay,
                        client: data.client ? data.client.id : '',
                        project: data.project ? data.project.id : '',
                        // workers: data.selectedWorkers ? data.selectedWorkers : '',
                        comment: data.jobComment,
                        googleComment: formattedComment,
                        address: data.jobAddress ? data.jobAddress : '',
                        files: allIds,
                        local_id: local_id
                    };
                    dateIntervals.push(dateObject);
                } else if (i + 1 === dayNumber) {
                    let startDay = moment(data.selectedEnd).set('hour', 8).set('minute', 0).format('YYYY-MM-DD HH:mm');
                    let endDay = moment(data.selectedEnd).format('YYYY-MM-DD HH:mm');
                    let dateObject = {
                        region: data.crew?.id,
                        eventAuthor: user.id,
                        // selectedEvent: event.Id && props.selectedLocalId === null ? props.selectedEvent : '',
                        selectedEvent: '',
                        eventTitle: data.eventTitle ? data.eventTitle : project!.title,
                        startDate: startDay,
                        endDate: endDay,
                        client: data.client ? data.client.id : '',
                        project: data.project ? data.project.id : '',
                        // workers: data.selectedWorkers ? data.selectedWorkers : '',
                        comment: data.jobComment,
                        googleComment: formattedComment,
                        address: data.jobAddress ? data.jobAddress : '',
                        files: allIds,
                        local_id: local_id
                    };
                    dateIntervals.push(dateObject);
                } else {
                    let startDay = moment(data.selectedStart).add(i, 'days').set('hour', 8).set('minute', 0).format('YYYY-MM-DD HH:mm');
                    let endDay = moment(data.selectedStart).add(i, 'days').set('hour', 22).set('minute', 0).format('YYYY-MM-DD HH:mm');
                    let dateObject = {
                        region: data.crew?.id,
                        eventAuthor: user.id, // eslint-disable-line
                        // selectedEvent: props.selectedEvent && props.selectedLocalId === null ? props.selectedEvent : '',
                        selectedEvent: '',

                        eventTitle: data.eventTitle ? data.eventTitle : project!.title,
                        startDate: startDay,
                        endDate: endDay,
                        client: data.client ? data.client.id : '',
                        project: data.project ? data.project.id : '',
                        // workers: data.selectedWorkers ? data.selectedWorkers : '',
                        comment: data.jobComment,
                        googleComment: formattedComment,
                        address: data.jobAddress ? data.jobAddress : '',
                        files: allIds,
                        local_id: local_id
                    };
                    dateIntervals.push(dateObject);
                }
            }
        }
        modal.remove();
        deleteMountingJob({ selectedEvent: event.Id, local_id: event.local_id! });
        const promises = dateIntervals.map((item, index) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    addOrUpdateMountingJob(item);
                    resolve(null);
                }, index * 500);
            });
        });
        toast.promise(Promise.all([...promises]), {
            pending: getMessage('updatePending'),
            success: getMessage('updated'),
            error: getMessage('updateError')
        });

        if (calendarId) syncGoogleCalendar({ id: user.id, region: calendarId });
    };

    const handleProjectChange = (data: Project) => {
        if (!jobs) return;
        const formData: Form = {} as Form;
        formData.jobAddress = data.address;
        formData.eventTitle = data.title;
        formData.selectedStart = getValues('selectedStart');
        formData.selectedEnd = getValues('selectedEnd');
        formData.crew = getValues('crew');
        let clientToSet = jobs.clients.filter((obj) => obj.id === data.client);
        if (clientToSet.length > 0) {
            formData.client = clientToSet[0];
        }
        toast.promise(
            Promise.all([
                fetchFilesForEvent({ projectId: event.Id, postId: data.id }),
                fetchClientInfo({ clientId: data.client, postId: data.id })
            ]).then(([filesResponse, clientInfoResponse]) => {
                if (filesResponse) {
                    const { data } = filesResponse;
                    formData.clientFiles = data?.clientFiles!;
                    formData.designerFiles = data?.designerFiles!;
                    formData.factoryFiles = data?.factoryFiles!;
                    formData.documentationFiles = data?.documentationFiles!;
                }
                if (clientInfoResponse) {
                    const { data } = clientInfoResponse;
                    setUserInformation(data);
                }
                formData.project = data;
                reset(formData);
            }),
            {
                error: getMessage('loadingError'),
                pending: getMessage('loadingPending')
            }
        );
    };

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()}>
            <ModalFormCard title={event.Subject}>
                <Stack component={'form'} gap={2} onSubmit={handleSubmit(createOrUpdateEvent)}>
                    <Controller
                        control={control}
                        name="eventTitle"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                placeholder="Event Title here..."
                                label="Title"
                                fullWidth
                                size="small"
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                    {/* Start and End Times */}
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="selectedStart"
                                render={({ field }) => (
                                    <DateTimePicker
                                        {...field}
                                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                        ampm={false}
                                        maxDate={moment(watch().selectedEnd)}
                                        format={`${dateFormat} HH:mm`}
                                        label="Start time"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="selectedEnd"
                                render={({ field }) => (
                                    <DateTimePicker
                                        {...field}
                                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                        ampm={false}
                                        minDate={moment(watch().selectedStart)}
                                        format={`${dateFormat} HH:mm`}
                                        label="End time"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Project Autocomplete */}
                    {jobs && jobs.projects.length > 0 && (
                        <Controller
                            control={control}
                            name="project"
                            render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                    options={jobs.projects}
                                    getOptionLabel={(option) => option.title}
                                    clearOnEscape={false}
                                    disableClearable={true}
                                    isOptionEqualToValue={(opt, val) => opt === val}
                                    onChange={(e, val) => {
                                        onChange(val);
                                        handleProjectChange(val);
                                    }}
                                    value={value!}
                                    renderInput={(params) => <TextField {...params} label="Project" size="small" />}
                                />
                            )}
                        />
                    )}

                    {/* Client Autocomplete */}
                    {jobs && jobs.clients.length > 0 && (
                        <Controller
                            control={control}
                            name="client"
                            render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                    options={jobs.clients}
                                    getOptionLabel={(option) => option.title}
                                    clearOnEscape
                                    isOptionEqualToValue={(opt, val) => opt === val}
                                    onChange={(e, val) => onChange(val)}
                                    value={value}
                                    renderInput={(params) => <TextField {...params} label="Client" size="small" />}
                                />
                            )}
                        />
                    )}

                    {/* Crew Autocomplete */}
                    {!calendarId && crews && crews.length > 0 && (
                        <Controller
                            control={control}
                            name="crew"
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Autocomplete
                                    options={crews}
                                    getOptionLabel={(option) => option.crew_name}
                                    clearOnEscape
                                    isOptionEqualToValue={(opt, val) => opt === val}
                                    onChange={(e, val) => onChange(val)}
                                    value={value}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Crew" error={!!error} helperText={error?.message} size="small" />
                                    )}
                                />
                            )}
                        />
                    )}

                    {/* Job Address */}
                    <Controller
                        control={control}
                        name="jobAddress"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                label="Address"
                                multiline
                                maxRows={6}
                                {...field}
                                error={!!error}
                                helperText={error?.message}
                                size="small"
                            />
                        )}
                    />

                    {/* Job Comment */}
                    <Controller
                        control={control}
                        name="jobComment"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                label="Details for mounters"
                                multiline
                                fullWidth
                                maxRows={6}
                                size="small"
                                {...field}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                    {/* Files from client */}
                    {watch('clientFiles') && watch('clientFiles').length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder color="primary" />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Client Files'}
                                </Typography>
                            </Box>
                            <FileList
                                files={watch('clientFiles')}
                                handleRemove={(index: number) => {
                                    const clientFiles = getValues('clientFiles');
                                    clientFiles.splice(index, 1);
                                    setValue('clientFiles', clientFiles);
                                }}
                            />
                        </Stack>
                    )}
                    {/* Factory files */}
                    {watch('factoryFiles') && watch('factoryFiles').length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder color="primary" />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Factory Files'}
                                </Typography>
                            </Box>
                            <FileList
                                files={watch('factoryFiles')}
                                handleRemove={(index: number) => {
                                    const factoryFiles = getValues('factoryFiles');
                                    factoryFiles.splice(index, 1);
                                    setValue('factoryFiles', factoryFiles);
                                }}
                            />
                        </Stack>
                    )}
                    {/* Designer files */}
                    {watch('designerFiles') && watch('designerFiles').length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder color="primary" />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Designer Files'}
                                </Typography>
                            </Box>
                            <FileList
                                files={watch('designerFiles')}
                                handleRemove={(index: number) => {
                                    const designerFiles = getValues('designerFiles');
                                    designerFiles.splice(index, 1);
                                    setValue('designerFiles', designerFiles);
                                }}
                            />
                        </Stack>
                    )}
                    {/* Documentation files */}
                    {watch('documentationFiles') && watch('documentationFiles').length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder color="primary" />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Documentation Files'}
                                </Typography>
                            </Box>
                            <FileList
                                files={watch('documentationFiles')}
                                handleRemove={(index: number) => {
                                    const documentationFiles = getValues('documentationFiles');
                                    documentationFiles.splice(index, 1);
                                    setValue('documentationFiles', documentationFiles);
                                }}
                            />
                        </Stack>
                    )}

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button type="submit" variant="contained" color="secondary" size="small">
                            Ok
                        </Button>
                        <Button onClick={() => modal.remove()} variant="contained" size="small">
                            Cancel
                        </Button>
                    </Box>
                </Stack>
            </ModalFormCard>
        </Modal>
    );
});
export default EventEditForm;
