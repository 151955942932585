import { Box } from '@mui/system';
import React from 'react';
import Scheduler from 'ui-component/mounting/Scheduler';
import { InView } from 'react-intersection-observer';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';
import { useAtom } from 'jotai';
import useAuth from 'hooks/useAuth';
import ClientScheduler from 'ui-component/mounting/ClientScheduler';

const OrderMountingScheduler = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);
    return (
        <Box sx={{ p: 0 }}>
            <InView triggerOnce>
                {({ entry, inView, ref }) => (
                    <Box ref={ref}>
                        {inView && (
                            <>
                                {user?.role! === 'client' ? (
                                    <ClientScheduler orderId={orderId!} />
                                ) : (
                                    <Scheduler orderId={orderId!} loadButton />
                                )}
                            </>
                        )}
                    </Box>
                )}
            </InView>
        </Box>
    );
};

export default OrderMountingScheduler;
