import { createRoot } from 'react-dom/client';

// third party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import reportWebVitals from 'reportWebVitals';
import { store } from 'store';
import 'assets/scss/style.scss';
import { dateFormat } from 'utils/data/constant';
import { z, ZodErrorMap } from 'zod';

moment.updateLocale('en', {
    week: {
        dow: 1
    }
});

declare module 'moment' {
    interface Moment {
        originalFormat?: (format?: string) => string;
    }
}
moment.fn.originalFormat = moment.fn.format;
moment.fn.format = function (format?: string) {
    format = format || dateFormat;
    return this.originalFormat!(format);
};

const myErrorMap: ZodErrorMap = (issue, ctx) => {
    switch (issue.code) {
        case 'too_small':
            if (issue.minimum === 1 && issue.type === 'array') {
                return { message: 'Please select at least one option.' };
            } else if (issue.minimum === 1 && issue.type === 'string') {
                return { message: 'This field is required.' };
            } else if (issue.minimum === 2 && issue.type === 'string') {
                return { message: 'This field must have at least 2 characters.' };
            } else {
                return { message: ctx.defaultError };
            }
        case 'invalid_type':
            return { message: `Invalid value type provided for ${issue.path.join('.')}.` };
        default:
            return { message: ctx.defaultError };
    }
};

// Set the global error map
z.setErrorMap(myErrorMap);

if (localStorage.getItem('cookiesCleared')) {
    //Yeah baby, its good.
} else {
    // Clear all cookies
    document.cookie.split(';').forEach((cookie) => {
        document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    localStorage.clear();
    // Set a flag in localStorage indicating that cookies have been cleared
    localStorage.setItem('cookiesCleared', 'true');
    alert('Cookies cleared!');
}

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
    <Provider store={store}>
        <BrowserRouter basename={BASE_PATH}>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
