import { Box, Button, Container, Grid2 as Grid, Tooltip, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { atom, useAtom } from 'jotai';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {
    useFetchCountriesQuery,
    useFetchFactoriesQuery,
    useFetchMountingCrewsQuery,
    useGetSystemInitStatusQuery,
    useSetSystemInitStatusMutation
} from 'store/api/system.api';
import { useEditUserDataMutation, useFetchUserDataQuery } from 'store/api/user.api';
import { UserSchema } from 'types/user.types';
import SubCard from 'ui-component/cards/SubCard';
import UserForm, { UserFormType } from 'views/forms/users/UserForm';
import AuthWrapper from 'views/pages/AuthWrapper';
import SystemCountriesPage from 'views/pages/system/SystemCountriesPage';
import SystemFactoryPage from 'views/pages/system/SystemFactoriesPage';

export const isSystemDisabledAtom = atom(false);

type WrapperProps = { debug?: boolean } & PropsWithChildren;

const Wrapper = ({ children, debug = false }: WrapperProps) => {
    const { user } = useAuth();
    const [activeStep, setActiveStep] = useState(0);
    const formRef = useRef<HTMLFormElement | null>(null);

    const { data: systemInit } = useGetSystemInitStatusQuery(null);
    const [setSystemInit] = useSetSystemInitStatusMutation();
    const [isSystemDisabled, setIsSystemDisabled] = useAtom(isSystemDisabledAtom);

    const { data: userData } = useFetchUserDataQuery({
        userId: user?.id!
    });

    useEffect(() => {
        if (systemInit === false) setIsSystemDisabled(true);
        else setIsSystemDisabled(false);
    }, [systemInit]);

    useEffect(() => {
        console.log({ systemInit });
    }, [systemInit]);

    const steps: {
        label: string;
        fullName: string;
    }[] = [
        {
            label: 'Create Countries',
            fullName: 'Country Creation Module'
        },
        {
            label: 'Create Factories',
            fullName: 'Factory Setup and Configuration'
        },
        {
            label: 'Finish Profile',
            fullName: 'Finish setting up your profile.'
        }
    ];

    const { data: factories } = useFetchFactoriesQuery({});
    const { data: countries } = useFetchCountriesQuery({});
    const { data: crews } = useFetchMountingCrewsQuery({});

    const [nextButtonData, setNextButtonData] = useState<{ disabled: boolean; tooltip: undefined | string }>({
        disabled: false,
        tooltip: undefined
    });

    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (countries?.length! > 0) {
                    setNextButtonData({ disabled: false, tooltip: undefined });
                } else {
                    setNextButtonData({ disabled: true, tooltip: 'One country is required at least' });
                }
                break;
            case 1:
                if (factories?.length! > 0) {
                    setNextButtonData({ disabled: false, tooltip: undefined });
                } else {
                    setNextButtonData({ disabled: true, tooltip: 'One Factory is required at least' });
                }
                break;
            default:
                setNextButtonData({ disabled: false, tooltip: undefined });
        }
    }, [activeStep, countries, factories, crews]);

    useEffect(() => {
        if (!isSystemDisabled && !debug) return;
        else if (!user && !debug) return;
        else if (!userData) return;

        const userValues: UserFormType = {
            username: userData.username,
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.user_email,
            phone: userData.phone_number,
            avatar: userData.avatar,
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            factories: userData.user_factories,
            countries: userData.user_countries,
            crews: userData.user_crews,
            userOrigin: userData.user_origin_country[0],
            role: userData.user_role
        };
        const parseResult = UserSchema.safeParse(userValues);
        console.log({ parseResult });
        if (parseResult.success === true) setSystemInit({ initStatus: true, userId: user?.id! });
    }, [userData]);

    if (!isSystemDisabled && !debug) return <>{children}</>;
    else if (!user && !debug) return <>{children}</>;

    return (
        <>
            <AuthWrapper>
                <Grid container sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    {steps.map((step, index) => {
                        return (
                            <Grid size={{ xs: 12, sm: 'grow' }} sx={{ bgcolor: 'white', border: '1px solid rgb(211, 226, 253);' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                                    <Typography variant="h5">
                                        {index === activeStep ? (
                                            <Typography variant="h6" sx={{ color: 'green', fontWeight: 700 }}>
                                                Current
                                            </Typography>
                                        ) : activeStep > index ? (
                                            <Typography variant="h6" sx={{}}>
                                                Completed
                                            </Typography>
                                        ) : (
                                            <Typography variant="h6" sx={{}}>
                                                Upcoming
                                            </Typography>
                                        )}
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontWeight: index === activeStep ? 700 : 200 }}>
                                        {index + 1}. {step.label}
                                    </Typography>
                                </Box>
                            </Grid>
                        );
                    })}
                    <Grid
                        size={{ xs: 12, sm: 3.5, md: 2 }}
                        sx={{
                            bgcolor: 'white',
                            border: '1px solid rgb(211, 226, 253);',
                            display: 'flex',
                            p: 2
                        }}
                    >
                        <Button
                            color="inherit"
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={() => {
                                setActiveStep((prevActiveStep) => prevActiveStep - 1);
                            }}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Tooltip title={nextButtonData.tooltip}>
                            <Box component={'label'} sx={{ width: '100%' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={nextButtonData.disabled}
                                    onClick={() => {
                                        if (activeStep === steps.length - 1) {
                                            // setSystemInit({ initStatus: true, userId: user?.id! });
                                            if (formRef.current) {
                                                formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                                            }
                                        } else {
                                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                        }
                                    }}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Box>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Container
                    sx={{
                        pt: 2,
                        bgcolor: 'white',
                        height: '100%',
                        minHeight: 'calc(100vh - 72px)',
                        borderLeft: '1px solid rgb(211, 226, 253);',
                        borderRight: '1px solid rgb(211, 226, 253);'
                    }}
                    maxWidth={'md'}
                >
                    <Grid container>
                        <Grid size={12}>
                            <Grid container>
                                {/* <Grid xs={12} sx={{ mb: 2 }}>
                                        <Typography variant="h1">
                                            {activeStep + 1}. {steps[activeStep].fullName}
                                        </Typography>
                                    </Grid> */}
                                {/* {activeStep === 0 ? (
                                    <Grid size={12}>
                                        <Typography variant="h4" sx={{ fontWeight: 200, color: 'rgba(0 ,0 ,0 , 0.50)' }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at augue pretium, gravida erat
                                            vitae, posuere nisl. Vestibulum eu tortor at ligula aliquam facilisis nec sit amet justo.
                                            Vestibulum sit amet nisi vestibulum, cursus neque nec, ornare lorem. Morbi rhoncus porttitor
                                            elit. Aenean at lectus dui. Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                                            per inceptos himenaeos. Donec at metus vel metus ullamcorper congue et in augue. Vestibulum
                                            laoreet, ligula at sodales mattis, ante eros pulvinar quam, eget facilisis nisi arcu vitae
                                            ligula. Aenean eleifend erat id porta ullamcorper. Maecenas tempor tempus libero, eget bibendum
                                            nibh viverra sed. Quisque nulla ligula, egestas eu maximus vel, elementum in lacus. Quisque
                                            tristique rutrum metus. In ac tempus eros. Etiam eu consectetur ipsum. Fusce auctor posuere
                                            metus id convallis. Praesent dui nunc, aliquam et sollicitudin nec, fermentum a ipsum.
                                            Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                            Nulla efficitur sodales metus malesuada mollis. Vivamus sed urna metus. Praesent nec orci vel
                                            nulla fermentum viverra. Nam volutpat ultricies justo, quis feugiat neque molestie vel. Cras
                                            aliquam pellentesque urna eget consequat. Fusce condimentum mollis tortor, sit amet pellentesque
                                            nunc gravida in.
                                        </Typography>
                                        <Box component={ReactPlayer} url="https://www.youtube.com/watch?v=aL27fX5kv9U" sx={{ my: 2 }} />
                                        <Typography variant="h4" sx={{ fontWeight: 200, color: 'rgba(0 ,0 ,0 , 0.50)', mb: 2 }}>
                                            Aenean varius malesuada ipsum, sed ornare tellus eleifend eget. Aliquam massa tellus,
                                            consectetur blandit ante eget, mollis pellentesque elit. Lorem ipsum dolor sit amet, consectetur
                                            adipiscing elit. Phasellus sodales sed turpis at tincidunt. Duis dignissim augue bibendum dolor
                                            commodo, et sollicitudin urna aliquet. Aliquam quam eros, luctus et orci ut, pharetra aliquet
                                            justo. Donec tempus ex sed nibh consequat maximus sit amet non purus. Mauris consequat nulla
                                            nibh, semper eleifend leo ornare ac. In quis mauris sed ex ullamcorper hendrerit quis at sapien.
                                            Pellentesque semper venenatis lorem, quis blandit sapien auctor et. Curabitur facilisis porta
                                            imperdiet. Donec elementum nisl et neque ultricies, eu euismod neque mollis. Vivamus lobortis
                                            maximus pulvinar. Integer suscipit enim et dui aliquam, at porttitor sapien bibendum.
                                        </Typography>
                                    </Grid>
                                ) : activeStep === 1 ? (
                                    <>
                                        <SystemCountriesPage />
                                    </>
                                ) : activeStep === 2 ? (
                                    <>
                                        <SystemFactoryPage />
                                    </>
                                ) : activeStep === 3 ? (
                                    <>
                                        <SystemMountingCrewsPage />
                                    </>
                                ) : (
                                    <></>
                                )} */}

                                <SubCard sx={{ display: activeStep === 0 ? 'initial' : 'none', width: '100%' }}>
                                    <SystemCountriesPage />
                                </SubCard>
                                <SubCard sx={{ display: activeStep === 1 ? 'initial' : 'none', width: '100%' }}>
                                    <SystemFactoryPage />
                                </SubCard>
                                <Box sx={{ display: activeStep === 2 ? 'initial' : 'none' }}>
                                    <UserForm targetUserId={user?.id} ref={formRef} disableToasts />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </AuthWrapper>
        </>
    );
};

export default Wrapper;
