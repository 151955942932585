import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { KeyedObject } from 'types/index.types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth.types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { decodeHtmlEntities, getTextContentOnly } from 'helpers/global.helper';

const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

export const getAuth = async () => {
    const serviceToken = window.localStorage.getItem('serviceToken');
    if (serviceToken && verifyToken(serviceToken)) {
        setSession(serviceToken);
        const response = await axios.post('/v1/token/validate');
        const {
            user_display_name: name,
            user_email: email,
            user_id: id,
            user_nicename: nickname,
            user_avatar_url: avatar,
            user_role: role
        } = response.data.data.user;
        return {
            name,
            email,
            id,
            nickname,
            avatar,
            role
        };
    } else {
        return null;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.post('/v1/token/validate');
                    const {
                        user_display_name: name,
                        user_email: email,
                        user_id: id,
                        user_nicename: nickname,
                        user_avatar_url: avatar,
                        user_role: role,
                        user_limit,
                        subscription_status
                    } = response.data.data.user;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: { name, email, id, nickname, avatar, role, user_limit, subscription_status }
                        }
                    });
                } else {
                    logout();
                }
            } catch (err) {
                logout();
            }
        };

        init();
    }, []);

    const login = async (username: string, password: string) => {
        const response = await axios.post('/v1/token', { username, password });
        const {
            token,
            user_display_name: name,
            user_email: email,
            user_id: id,
            user_nicename: nickname,
            user_avatar_url: avatar,
            user_role: role
        } = response.data;
        if (response.status === 200) {
            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: { name, email, id, nickname, avatar, role }
                }
            });
            navigate('/');
        } else {
            toast.error(getTextContentOnly(response.data.message));
        }
    };

    const authenticateToken = async (authorizationToken: string) => {
        const response = await axios.post('/v1/token/authorize', { token: authorizationToken });
        console.log({ response });
        const {
            token,
            user_display_name: name,
            user_email: email,
            user_id: id,
            user_nicename: nickname,
            user_avatar_url: avatar,
            user_role: role
        } = response.data;
        if (response.status === 200) {
            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: { name, email, id, nickname, avatar, role }
                }
            });
            navigate('/');
        } else {
            toast.error(getTextContentOnly(response.data.message));
            if (response.data.message === 'Invalid token') navigate('/');
        }
    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;
        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = (redirect: boolean = false) => {
        setSession(null);
        dispatch({ type: LOGOUT });
        if (redirect) navigate('/');
    };

    const requestResetPassword = async (email: string) => {
        const response = await axios.post<{ message: string }>('/v1/request_password_reset', { email });
        return response;
    };

    const resetPassword = async (login: string, key: string, password: string) => {
        const response = await axios.post<{ message: string }>('/v1/reset_password', { login, key, password });
        return response;
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{ ...state, login, authenticateToken, logout, register, resetPassword, requestResetPassword, updateProfile }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
