import { ArrowBack, Close } from '@mui/icons-material';
import { Box, Divider, IconButton, LinearProgress, Paper, Stack, SxProps, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface Props {
    sx?: SxProps;
    title: string;
    subtitle?: string;
    onClose?: () => void;
    onBack?: () => void;
    isLoading?: boolean;
    gutter?: boolean;
    Header?: ReactNode;
    animation?: boolean;
}

const ModalFormCard: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    sx,
    title,
    subtitle,
    onClose,
    onBack,
    Header,
    isLoading = false,
    gutter = false,
    animation = true
}) => {
    return (
        <Paper
            elevation={4}
            sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                minWidth: 260,
                maxWidth: 600,
                borderRadius: 2,
                overflow: 'auto',
                ...sx
            }}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: animation ? 0.3 : 0 }}
        >
            <Stack>
                {isLoading && <LinearProgress />}
                <Box sx={{ mb: gutter ? 1 : 2, px: 2, pt: gutter ? 1 : 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {onBack && (
                            <IconButton size="small" onClick={() => onBack()}>
                                <ArrowBack sx={{ width: 20, height: 20 }} />
                            </IconButton>
                        )}
                        <Typography sx={{ my: 'auto' }} variant="h4" fontWeight={600}>
                            {title}
                        </Typography>
                        {subtitle && (
                            <Typography sx={{ my: 'auto' }} variant="body2" fontWeight={400}>
                                {subtitle}
                            </Typography>
                        )}
                        <Box sx={{ flexGrow: 1, my: 'auto' }}>{Header && Header}</Box>
                    </Box>
                    <Box sx={{ mr: 5 }} />
                    {onClose && (
                        <IconButton size="small" onClick={() => onClose()}>
                            <Close sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    )}
                </Box>
                <Divider sx={{ mb: gutter ? 0 : 2 }} />
                <Box
                    // @ts-ignore
                    component={PerfectScrollbar}
                    sx={{
                        maxHeight: { xs: '60vh', sm: '80vh' }
                    }}
                >
                    <Box sx={{ px: gutter ? 0 : 2, pb: gutter ? 0 : 2 }}>{children}</Box>
                </Box>
            </Stack>
        </Paper>
    );
};

export default ModalFormCard;
