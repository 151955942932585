import { Timelapse } from '@mui/icons-material';
import { getAuth } from 'contexts/JWTContext';
import { dispatch } from 'store';
import { mountingApi } from 'store/api/mounting.api';
import { NavItemType } from 'types/index.types';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const MountingSchedulerPage = Loadable(lazy(() => import('views/pages/mountingScheduler/MountingSchedulerPage')));

const getMountingSchedulerMenu = async () => {
    const mountingCrews: NavItemType[] = [];

    const user = await getAuth();

    if (user)
        await dispatch(mountingApi.endpoints.fetchCalendarInformation.initiate({ userId: user?.id })).then(({ data }) => {
            if (!data || data.length === 0) return;
            mountingCrews.push({
                id: 'mountingAll',
                title: 'All',
                type: 'item',
                icon: undefined,
                url: '/mounting/scheduler',
                breadcrumbs: false
            });
            for (const item of data)
                mountingCrews.push({
                    id: item.crew_abbreviation,
                    title: item.crew_name,
                    type: 'item',
                    icon: undefined,
                    url: `/mounting/scheduler/${item.id}`,
                    breadcrumbs: false,
                    component: <MountingSchedulerPage />
                });
        });

    const mountingScheduler: NavItemType = {
        id: 'mountingScheduler',
        title: 'Scheduler',
        icon: Timelapse,
        type: 'group',
        url: '/mounting/scheduler',
        routes: false,
        children: mountingCrews,
        probhitedRoles: ['client']
    };

    return mountingScheduler;
};

export default getMountingSchedulerMenu;
