import { z } from 'zod';
import { FileSchema } from './file.types';

export const crewSchema = z.object({
    id: z.string(),
    crew_name: z.string(),
    color: z.string(),
    crew_abbreviation: z.string(),
    start_of_week: z.string()
});

export type Crew = z.infer<typeof crewSchema>;

export const workerSchema = z.object({
    ID: z.string(),
    display_name: z.string(),
    user_email: z.string()
});

export type Worker = z.infer<typeof workerSchema>;

export const clientSchema = z.object({
    id: z.number(),
    title: z.string()
});

export type Client = z.infer<typeof clientSchema>;

export const projectSchema = z.object({
    id: z.number(),
    title: z.string(),
    client: z.number(),
    address: z.string()
});

export type Project = z.infer<typeof projectSchema>;

// export const clientFileSchema = z.object({
//     src: z.string(),
//     name: z.string(),
//     id: z.number()
// });

// export type ClientFile = z.infer<typeof clientFileSchema>;

// export const factoryFileSchema = z.object({
//     src: z.string(),
//     name: z.string()
// });

// export type FactoryFile = z.infer<typeof factoryFileSchema>;

// export const designerFileSchema = z.object({
//     src: z.string(),
//     name: z.string()
// });

// export type DesignerFile = z.infer<typeof designerFileSchema>;

export const eventSchema = z.object({
    Id: z.string(),
    Subject: z.string(),
    StartTime: z.date(),
    EndTime: z.date(),
    project: z.number(),
    client: z.string(),
    clientPostalPlace: z.string(),
    clientPostalAddress: z.string(),
    clientPhoneNumber: z.string(),
    author: z.string(),
    comment: z.string(),
    workers: z.array(workerSchema),
    address: z.string().optional(),
    clientFiles: z.array(FileSchema).nullable(),
    factoryFiles: z.array(FileSchema).nullable(),
    designerFiles: z.array(FileSchema).nullable(),
    documentationFiles: z.array(FileSchema).nullable(),
    region: z.string(),
    color: z.string(),
    local_id: z.string().optional()
});

export type Event = z.infer<typeof eventSchema>;

export const minimizedEventSchema = z.object({
    // Id: z.string(),
    Subject: z.string(),
    StartTime: z.date(),
    EndTime: z.date(),
    color: z.string()
});

export type MinimizedEvent = z.infer<typeof minimizedEventSchema>;

export const rootSchema = z.object({
    workers: z.array(workerSchema),
    clients: z.array(clientSchema),
    projects: z.array(projectSchema),
    events: z.array(eventSchema)
});

export type Root = z.infer<typeof rootSchema>;
