import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid, MenuItem, Modal, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useCreateClientMutation } from 'store/api/clients.api';
import { gridSpacing } from 'store/constant';
import { CompanyClient, CompanyClientSchema } from 'types/client.types';
import ModalFormCard from 'ui-component/cards/ModalCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import countryList from 'utils/data/countries';
import CompanyForm from 'views/forms/clients/CompanyForm';

const ComapanyAddModal = NiceModal.create(() => {
    const modal = useModal();

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()} sx={{ overflowY: 'scroll' }}>
            <ModalFormCard title="Company">
                <CompanyForm isRepresentativesEnabled={false} />
            </ModalFormCard>
        </Modal>
    );
});

export default ComapanyAddModal;
