import { Attachment } from '@mui/icons-material';
import { Chip, Stack, StackProps } from '@mui/material';
import useLightbox from 'hooks/useLightbox';
import React, { useEffect, useState } from 'react';
import { File as CustomFile, Subtype } from 'types/file.types';

interface Props extends StackProps {
    files: CustomFile[] | File[] | FileList;
    handleRemove?: (index: number) => void;
}

const FileList = ({ files: initialFiles, handleRemove, direction = 'row', ...props }: Props) => {
    const { show } = useLightbox();

    const [files, setFiles] = useState<CustomFile[] | File[]>([]);

    useEffect(() => {
        if (Array.isArray(initialFiles)) {
            setFiles(initialFiles as CustomFile[] | File[]);
        } else {
            const filesSkeleton = Array.from(initialFiles as FileList).map((file) => file);
            setFiles(filesSkeleton);
        }
    }, [initialFiles]);

    return (
        <Stack {...props} gap={2} direction={direction} sx={{ overflow: 'auto', ...props.sx }}>
            {files.map((file, index) => {
                return (
                    <Chip
                        size="small"
                        avatar={<Attachment />}
                        label={file.name}
                        onClick={() =>
                            file instanceof File
                                ? show(URL.createObjectURL(file), { subtype: file.type.split('/')[1] as Subtype })
                                : show(file.src)
                        }
                        variant="outlined"
                        onDelete={handleRemove ? () => handleRemove(index) : undefined}
                        key={file instanceof File ? index : file.id}
                    />
                );
            })}
        </Stack>
    );
};

export default FileList;
