import { File } from 'types/file.types';
import { Client, Crew, Event, Project, Worker } from 'types/mounting.types';
import { api } from './api';

export const mountingApi = api.injectEndpoints({
    endpoints: (builder) => ({
        fetchCalendarInformation: builder.query<Crew[], { userId: number; calendarId?: string }>({
            providesTags: ['MountingEvents'],
            query: ({ userId, calendarId }) => {
                const requestBody = {
                    user_id: userId,
                    calendar_id: calendarId
                };
                return { url: '/v1/mounting_schedule/load_calendar_information', method: 'POST', body: JSON.stringify(requestBody) };
            }
        }),
        syncGoogleCalendar: builder.mutation<{}[], { id: number; region?: string }>({
            query: (props) => {
                return { url: '/v1/mounting_schedule/sync_google_calendar', method: 'POST', body: JSON.stringify(props) };
            }
        }),
        fetchJobs: builder.query<
            { workers: Worker[]; clients: Client[]; projects: Project[]; events: Event[] },
            { region: any; userID: number }
        >({
            providesTags: ['MountingEvents'],
            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/load_jobs',
                    method: 'POST',
                    body: JSON.stringify(props)
                };
            }
        }),
        fetchEventDuration: builder.query<
            {
                events: {
                    id: string;
                    order_id: string;
                    title: string;
                    author: string;
                    client: string;
                    region: string;
                    comment: string;
                    address: string;
                    start_date: string;
                    end_date: string;
                    google_calendar_event_id: string;
                    local_id: string;
                }[];
                clientFiles: File[];
                factoryFiles: File[];
                designerFiles: File[];
                documentationFiles: File[];
            },
            { event_id: string }
        >({
            providesTags: ['MountingEvents'],
            query: ({ event_id }) => {
                return {
                    url: 'v1/mounting_schedule/check_event_duration',
                    method: 'POST',
                    body: JSON.stringify({ event_id })
                };
            }
        }),
        deleteMountingJob: builder.mutation<
            any,
            {
                selectedEvent: string;
                local_id: string;
            }
        >({
            invalidatesTags: ['MountingEvents'],

            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/delete_mounting_job',
                    method: 'POST',
                    body: JSON.stringify(props)
                };
            }
        }),
        fetchFilesForEvent: builder.query<
            { clientFiles: File[]; factoryFiles: File[]; documentationFiles: File[]; designerFiles: File[] },
            { projectId: string; postId: number }
        >({
            providesTags: ['MountingEvents'],

            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/load_files_for_event',
                    method: 'POST',
                    body: JSON.stringify(props)
                };
            }
        }),
        fetchFiles: builder.query<
            { clientFiles: File[]; factoryFiles: File[]; documentationFiles: File[]; designerFiles: File[] },
            { projectId: number }
        >({
            providesTags: ['MountingEvents'],

            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/load_files',
                    method: 'POST',
                    body: JSON.stringify(props)
                };
            }
        }),
        fetchClientInfo: builder.query<any, { clientId: number; postId: number }>({
            providesTags: ['Clients'],
            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/load_client_info',
                    method: 'POST',
                    body: JSON.stringify(props)
                };
            }
        }),
        addOrUpdateJob: builder.mutation<any, any>({
            invalidatesTags: ['MountingEvents', 'Orders'],
            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/add_update_new_job',
                    method: 'POST',
                    body: JSON.stringify(props)
                };
            }
        }),
        sendMountingCreationNotification: builder.mutation<unknown, { orderId: number; startDate: string; endDate: string }>({
            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/send_client_email',
                    method: 'POST',
                    body: JSON.stringify({ order_id: props.orderId, start_date: props.startDate, selected_date: props.endDate })
                };
            }
        }),
        sendMountingCreationNotificationClient: builder.mutation<unknown, { orderId: number; startDate: string; endDate: string }>({
            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/client_assigned_mounting',
                    method: 'POST',
                    body: JSON.stringify({ order_id: props.orderId, start_date: props.startDate, selected_date: props.endDate })
                };
            }
        }),
        sendMountingDeletionNotification: builder.mutation<unknown, { mountingId: string; orderId: number }>({
            query: (props) => {
                return {
                    url: '/v1/mounting_schedule/send_client_email_deleted_mounting',
                    method: 'POST',
                    body: JSON.stringify({
                        mounting_id: props.mountingId,
                        order_id: props.orderId
                    })
                };
            }
        })
    })
});

export const {
    useFetchCalendarInformationQuery,
    useSyncGoogleCalendarMutation,
    useFetchJobsQuery,
    useLazyFetchJobsQuery,
    useLazyFetchEventDurationQuery,
    useFetchEventDurationQuery,
    useLazyFetchFilesForEventQuery,
    useLazyFetchFilesQuery,
    useLazyFetchClientInfoQuery,
    useDeleteMountingJobMutation,
    useAddOrUpdateJobMutation,
    useSendMountingCreationNotificationMutation,
    useSendMountingCreationNotificationClientMutation,
    useSendMountingDeletionNotificationMutation
} = mountingApi;
