import NiceModal from '@ebay/nice-modal-react';
import { CloudUpload, List, ViewQuilt } from '@mui/icons-material';
import {
    Backdrop,
    Box,
    Button,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    Grid2 as Grid
} from '@mui/material';
import { downloadUrl } from 'helpers/files.helper';
import { handlePermissionCheck } from 'helpers/global.helper';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import {
    useDeleteOrderGalleryImageMutation,
    useDownloadSelectedGalleryImagesMutation,
    useEditOrderGalleryImageMutation,
    useFetchOrderGalleryQuery
} from 'store/api/orders.api';
import { useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import { GalleryDisplay, GalleryFile } from 'types/orders.types';
import Cover from 'ui-component/Cover';
import Gallery, { gallerySelectedImagesAtom } from 'ui-component/gallery/Gallery';
import DebouncedTextField from 'ui-component/inputs/DebouncedTextField';
import MobileCompactor from 'ui-component/mobile/MobileCompactor';
import { useImmer } from 'use-immer';
import { getMessage } from 'utils/messages';
import { ReactSwal } from 'utils/reactswal';
import { GalleryImageEditModal, GalleryImageUploadConfirmationModal } from 'views/pages/gallery/GalleryPage';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';

const OrderGallery = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedImages] = useAtom(gallerySelectedImagesAtom);
    const [searchTerm, setSearchTerm] = useState('');
    const [gallery, setGallery] = useImmer<GalleryFile[]>([]);

    const {
        data: galleryInitialData,
        isFetching: isGalleryFetching,
        isLoading: isGalleryLoading
    } = useFetchOrderGalleryQuery({ orderId: orderId!, categories: selectedCategories, search: searchTerm }, { skip: !orderId });
    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    useEffect(() => {
        if (!galleryInitialData) return;
        setGallery(galleryInitialData.images);
    }, [galleryInitialData]);

    const [galleryDisplay, setGalleryDisplay] = useState<GalleryDisplay>('list');

    const [deleteGalleryImage] = useDeleteOrderGalleryImageMutation();
    const [downloadSelectedImages] = useDownloadSelectedGalleryImagesMutation();

    const handleDeleteImages = (imageIds: number[]) => {
        if (!orderId) return;
        deleteGalleryImage({ imageIds });
    };

    const handleEditImage = (imageId: number) => {
        if (!orderId) return;
        NiceModal.show(GalleryImageEditModal, { imageId });
    };

    const [editGalleryImage] = useEditOrderGalleryImageMutation();
    const stopEventPropagation = (e: any) => e.stopPropagation();
    return (
        <Dropzone
            useFsAccessApi={false}
            noClick={true}
            noDragEventsBubbling
            onDrop={(acceptedFiles, rejectedFiles, event) => {
                if (!orderId) return;
                const dataTransfer = new DataTransfer();
                acceptedFiles.forEach((file) => dataTransfer.items.add(file));
                NiceModal.show(GalleryImageUploadConfirmationModal, {
                    files: dataTransfer.files,
                    categories: galleryInitialData?.categories!,
                    orderId: orderId
                });
            }}
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <>
                    <Backdrop
                        open={isDragActive}
                        sx={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            width: '100%',
                            height: '100% - 40px',
                            transition: 'opacity 100ms ease-in-out',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pointerEvents: 'none',
                            flexDirection: 'column',
                            gap: 1,
                            mt: '40px'
                        }}
                    >
                        <CloudUpload
                            sx={{
                                color: 'white',
                                transform: 'rotate(5deg)',
                                width: 100,
                                height: 100,
                                filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))'
                            }}
                        />
                        <Typography variant="h2" color="white">
                            Upload to gallery
                        </Typography>
                    </Backdrop>
                    <Box component={'section'} sx={{ border: '1px dashed rgba(0,0,0,0.2)', py: 1, mb: 1 }} onClick={stopEventPropagation}>
                        <Box
                            {...getRootProps()}
                            onClick={undefined}
                            onKeyDown={undefined}
                            onFocus={undefined}
                            sx={{
                                position: 'relative'
                            }}
                        >
                            <input {...getInputProps()} />
                            <Box sx={{ pointerEvents: isDragActive ? 'none' : 'all' }}>
                                <MobileCompactor collapsedText="Show filters" expandedText="Hide filters" sx={{ p: 0, mb: 0.5, mx: 1 }}>
                                    <Grid size={{ xs: 12, md: 3, lg: 2 }}>
                                        <DebouncedTextField
                                            value={searchTerm}
                                            onChange={(value) => setSearchTerm(value as string)}
                                            label="Search"
                                            sx={{ m: 0 }}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid size={{ xs: 12, sm: 6, lg: 3 }} alignItems={'center'} sx={{ display: 'flex' }}>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel>
                                                {galleryInitialData?.categories && galleryInitialData.categories.length === 0
                                                    ? 'No categories found!'
                                                    : 'Categories'}
                                            </InputLabel>
                                            <Select
                                                multiple
                                                value={selectedCategories}
                                                disabled={
                                                    galleryInitialData?.categories && galleryInitialData.categories.length === 0
                                                        ? true
                                                        : false
                                                }
                                                input={<OutlinedInput label="Categories" size="small" />}
                                                fullWidth
                                                onChange={(event) => setSelectedCategories(event.target.value as [])}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                            >
                                                {galleryInitialData && galleryInitialData.categories.length > 0 ? (
                                                    galleryInitialData?.categories.map((category) => (
                                                        <MenuItem key={category} value={category}>
                                                            {category}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem key="empty" value="empty" disabled>
                                                        No categories found
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {(handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') ||
                                        user?.role === 'mounting_worker') && (
                                        <>
                                            <Grid size={{ xs: 12, sm: 3, lg: 1.5 }} alignItems={'center'} display={'flex'}>
                                                <Button variant="contained" component="label" sx={{ width: '100%' }}>
                                                    Upload Files
                                                    <input
                                                        type="file"
                                                        hidden
                                                        multiple
                                                        onChange={(event) => {
                                                            if (
                                                                event.currentTarget.files &&
                                                                event.currentTarget.files.length > 0 &&
                                                                orderId
                                                            )
                                                                NiceModal.show(GalleryImageUploadConfirmationModal, {
                                                                    files: event.currentTarget.files,
                                                                    categories: galleryInitialData?.categories!,
                                                                    orderId: orderId
                                                                });
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>
                                        </>
                                    )}

                                    {handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') && (
                                        <>
                                            {galleryDisplay === 'list' && (
                                                <Grid
                                                    size={{ xs: 12, sm: 3, lg: 1.5 }}
                                                    alignItems={'center'}
                                                    display={'flex'}
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                >
                                                    <Button
                                                        variant={'contained'}
                                                        disabled={selectedImages?.size === 0}
                                                        onClick={() => {
                                                            const selectedImagesArray: number[] = [];
                                                            selectedImages?.forEach((imageId) => selectedImagesArray.push(imageId));
                                                            Swal.fire({
                                                                title: 'Are you sure?',
                                                                text: `This will delete ${selectedImagesArray.length} selected files. You won't be able to revert this!`,
                                                                icon: 'warning',
                                                                showCancelButton: true
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    handleDeleteImages(selectedImagesArray);
                                                                    // @ts-ignore
                                                                    selectedImages.clear();
                                                                }
                                                            });
                                                        }}
                                                        color="error"
                                                        fullWidth
                                                    >
                                                        Delete Selected
                                                    </Button>
                                                </Grid>
                                            )}

                                            {galleryDisplay === 'list' && (
                                                <Grid
                                                    size={{ xs: 12, sm: 3, lg: 1.5 }}
                                                    alignItems={'center'}
                                                    display={'flex'}
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                >
                                                    <Button
                                                        variant={'contained'}
                                                        disabled={selectedImages?.size === 0}
                                                        onClick={async () => {
                                                            const imagesIds: number[] = [];
                                                            selectedImages?.forEach((imageId) => imagesIds.push(imageId));

                                                            if (imagesIds.length === 1) {
                                                                const image = gallery.find((image) => image.id === imagesIds[0]);
                                                                if (!image) return;

                                                                downloadUrl({
                                                                    name: image?.name,
                                                                    src: image?.src
                                                                });
                                                            } else {
                                                                const file = await downloadSelectedImages({ imagesIds, userId: user?.id! });

                                                                if ('data' in file && file.data !== null)
                                                                    downloadUrl({ src: file.data, name: 'Gallery Export.zip' });
                                                            }

                                                            // @ts-ignore
                                                            selectedImages.clear();
                                                        }}
                                                        color="info"
                                                        fullWidth
                                                    >
                                                        Download Selected
                                                    </Button>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                    <Grid size={{ xs: 4, sm: 3, lg: 'auto' }}>
                                        <ToggleButtonGroup
                                            value={galleryDisplay}
                                            exclusive
                                            onChange={(event, value) => setGalleryDisplay(value)}
                                            sx={{
                                                display: 'flex',
                                                gap: 0
                                            }}
                                        >
                                            <ToggleButton size="small" value="list">
                                                <List />
                                            </ToggleButton>
                                            <ToggleButton size="small" value={'masonry'}>
                                                <ViewQuilt />
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </MobileCompactor>
                                {isGalleryLoading || isGalleryFetching ? (
                                    <Cover sx={{ width: '100%', height: '400px', mx: 'auto', my: 'auto' }} />
                                ) : (
                                    <Gallery
                                        images={gallery}
                                        isImagesFetching={isGalleryFetching}
                                        onDelete={
                                            handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')
                                                ? handleDeleteImages
                                                : undefined
                                        }
                                        onEdit={
                                            handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') ? handleEditImage : undefined
                                        }
                                        sx={{ height: 600, mx: 1 }}
                                        display={galleryDisplay}
                                        categories={galleryInitialData?.categories}
                                        rowsEditEnabled={handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') ? true : false}
                                        onRowsChange={async (rows, edited) => {
                                            let shouldContinue = true;
                                            const row = rows[edited.indexes[0]];
                                            const imageIds: number[] = [row.id];
                                            const images: GalleryFile[] = [
                                                galleryInitialData?.images.find((image) => image.id === row.id)!
                                            ];
                                            selectedImages?.forEach((imageId) => {
                                                if (!imageIds.includes(imageId)) {
                                                    if (galleryInitialData) {
                                                        images.push(galleryInitialData?.images.find((image) => image.id === imageId)!);
                                                    }
                                                    imageIds.push(imageId);
                                                }
                                            });

                                            if (imageIds.length > 1) {
                                                await ReactSwal.fire({
                                                    title: 'Are you sure?',
                                                    html: (
                                                        <Stack sx={{ overflow: 'hidden', display: 'flex' }} gap={2}>
                                                            <Typography sx={{ textAlign: 'start' }}>
                                                                Are you sure you want to perform the following update on those{' '}
                                                                {images.length} records?
                                                            </Typography>
                                                            <Stack sx={{ display: 'flex', justifyContent: 'start' }} gap={0.5}>
                                                                <Typography sx={{ textAlign: 'start' }}>
                                                                    Field: {edited.column.name}
                                                                </Typography>
                                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                                    <Typography sx={{ whiteSpace: 'nowrap' }}>Update to: </Typography>
                                                                    {edited.column.key === 'categories' ? (
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                maxWidth: '70%',
                                                                                height: '100%',
                                                                                overflowX: 'auto',

                                                                                gap: 1
                                                                            }}
                                                                        >
                                                                            {row.categories.map((category) => (
                                                                                <Chip variant="outlined" label={category} size="small" />
                                                                            ))}
                                                                        </Box>
                                                                    ) : (
                                                                        <Typography>{row.text}</Typography>
                                                                    )}
                                                                </Box>
                                                            </Stack>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                sx={{
                                                                    overflowX: 'hidden',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    overflowY: 'auto'
                                                                }}
                                                            >
                                                                {images.map((image) => (
                                                                    <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                                                                        <Box
                                                                            src={image.src}
                                                                            component={'img'}
                                                                            sx={{
                                                                                height: 50,
                                                                                width: '100%',
                                                                                objectFit: 'cover',
                                                                                border: '1px solid #e3e8ef'
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </Stack>
                                                    ),
                                                    icon: 'warning',
                                                    showCancelButton: true
                                                }).then((result) => {
                                                    if (result.isDenied || result.isDismissed || !result.isConfirmed) {
                                                        shouldContinue = false;
                                                        return;
                                                    }
                                                });
                                            }

                                            if (!shouldContinue) return;

                                            setGallery((draft) => {
                                                imageIds?.forEach((imageId) => {
                                                    if (edited.column.key === 'categories') {
                                                        draft[gallery.findIndex((image) => image.id === imageId)].categories =
                                                            row.categories;
                                                    } else {
                                                        draft[gallery.findIndex((image) => image.id === imageId)].text = row.text;
                                                    }
                                                });
                                            });
                                            toast.promise(
                                                editGalleryImage({
                                                    imageIds,
                                                    categories: edited.column.key === 'categories' ? row.categories : null,
                                                    description: edited.column.key === 'text' ? row.text || '' : null
                                                }),
                                                { pending: getMessage('savePending'), success: getMessage('saved') },
                                                {
                                                    autoClose: 1000
                                                }
                                            );
                                            // @ts-ignore
                                            if (selectedImages?.size > 0) selectedImages.clear();
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </Dropzone>
    );
};

export default OrderGallery;
