import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, ButtonGroup, Modal, TextField } from '@mui/material';
import { Stack, height } from '@mui/system';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useFetchClientsQuery } from 'store/api/clients.api';
import ModalFormCard from 'ui-component/cards/ModalCard';
import OrderCreateForm from 'views/forms/orders/OrderCreateForm';

const OrderCreateModal = NiceModal.create(() => {
    const modal = useModal();

    return (
        <Modal
            open={modal.visible}
            onClose={modal.remove}
            sx={{
                overflow: 'scroll'
            }}
        >
            <ModalFormCard
                title="Create order"
                sx={{
                    minWidth: 280,
                    maxWidth: '100%'
                }}
            >
                <OrderCreateForm />
            </ModalFormCard>
        </Modal>
    );
});

export default OrderCreateModal;
