import { CompanyClient, MinimalClient, NaturalPersonClient } from 'types/client.types';
import { api } from './api';

export const clientsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        fetchClients: builder.query<MinimalClient[], null>({
            providesTags: ['Clients'],
            query: () => {
                return { url: 'v1/clients/get', method: 'POST' };
            }
        }),
        fetchClient: builder.query<CompanyClient | NaturalPersonClient, { clientId: number }>({
            providesTags: ['Clients'],
            query: ({ clientId }) => {
                const requestData = {
                    client_id: clientId
                };
                return {
                    url: 'v1/clients/get_client_data',
                    method: 'POST',
                    body: requestData
                };
            }
        }),
        deleteClients: builder.mutation<null, { clientIds: number[] }>({
            invalidatesTags: ['Clients'],
            query: ({ clientIds }) => {
                const requestData = {
                    ids: clientIds
                };
                return {
                    url: '/v1/clients/delete',
                    method: 'POST',
                    body: requestData
                };
            }
        }),
        createClient: builder.mutation<{ clientId: number }, { client: CompanyClient | NaturalPersonClient }>({
            query: ({ client }) => {
                const requestData = client;
                return { url: 'v1/clients/create_client', method: 'POST', body: requestData };
            },
            invalidatesTags: ['Clients']
        }),
        editClient: builder.mutation<null, { client: CompanyClient | NaturalPersonClient; clientId: number }>({
            query: ({ client, clientId }) => {
                const requestData = { client, clientId };
                return { url: 'v1/clients/edit_client', method: 'POST', body: requestData };
            },
            invalidatesTags: ['Clients']
        }),
        fetchCLientAddress: builder.query<null, { clientId: number }>({
            query: ({ clientId }) => {
                const requestData = { client_id: clientId };
                return { url: 'v1/client/get_client_full_address', method: 'POST', body: requestData };
            }
        }),
        createClientUser: builder.mutation<null, { clientId: number }>({
            query: ({ clientId }) => {
                const requestData = { clientId: clientId };
                return { url: 'v1/clients/create_user', method: 'POST', body: requestData };
            },
            invalidatesTags: ['Clients']
        }),
        updateClientEmail: builder.mutation<null, { clientId: number; email: string }>({
            query: ({ clientId, email }) => {
                const requestData = { clientId: clientId, email: email };
                return { url: 'v1/clients/update_client_email', method: 'POST', body: requestData };
            },
            invalidatesTags: ['Clients']
        })
    })
});

export const {
    useFetchClientsQuery,
    useDeleteClientsMutation,
    useCreateClientMutation,
    useFetchClientQuery,
    useEditClientMutation,
    useCreateClientUserMutation,
    useUpdateClientEmailMutation
} = clientsApi;
