import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AccessTime, AccountBox, AccountTree, Chat, Folder, LocationOn, People, Phone } from '@mui/icons-material';
import { Button, Chip, Modal, Skeleton, Typography, Link as MuiLink } from '@mui/material';
import { Box, Stack } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import {
    useDeleteMountingJobMutation,
    useFetchCalendarInformationQuery,
    useFetchJobsQuery,
    useLazyFetchEventDurationQuery,
    useSyncGoogleCalendarMutation,
    useSendMountingDeletionNotificationMutation,
    useFetchEventDurationQuery
} from 'store/api/mounting.api';
import { Client, Crew, Event, Project } from 'types/mounting.types';
import FileList from 'ui-component/files/FileList';
import EventEditForm from './EventEditForm';
import { toast } from 'react-toastify';
import { getMessage } from 'utils/messages';
import ModalFormCard from 'ui-component/cards/ModalCard';
import moment from 'moment';
import { dateFormat } from 'utils/data/constant';
import { Link } from 'react-router-dom';

interface Props {
    calendarId: string | undefined;
    event: Event;
    readOnly?: boolean;
}

const EventViewModal = NiceModal.create(({ calendarId, event, readOnly = false }: Props) => {
    const modal = useModal();
    const { user } = useAuth();

    const [deleteMountingJob] = useDeleteMountingJobMutation();
    const [syncGoogleCalendar] = useSyncGoogleCalendarMutation();
    const [checkEventDuration] = useLazyFetchEventDurationQuery();
    const [sendMountingDeletionNotification] = useSendMountingDeletionNotificationMutation();

    const { data: crews } = useFetchCalendarInformationQuery({ userId: user!.id, calendarId: calendarId });
    const { data: jobs } = useFetchJobsQuery(
        {
            region: crews?.map((item) => parseInt(item.id)),
            userID: user!.id
        },
        { skip: !crews }
    );
    const { data: duration } = useFetchEventDurationQuery({ event_id: event.Id }, { skip: !event });

    const [client, setClient] = useState<null | Client>();
    const [crew, setCrew] = useState<null | Crew>();
    const [project, setProject] = useState<null | Project>();

    useEffect(() => {
        if (!jobs || !crews) return;
        const client = jobs?.clients.find((obj) => obj.id === parseInt(event.client));
        if (client) setClient(client);
        const crew = crews?.find((obj) => obj.id === event.region);
        if (crew) setCrew(crew);
        const project = jobs?.projects.find((obj) => obj.id === event.project);
        if (project) setProject(project);
    }, [jobs, crews]);

    const handleDelete = () => {
        if (!user) return;
        modal.remove();
        checkEventDuration({ event_id: event.Id }).then(({ data }) => {
            if (!data) return;
            const events = data.events;
            sendMountingDeletionNotification({ orderId: parseInt(events[0].order_id), mountingId: events[0].local_id });
            toast.promise(
                deleteMountingJob({ local_id: events[0].local_id, selectedEvent: event.Id }).then(() => {
                    if (calendarId) syncGoogleCalendar({ id: user.id, region: calendarId });
                }),
                { pending: getMessage('deletePending'), error: getMessage('deleteError'), success: getMessage('deleted') }
            );
        });
    };

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()}>
            <ModalFormCard
                title={event.Subject}
                sx={{
                    minwidth: 400
                }}
            >
                {/* Start and End Times */}
                <Stack gap={2} mb={2}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <AccessTime />
                        <Typography
                            variant="subtitle1"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100%)'
                            }}
                        >
                            {moment(event.StartTime).format(`${dateFormat} HH:mm`)} - {moment(event.EndTime).format(`${dateFormat} HH:mm`)}
                        </Typography>
                    </Box>
                    {/* Selected project number */}
                    {project && (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center'
                            }}
                        >
                            <AccountTree />
                            <Typography
                                component={Link}
                                to={`/order/${project.id}`}
                                variant="h6"
                                onClick={() => modal.remove()}
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: 'calc(100%)',
                                    textDecoration: 'none',
                                    color: 'revert',
                                    fontWeight: 600
                                }}
                            >
                                {project.title}
                            </Typography>
                        </Box>
                    )}

                    {/* Assigned Workers */}
                    {crew !== undefined && (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center'
                            }}
                        >
                            <People />
                            {crew ? (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {crew && crew.crew_name && (
                                        <Chip label={crew.crew_name} key={crew.crew_name} size="small" variant="outlined" />
                                    )}
                                </Box>
                            ) : (
                                <Skeleton width={80} height={24} />
                            )}
                        </Box>
                    )}

                    {/* Selected Client */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center'
                        }}
                    >
                        <AccountBox />
                        {client ? (
                            <Typography
                                variant="h6"
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: 'calc(100%)'
                                }}
                            >
                                {client.title}
                            </Typography>
                        ) : (
                            <Skeleton width={80} sx={{ fontSize: '0.75rem' }} />
                        )}
                    </Box>

                    {/* Job Address */}
                    {event.address && (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center'
                            }}
                        >
                            <LocationOn />
                            <Box
                                sx={{
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {event.address}
                                </Typography>
                                {event.clientPostalPlace && (
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: 'calc(100%)'
                                        }}
                                    >
                                        {event.clientPostalPlace}
                                    </Typography>
                                )}
                                {event.clientPostalAddress && (
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: 'calc(100%)'
                                        }}
                                    >
                                        {event.clientPostalAddress}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                    {/* Phone Number */}
                    {event.clientPhoneNumber && (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center'
                            }}
                        >
                            <Phone />
                            <MuiLink href={`tel:${event.clientPhoneNumber}`}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {event.clientPhoneNumber}
                                </Typography>
                            </MuiLink>
                        </Box>
                    )}

                    {/* Job Comment */}
                    {event.comment && (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center'
                            }}
                        >
                            <Chat />
                            <Typography
                                variant="h6"
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: 'calc(100%)'
                                }}
                            >
                                {event.comment}
                            </Typography>
                        </Box>
                    )}

                    {/* Files from client */}
                    {duration && duration.clientFiles && duration.clientFiles.length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Client Files'}
                                </Typography>
                            </Box>
                            <FileList files={duration.clientFiles} />
                        </Stack>
                    )}
                    {/* Factory files */}
                    {duration && duration.factoryFiles && duration.factoryFiles.length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Factory Files'}
                                </Typography>
                            </Box>
                            <FileList files={duration.factoryFiles} />
                        </Stack>
                    )}
                    {/* Designer files */}
                    {duration && duration.designerFiles && duration.designerFiles.length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Designer Files'}
                                </Typography>
                            </Box>
                            <FileList files={duration.designerFiles} />
                        </Stack>
                    )}
                    {/* Documentation files */}
                    {duration && duration.documentationFiles && duration.documentationFiles.length > 0 && (
                        <Stack gap={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Folder />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 'calc(100%)'
                                    }}
                                >
                                    {'Documentation Files'}
                                </Typography>
                            </Box>
                            <FileList files={duration.documentationFiles} />
                        </Stack>
                    )}
                </Stack>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button color="primary" size="small" variant="contained" onClick={() => modal.remove()}>
                        Done
                    </Button>
                    {!readOnly && (
                        <>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    modal.remove();
                                    if (!duration) return;
                                    NiceModal.show(EventEditForm, {
                                        event: {
                                            ...event,
                                            designerFiles: duration.designerFiles,
                                            documentationFiles: duration.documentationFiles,
                                            factoryFiles: duration.factoryFiles,
                                            clientFiles: duration.clientFiles
                                        },
                                        calendarId
                                    });
                                }}
                            >
                                Edit
                            </Button>
                            <Button color="error" size="small" variant="contained" onClick={() => handleDelete()}>
                                Delete
                            </Button>
                        </>
                    )}
                </Box>
            </ModalFormCard>
        </Modal>
    );
});

export default EventViewModal;
