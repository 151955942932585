import NiceModal from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Add, Close } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
    Grid2 as Grid
} from '@mui/material';
import { Stack } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateClientMutation, useEditClientMutation, useFetchClientQuery, useFetchClientsQuery } from 'store/api/clients.api';
import { useFetchUserDataQuery } from 'store/api/user.api';
import { gridSpacing } from 'store/constant';
import { MinimalClient, NaturalPersonClient, NaturalPersonClientSchema } from 'types/client.types';
import Loader from 'ui-component/Loader';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { clientStepAtom } from 'ui-component/modals/clients/ClientManageModal';
import ComapanyAddModal from 'ui-component/modals/clients/CompanyAddModal';
import countryList from 'utils/data/countries';
import { z } from 'zod';

interface Props {
    clientId?: number;
    isCompaniesEnabled?: boolean;
    isMinimal?: boolean;
    isModal?: boolean;
    redirect?: boolean;
    onClose?: () => void;
    onSubmitAdditions?: (clientId: number) => void;
}

const NaturalPersonForm = ({
    clientId,
    isCompaniesEnabled = true,
    isMinimal = false,
    redirect = false,
    onClose,
    isModal = false,
    onSubmitAdditions
}: Props) => {
    const { user } = useAuth();

    const { data: clientData, isLoading: isClientLoading } = useFetchClientQuery({ clientId: clientId! }, { skip: !clientId });
    const client = clientData as NaturalPersonClient;
    const { data: clients } = useFetchClientsQuery(null);
    const { data: userData } = useFetchUserDataQuery({ userId: user?.id! }, { skip: !user });

    const [step, setStep] = useAtom(clientStepAtom);

    const navigate = useNavigate();

    const gridSizes = isMinimal ? { size: { xs: 12, sm: 6, md: 4 } } : { size: { xs: 12, sm: 6, md: 3 } };

    const [createClient] = useCreateClientMutation();
    const [editClient] = useEditClientMutation();

    const companies = clients?.filter((client) => client.person_or_company === 'company');

    const defaultValues: NaturalPersonClient = {
        additional_phone_number: '',
        first_name: '',
        last_name: '',
        person_address: '',
        person_country: 'Lithuania',
        person_email: '',
        person_or_company: 'natural_person',
        person_phone_number: '',
        person_postal_code: '',
        position: 'private_person',
        companies: [],
        person_url: '',
        postal_place: ''
    };

    const {
        handleSubmit,
        watch,
        control,
        reset,
        setValue,
        formState: { errors }
    } = useForm<NaturalPersonClient>({
        resolver: zodResolver(NaturalPersonClientSchema),
        defaultValues
    });

    useEffect(() => {
        if (!userData) return;
        if (userData.user_origin_country.length === 1) setValue('person_country', userData.user_origin_country[0].name);
    }, [userData]);

    const SubmitNaturalPersonClientSchema = NaturalPersonClientSchema.extend({
        companiesIds: z.array(z.number())
    });

    type SubmitNaturalPersonClient = z.infer<typeof SubmitNaturalPersonClientSchema>;

    const onSubmit: SubmitHandler<NaturalPersonClient> = (data) => {
        if (clientId) {
            const requestData = data as SubmitNaturalPersonClient;
            requestData.companiesIds = [];
            requestData.companies?.forEach((company: MinimalClient) => requestData.companiesIds.push(company.id));
            if (onClose) onClose();
            editClient({ client: requestData, clientId }).then(() => toast.success('Successfully edited representative'));
            if (onSubmitAdditions) onSubmitAdditions(clientId);
        } else {
            const requestData = data as SubmitNaturalPersonClient;
            requestData.companiesIds = [];
            requestData.companies?.forEach((company: MinimalClient) => requestData.companiesIds.push(company.id));
            if (onClose) onClose();
            createClient({ client: requestData }).then((data) => {
                if ('data' in data) {
                    const clientId = data.data.clientId;
                    toast.success('Successfully created representative');
                    if (onSubmitAdditions) onSubmitAdditions(data.data.clientId);
                    if (clientId && redirect) navigate(`/client/manage/${clientId}`);
                }
            });
        }
    };

    useEffect(() => {
        if (!clientId || !client || client.person_or_company === 'company') return;
        const clientSkeleton: NaturalPersonClient = {
            ...client
        };
        reset(clientSkeleton);
    }, [client]);

    if (isClientLoading) return <Loader />;

    return (
        <Stack component={'form'} gap={gridSpacing} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={gridSpacing}>
                {!isMinimal && (
                    <Grid size={12}>
                        <Controller
                            name="position"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <FormControl error={!!error}>
                                    <FormLabel>Position</FormLabel>
                                    <RadioGroup row value={value} onChange={(event, value) => onChange(value)}>
                                        <FormControlLabel value="manager" control={<Radio />} label="Manager" />
                                        <FormControlLabel value="sales_manager" control={<Radio />} label="Sales Manager" />
                                        <FormControlLabel value="marketing_manager" control={<Radio />} label="Marketing Manager" />
                                        <FormControlLabel value="director" control={<Radio />} label="Director" />
                                        <FormControlLabel value="owner" control={<Radio />} label="Owner" />
                                        <FormControlLabel value="owner_director" control={<Radio />} label="Owner & Ceo" />
                                        <FormControlLabel value="private_person" control={<Radio />} label="Private Person" />
                                        <FormControlLabel
                                            value="custom"
                                            control={<Radio />}
                                            label={
                                                value === 'custom' ? (
                                                    <Controller
                                                        name="customPosition"
                                                        control={control}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                size="small"
                                                                label="Custom"
                                                                variant="outlined"
                                                                error={!!error}
                                                                helperText={error?.message}
                                                            />
                                                        )}
                                                    />
                                                ) : (
                                                    'Custom'
                                                )
                                            }
                                        />
                                    </RadioGroup>
                                    <FormHelperText>{error?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                )}

                <Grid {...gridSizes}>
                    <Controller
                        control={control}
                        name="first_name"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error?.message}
                                label="First Name"
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="last_name"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Last Name"
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="person_address"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Address"
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="person_country"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <TextField
                                fullWidth
                                value={value}
                                variant="standard"
                                onChange={(event) => {
                                    onChange(event.target.value);
                                }}
                                select
                                label="Country"
                                error={!!error}
                                helperText={error?.message}
                            >
                                {countryList.map((country) => (
                                    <MenuItem key={country.id} value={country.text}>
                                        {country.text}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="person_postal_code"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Postal Code"
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        control={control}
                        name="postal_place"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Postal Place"
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="person_phone_number"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Phone Number"
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="additional_phone_number"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Additional Phone Number"
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="person_email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="E-mail Address"
                                error={!!error}
                                helperText={error?.message}
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid {...gridSizes}>
                    <Controller
                        name="person_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} fullWidth label="URL" error={!!error} helperText={error?.message} variant="standard" />
                        )}
                    />
                </Grid>
                {isCompaniesEnabled && companies && (
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Controller
                            name="companies"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Autocomplete
                                        multiple
                                        value={value}
                                        options={companies}
                                        sx={{ width: '100%' }}
                                        onChange={(event, value) => {
                                            onChange(value);
                                        }}
                                        getOptionLabel={(option) => option.title}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderTags={(options, getTagProps) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                                {options.map((option, index) => (
                                                    <Paper
                                                        sx={{ display: 'flex', gap: 1, alignItems: 'center', py: 0.1, px: 0.5 }}
                                                        elevation={4}
                                                        {...getTagProps({ index: option.id })}
                                                    >
                                                        <Stack>
                                                            <Link
                                                                style={{ textDecoration: 'none', color: 'black' }}
                                                                to={`/client/manage/${option.id}`}
                                                            >
                                                                <Typography variant="caption" fontWeight={600}>
                                                                    {option.title}
                                                                </Typography>
                                                            </Link>
                                                        </Stack>
                                                        <Close
                                                            sx={{ height: 15, width: 15, cursor: 'pointer' }}
                                                            onClick={(event) => getTagProps({ index }).onDelete(event)}
                                                        />
                                                    </Paper>
                                                ))}
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} label="Companies" variant="standard" />}
                                    />
                                    <Box bgcolor={'lightgreen'} sx={{ display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                                        <Tooltip title="Add new company">
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    if (isModal) {
                                                        setStep('companies');
                                                    } else {
                                                        NiceModal.show(ComapanyAddModal);
                                                    }
                                                }}
                                            >
                                                <Add />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <AnimateButton>
                    <Button variant="contained" size="small" type="submit">
                        Save changes
                    </Button>
                </AnimateButton>
            </Box>
        </Stack>
    );
};

export default NaturalPersonForm;
