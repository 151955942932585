import { z } from 'zod';

export const TypeSchema = z.enum(['application', 'audio', 'image', 'text', 'video']);

export type Type = z.infer<typeof TypeSchema>;

export const SubTypeSchema = z.enum([
    'mp3',
    'mp4',
    'wav',
    'ogg',
    'flac',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'svg+xml',
    'plain',
    'html',
    'css',
    'csv',
    'xml',
    'avi',
    'mov',
    'wmv',
    'mkv',
    'pdf',
    'jpg',
    'x-icon',
    'msword',
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'doc',
    'docx',
    'webp',
    'ai',
    'postscript'
]);

export type Subtype = z.infer<typeof SubTypeSchema>;

export const FileSchema = z.object({
    id: z.number(),
    src: z.string(),
    name: z.string(),
    type: z.optional(z.string()),
    subtype: z.optional(z.string()),
    thumbnail: z.string().optional()
});

export type File = z.infer<typeof FileSchema>;
