// LEGACY CODE
import {
    Button,
    Box,
    Grid2 as Grid,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { Controller, useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import Swal from 'sweetalert2';
import { Factory, FactorySchema } from 'types/global.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAddFactoryMutation, useDeleteFactoryMutation, useEditFactoryMutation, useFetchFactoriesQuery } from 'store/api/system.api';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { toast } from 'react-toastify';
import { Stack } from '@mui/system';
import MainCard from 'ui-component/cards/MainCard';
import { getMessage } from 'utils/messages';

const SystemFactoryPage = () => {
    const { control, handleSubmit } = useForm<Factory>({
        resolver: zodResolver(FactorySchema.omit({ id: true })),
        defaultValues: { abbr: undefined, name: undefined }
    });

    const { data: factories } = useFetchFactoriesQuery({});

    const [addFactory] = useAddFactoryMutation();
    const [deleteFactory] = useDeleteFactoryMutation();

    const onSubmit: SubmitHandler<Factory> = (data) => {
        addFactory({
            factory_name: data.name,
            factory_name_abbreviation: data.abbr
        }).then(() => toast.success(getMessage('added')));
    };

    const handleDelete = (factoryId: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                deleteFactory({
                    factory_id: factoryId
                }).then(() => toast.success(getMessage('deleted')));
            }
        });
    };

    return (
        <MainCard
            title="Factories"
            content={false}
            dense
            sx={{ width: '100%' }}
            Header={
                <Grid
                    container
                    spacing={2}
                    component={'form'}
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        mx: 2
                    }}
                >
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label="Factory Name"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="abbr"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    label="Factory Abbreviation"
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Button size="small" variant="contained" fullWidth type="submit">
                            Add New
                        </Button>
                    </Grid>
                </Grid>
            }
            divider
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Factory Name</TableCell>
                            <TableCell>Factory Name Abbreviation</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {factories &&
                            factories.map((row) => {
                                return (
                                    <TableRow
                                        key={row.id}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.abbr}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => NiceModal.show(EditModal, { factory: row })}
                                                    sx={{ width: 80 }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button variant="contained" color="error" onClick={() => handleDelete(row.id)}>
                                                    Delete
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
};

const EditModal = NiceModal.create(({ factory }: { factory: Factory }) => {
    const modal = useModal();

    const { control, handleSubmit } = useForm<Factory>({
        resolver: zodResolver(FactorySchema.omit({ id: true })),
        defaultValues: factory
    });
    const [editFactory] = useEditFactoryMutation();

    const handleEdit: SubmitHandler<Factory> = (data) => {
        editFactory({
            factory_id: factory.id!,
            edited_factory_name: data.name,
            edited_abbreviation_name: data.abbr
        }).then(() => toast.success(getMessage('edited')));
        modal.remove();
    };

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()}>
            <Paper
                component={'form'}
                onSubmit={handleSubmit(handleEdit)}
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    transform: 'translate(-50%, -50%)',
                    p: 2
                }}
            >
                <Stack gap={2}>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                placeholder="Metalform Latvia"
                                label="Factory Name"
                                {...field}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="abbr"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                placeholder="MLF"
                                label="Factory Abbreviation"
                                {...field}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />

                    <Button sx={{ height: 50 }} variant="contained" type="submit">
                        EDIT FACTORY
                    </Button>
                </Stack>
            </Paper>
        </Modal>
    );
});

export default SystemFactoryPage;
