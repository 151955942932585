// noinspection JSUnusedGlobalSymbols

import { nanoid } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Moment } from 'moment';
import { Author, Country, Factory } from '../../types/global.types';
import { Client } from '../../types/mounting.types';
import {
    AllShipping,
    CompiledOrder,
    Employee,
    GalleryFile,
    Order,
    OrderCreate,
    OrderFile,
    OrderFileStatus,
    OrderFileWithAdditionsRoot,
    OrderProduct,
    Product,
    Shipping,
    ShippingManage,
    ShippingManageProject,
    ShippingProject
} from '../../types/orders.types';
import { api } from './api';

export type ExtendedOrderCreate = OrderCreate & {
    user_id: number;
};

export type OrdersGet = {
    authors: Author[];
    employees: Author[];
    countries: Country[];
    factories: Factory[];
    role: string[];
    rows: CompiledOrder[];
};

export type ShippingGet = {
    rows: Shipping[];
    authors: Author[];
    clients: Client[];
    countries: Country[];
    factories: Factory[];
};

export type ShippingGetOrders = {
    rows: Shipping[];
    countries: Country[];
    factories: Factory[];
};

export type ShippingGetAll = {
    rows: AllShipping[];
    authors: Author[];
    clients: Client[];
    countries: Country[];
    factories: Factory[];
};

export type ExtendedShippingManage = ShippingManage & {
    disableProjects?: boolean;
};

export const ordersApi = api.injectEndpoints({
    endpoints: (builder) => ({
        fetchCompiledOrders: builder.query<OrdersGet, { userId: number; orderBy?: string; limit?: number; notifs?: boolean }>({
            query: ({ userId, orderBy, limit, notifs }) => {
                let body = new FormData();
                body.append('user_id', userId.toString());
                if (orderBy) body.append('orderBy', orderBy);
                if (limit) body.append('limit', limit.toString());
                if (notifs) body.append('notifs', notifs.toString());

                return { url: '/v1/order/all_orders', method: 'POST', body: body };
            },
            providesTags: ['Orders', 'OrderShippings']
        }),
        deleteOrders: builder.mutation<null, { ordersIds: number[]; userId: number }>({
            query: ({ ordersIds, userId }) => {
                const requestData = {
                    order_ids: ordersIds,
                    current_user: userId
                };
                return { url: '/v1/order/delete_order_post', method: 'POST', body: requestData };
            },
            invalidatesTags: ['Orders']
        }),
        createOrder: builder.mutation<{ order_title: string; status: string; client: number; order_id: number }, ExtendedOrderCreate>({
            query: (props) => {
                const requestData = {
                    factory_abbr: props.factory?.abbr,
                    user_id: props.user_id,
                    status: props.orderStatus,
                    clientId: props.client.id,
                    shipping_address: props.shippingAddress,
                    custom_address: props.customAddress,
                    on_hold_reason: props.onHoldReason,
                    mounting: props.mounting,
                    order_title: props.orderTitle
                };
                return { url: '/v1/order/initiate_order', method: 'POST', body: requestData };
            },
            invalidatesTags: ['Orders']
        }),
        fetchOrderData: builder.query<Order, { orderId: number; userId: number }>({
            query: ({ orderId, userId }) => {
                const requestData = {
                    order_id: orderId,
                    user_id: userId
                };
                return { url: '/v1/order/get_order_data', method: 'POST', body: requestData };
            },
            providesTags: ['Orders']
        }),
        fetchOrderEmployees: builder.query<Employee[], null>({
            query: () => {
                return {
                    url: '/v1/order/get_order_employees',
                    method: 'GET'
                };
            }
        }),
        saveOrderData: builder.mutation<null, { value: any; name: string; orderId: number; userId: number }>({
            query: ({ name, orderId, value, userId }) => {
                const requestBody = { order_id: orderId, value, name, user_id: userId };
                return {
                    url: '/v1/order/save_order_data',
                    method: 'POST',
                    body: requestBody
                };
            },
            invalidatesTags: ['Orders', 'Notifications']
        }),
        toggleOrderNotifications: builder.mutation<null, { userId: number; orderId: number }>({
            query: ({ orderId, userId }) => {
                const formdata = new FormData();
                formdata.append('post_id', orderId.toString());
                formdata.append('user_id', userId.toString());
                return {
                    url: '/v1/notifications_new/stop_order_notifications',
                    method: 'POST',
                    body: formdata
                };
            },
            invalidatesTags: ['Orders']
        }),
        toggleOrderClientDisplay: builder.mutation<null, { userId: number; orderId: number }>({
            query: ({ orderId, userId }) => {
                const formdata = new FormData();
                formdata.append('post_id', orderId.toString());
                formdata.append('user_id', userId.toString());
                return {
                    url: '/v1/client/display_status',
                    method: 'POST',
                    body: formdata
                };
            },
            invalidatesTags: ['Orders']
        }),
        // Order backups
        createOrderBackup: builder.mutation<null, { userId: number; orderId: number; content: string }>({
            query: ({ orderId, userId, content }) => {
                const formdata = new FormData();
                formdata.append('order_id', orderId.toString());
                formdata.append('user_id', userId.toString());
                formdata.append('content', content);
                return {
                    url: '/v1/order/create_backup',
                    method: 'POST',
                    body: formdata
                };
            },
            invalidatesTags: ['OrderBackup']
        }),
        getOrderBackupData: builder.query<{ order_id: string; timestamp: number }, { orderId: number }>({
            query: ({ orderId }) => {
                const formdata = new FormData();
                formdata.append('order_id', orderId.toString());
                return {
                    url: '/v1/order/last_order_backup_date',
                    method: 'POST',
                    body: formdata
                };
            },
            providesTags: ['OrderBackup']
        }),
        // Products
        fetchOrderProducts: builder.query<OrderProduct, { orderId: number }>({
            providesTags: ['OrderProducts'],
            query: (props) => {
                return { url: '/v1/product/get_order_product_information', method: 'POST', body: props };
            }
        }),
        saveOrderProducts: builder.mutation<null, { products: Product[]; orderId: number; userId: number }>({
            invalidatesTags: ['OrderProducts'],
            query: (props) => {
                console.log({ props });
                const products = cloneDeep(props.products);
                products.forEach((product) => {
                    product.uniqueId = product.uniqueId || nanoid();
                    product.inputs.forEach((input) => {
                        input.uniqueId = input.uniqueId || nanoid();
                    });
                });

                const formdata = new FormData();
                formdata.append('orderId', props.orderId.toString());
                formdata.append('userId', props.userId.toString());

                products.forEach((product) => {
                    product.inputs.forEach((input, index) => {
                        // Adapt data to save old images or change to new if uploaded
                        if (product.inputs[index].inputType === 'file') {
                            if (input.inputFile instanceof FileList && input.inputFile.length > 0 && product.inputs[index].uniqueId) {
                                // Upload new image
                                formdata.append(product.inputs[index].uniqueId!, input.inputFile[0]);
                            } else {
                                // Leave old one
                                product.inputs[index].inputValue = product.inputs[index].inputFile?.file_id!;
                            }
                        }

                        if (!product.inputs[index].inputValue) {
                            product.inputs[index].inputValue = '';
                        }
                        delete input.inputFile;
                    });
                });

                formdata.append('products', JSON.stringify(products));
                return { url: '/v1/product/save_order_product', method: 'POST', body: formdata };
            }
        }),
        saveOrderProductsDrawingNeeded: builder.mutation<
            null,
            { isDrawingEnabled: boolean; drawingDate: string; orderId: number; userId: number }
        >({
            invalidatesTags: ['OrderProducts', 'Notifications'],
            query: (props) => {
                const formdata = new FormData();
                formdata.append('orderId', props.orderId.toString());
                formdata.append('userId', props.userId.toString());
                formdata.append('isDrawingEnabled', props.isDrawingEnabled.toString());
                if (props.isDrawingEnabled && props.drawingDate !== null) {
                    formdata.append('drawingDate', props.drawingDate);
                }
                return { url: '/v1/product/save_drawing_needed', method: 'POST', body: formdata };
            }
        }),
        deleteOrderProduct: builder.mutation<null, { productId: string; orderId: number }>({
            invalidatesTags: ['OrderProducts'],
            query: (props) => {
                return { url: '/v1/product/delete_order_product', method: 'POST', body: props };
            }
        }),
        // Shipping
        fetchShippingInformation: builder.query<
            {
                shipping_id: number;
                number: string;
                region: string;
                shipment_date: string;
                shipping_country: string;
                cmr: string;
                factory: Factory;
                delivery_date: string;
                deleted: boolean;
                deleted_by: boolean;
                status: { value: string; label: string };
                projects: ShippingManageProject[];
                all_projects: {
                    id: number;
                    orderNum: string;
                    orderTitle: string;
                    deliveryAddress: string;
                    clientId: number;
                }[];
                clients: Client[];
            },
            { shippingId?: number; orderId?: number }
        >({
            query: ({ shippingId, orderId }) => {
                const requestBody = { shipping_id: shippingId, order_id: orderId };
                return { url: '/v1/shipping_list/get_shipping_information', method: 'POST', body: requestBody };
            },
            providesTags: ['OrderShippings']
        }),
        checkShippingEvents: builder.query<string, { orderId: number; selectedDate: Moment }>({
            query: ({ orderId, selectedDate }) => {
                const requestBody = { post_id: orderId, selected_date: selectedDate.format('YYYYMMDD') };
                return {
                    url: '/v1/shipping_list/check_events',
                    method: 'POST',
                    body: requestBody
                };
            }
        }),
        checkShippingSuitable: builder.query<
            {
                selDate: number;
                postId: number;
                shipNum: number;
                shippingID: number;
                new?: boolean;
                transfer?: boolean;
                changeDate?: boolean;
                region: string;
                country: string;
            },
            { orderId: number; selectedDate: Moment }
        >({
            query: ({ orderId, selectedDate }) => {
                const requestBody = { post_id: orderId, selected_date: selectedDate.format('YYYYMMDD') };
                return {
                    url: '/v1/shipping_list/check_for_suitable',
                    method: 'POST',
                    body: requestBody
                };
            }
        }),
        fetchShippingOrders: builder.query<ShippingGetOrders, { userId: number; limited?: boolean }>({
            query: ({ userId, limited }) => {
                const formData = new FormData();

                formData.append('current_user_id', userId.toString());
                if (limited) formData.append('limited', 'true');

                return {
                    url: '/v1/shipping_list/all_shipping_orders',
                    method: 'POST',
                    body: formData
                };
            },
            providesTags: ['OrderShippings']
        }),
        fetchAllShippingsOfOrder: builder.query<ShippingGetAll, { orderId: number; userId: number }>({
            query: ({ orderId, userId }) => {
                const requestBody = {
                    order_id: orderId,
                    user_id: userId
                };
                return { url: '/v1/shipping_list/get_all_shippings_of_order', method: 'POST', body: requestBody };
            },
            providesTags: ['OrderShippings']
        }),
        fetchShippingProjectsOrders: builder.query<{ projects: ShippingProject[] }, { shippingId: number; addedProjects: any }>({
            query: ({ shippingId, addedProjects }) => {
                const requestBody = { addedProjects, shippingID: shippingId };
                return {
                    url: '/v1/shipping_list/all_shipping_orders_projects',
                    method: 'POST',
                    body: requestBody
                };
            }
        }),
        createAndAssignShipping: builder.mutation<
            { success: boolean; ship_id: number; shipNum: string; shipText: string; shipLink: string },
            { orderId: number; userId: number; selectedDate: Moment; region: string; country: string; isPartial: boolean }
        >({
            query: ({ country, isPartial, orderId, region, selectedDate, userId }) => {
                const requestBody = {
                    order_id: orderId,
                    selected_date: selectedDate.format('YYYYMMDD'),
                    region: region,
                    country: country,
                    is_partial: isPartial,
                    current_user_id: userId,
                    user_id: userId
                };
                return {
                    url: '/v1/shipping_list/create_and_assign',
                    method: 'POST',
                    body: requestBody
                };
            },
            invalidatesTags: ['OrderShippings']
        }),
        createShippingNotification: builder.mutation<null, { userId: number; shippingId: number; orderId: number; selectedDate: Moment }>({
            query: ({ orderId, selectedDate, userId, shippingId }) => {
                const requestBody = {
                    ship_id: shippingId,
                    order_id: orderId,
                    user_id: userId,
                    selected_date: selectedDate.format('YYYYMMDD')
                };
                return {
                    url: '/v1/shipping_list/add_shipping_notifications',
                    method: 'POST',
                    body: requestBody
                };
            }
        }),
        changeCurrentShippingDate: builder.mutation<
            null,
            { userId: number; orderId: number; selectedDate: Moment; shippingId: number; reason?: string }
        >({
            query: ({ selectedDate, userId, orderId, shippingId, reason }) => {
                const requestBody = {
                    current_user_id: userId,
                    order_id: orderId,
                    selected_date: selectedDate.format('YYYYMMDD'),
                    ship_id: shippingId,
                    reason: reason
                };
                return { url: '/v1/shipping_list/change_current_date', body: requestBody, method: 'POST' };
            },
            invalidatesTags: ['OrderShippings']
        }),
        changeOrderShippingDate: builder.mutation<null, { oldShippingId: number; orderId: number; selectedDate: Moment; userId: number }>({
            query: ({ oldShippingId, orderId, selectedDate, userId }) => {
                const requestBody = {
                    user_id: userId,
                    order_id: orderId,
                    selected_date: selectedDate.format('YYYYMMDD'),
                    old_shipping_id: oldShippingId
                };
                return { url: '/v1/shipping_list/change_order_shipping_date', body: requestBody, method: 'POST' };
            },
            invalidatesTags: ['OrderShippings']
        }),
        changeOldShippingStatus: builder.mutation<null, { oldShippingId: number }>({
            query: ({ oldShippingId }) => {
                const requestBody = {
                    old_shipping_id: oldShippingId
                };
                return { url: '/v1/shipping_list/change_old_shipping_status', body: requestBody, method: 'POST' };
            }
        }),
        deleteOrderShippings: builder.mutation<null, { shippingIds: number[]; userId: number }>({
            query: ({ shippingIds, userId }) => {
                const requestBody = {
                    current_user: userId,
                    shipping_ids: shippingIds
                };
                return { url: '/v1/shipping_list/delete_shipping_order', body: requestBody, method: 'POST' };
            },
            invalidatesTags: ['OrderShippings']
        }),
        updateShipping: builder.mutation<null, ExtendedShippingManage>({
            query: (data) => {
                return { url: '/v1/shipping_list/update_shipping', body: data, method: 'POST' };
            },
            invalidatesTags: ['OrderShippings']
        }),
        changeShippingReason: builder.mutation<null, { orderId: number; reason: string }>({
            query: ({ orderId, reason }) => {
                const requestBody = {
                    order_id: orderId,
                    reason
                };
                return {
                    url: '/v1/shipping_list/change_reason',
                    method: 'POST',
                    body: requestBody
                };
            },
            invalidatesTags: ['OrderShippings']
        }),
        addPartialShipping: builder.mutation<
            null,
            {
                orderId: number;
                reason: string;
                selectedDate: Moment;
                shippingId: number;
                shippmentContents: string;
                status: string;
                userId: number;
            }
        >({
            query: ({ orderId, reason, selectedDate, status, shippmentContents, shippingId, userId }) => {
                const requestBody = {
                    order_id: orderId,
                    reason,
                    selected_date: selectedDate,
                    ship_id: shippingId,
                    shipment_contents: shippmentContents,
                    status,
                    user_id: userId
                };
                return { url: '/v1/shipping_list/add_partial_shipping', method: 'POST', body: requestBody };
            },
            invalidatesTags: ['Orders']
        }),
        assignOrderToFoundShipping: builder.mutation<
            null,
            {
                shipId: number;
                orderId: number;
                userId: number;
                isPartial: boolean;
                selectedDate: Moment;
            }
        >({
            query: ({ isPartial, orderId, selectedDate, shipId, userId }) => {
                const requestBody = {
                    ship_id: shipId,
                    order_id: orderId,
                    user_id: userId,
                    is_partial: isPartial,
                    selected_date: selectedDate.format('YYYYMMDD')
                };
                return { url: '/v1/shipping_list/assign_order', method: 'POST', body: requestBody };
            },
            invalidatesTags: ['OrderShippings']
        }),
        //  Additions
        createExtraOrder: builder.mutation<{ order_id: number }, { orderId: number; userId: number }>({
            query: ({ orderId, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: 'v1/order/create_extra_order', method: 'POST', body: formData };
            },
            invalidatesTags: ['Orders']
        }),
        createFixOrder: builder.mutation<{ order_id: number }, { orderId: number; userId: number }>({
            query: ({ orderId, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: 'v1/order/create_fix_order', method: 'POST', body: formData };
            },
            invalidatesTags: ['Orders']
        }),
        createRelatedOrder: builder.mutation<{ order_id: number }, { orderId: number; userId: number }>({
            query: ({ orderId, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: 'v1/order/create_related_order', method: 'POST', body: formData };
            },
            invalidatesTags: ['Orders']
        }),
        createLocalFixOrder: builder.mutation<{ order_id: number }, { orderId: number; userId: number }>({
            query: ({ orderId, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: 'v1/order/create_local_fix_order', method: 'POST', body: formData };
            },
            invalidatesTags: ['Orders']
        }),
        fetchRelatedOrders: builder.query<OrdersGet, { orderId: number; userId: number; userRole: string }>({
            query: ({ orderId, userId, userRole }) => {
                const requestBody = {
                    order_id: orderId,
                    user_id: userId,
                    user_role: userRole
                };

                return { url: '/v1/orders/get_all_related_orders_of_order', method: 'POST', body: requestBody };
            },
            providesTags: ['Orders']
        }),
        // Files
        //      Factory
        fetchOrderFactoryFiles: builder.query<OrderFileWithAdditionsRoot[], { orderId: number; userId: number; userRole: string }>({
            query: ({ orderId, userId, userRole }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('user_roles', JSON.stringify([userRole]));

                return { url: '/v1/order/get_order_factory_files', method: 'POST', body: formData };
            },
            providesTags: ['OrderFactoryFiles']
        }),
        orderFactoryFileChangeStatus: builder.mutation<
            any,
            { orderId: number; fileId: number; userId: number; status: OrderFileStatus; uploadedFiles?: File[]; comment?: string }
        >({
            query: ({ comment, orderId, fileId, status, uploadedFiles, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('user_id', userId.toString());
                formData.append('status', status);
                formData.append('last_drawing', JSON.stringify(false));
                comment && formData.append('comment', comment);
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/factory/change_status', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFactoryFiles', 'Orders', 'Notifications']
        }),
        orderClientFactoryFileChangeStatus: builder.mutation<
            unknown,
            { orderId: number; userId: number; fileId: number; newStatus: boolean }
        >({
            query: ({ orderId, userId, fileId, newStatus }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('new_status', JSON.stringify(newStatus));

                return { url: '/v1/order/client_approve', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFactoryFiles', 'Orders', 'Notifications']
        }),
        deleteOrderFactoryFile: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ fileId, orderId, userId }) => {
                const formData = new FormData();
                formData.append('id', fileId.toString());
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: '/v1/factory/delete_factory_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFactoryFiles', 'Orders', 'Notifications']
        }),
        improveOrderFactoryFile: builder.mutation<any, { orderId: number; fileId: number; userId: number; uploadedFiles: File[] }>({
            query: ({ fileId, uploadedFiles, orderId, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('user_id', userId.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/factory/upload_improvement_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFactoryFiles', 'Orders', 'Notifications']
        }),
        uploadOrderFactoryFile: builder.mutation<any, { orderId: number; userId: number; uploadedFiles: File[] }>({
            query: ({ orderId, uploadedFiles, userId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: 'v1/order/upload_factory_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFactoryFiles', 'Orders', 'Notifications']
        }),
        changeFactoryFileSentToClientStatus: builder.mutation<any, { orderId: number; fileId: number; newStatus: boolean; userId: number }>(
            {
                query: ({ orderId, userId, fileId, newStatus }) => {
                    const formData = new FormData();
                    formData.append('file_id', fileId.toString());
                    formData.append('new_status', newStatus.toString());
                    formData.append('order_id', orderId.toString());
                    formData.append('user_id', userId.toString());

                    return {
                        url: '/v1/factory/sent_to_client_change',
                        body: formData,
                        method: 'POST'
                    };
                },
                invalidatesTags: ['OrderFactoryFiles', 'Orders', 'Notifications']
            }
        ),
        downloadAllFactoryFiles: builder.query<{ file: string; status: string }, { orderId: number }>({
            query: ({ orderId }) => {
                return {
                    url: '/v1/factory/download_all',
                    method: 'POST',
                    body: { postID: orderId }
                };
            }
        }),
        resetOrderFactoryFiles: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ orderId, fileId, userId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('file_id', fileId.toString());
                return {
                    url: '/v1/order/reset_factory_file',
                    method: 'POST',
                    body: formData
                };
            },
            invalidatesTags: ['OrderFactoryFiles', 'Orders', 'Notifications']
        }),
        changeFactoryFilesResolveDeadline: builder.mutation<
            any,
            { fileId: number; deadlineDate: number; comment: string; userId: number; orderId: number }
        >({
            query: ({ fileId, deadlineDate, comment, userId, orderId }) => {
                const formData = new FormData();
                formData.append('file_id', fileId.toString());
                formData.append('user_id', userId.toString());
                formData.append('order_id', orderId.toString());
                formData.append('deadline_date', deadlineDate.toString());
                formData.append('comment', comment);
                return {
                    url: '/v1/order/change_factory_files_resolve_deadline',
                    method: 'POST',
                    body: formData
                };
            },
            invalidatesTags: ['OrderFactoryFiles', 'Notifications']
        }),
        //      External designer
        fetchOrderExternalDesignerFiles: builder.query<OrderFileWithAdditionsRoot[], { orderId: number; userId: number; userRole: string }>(
            {
                query: ({ orderId, userId, userRole }) => {
                    const formData = new FormData();
                    formData.append('order_id', orderId.toString());
                    formData.append('user_id', userId.toString());
                    formData.append('user_roles', JSON.stringify([userRole]));

                    return { url: '/v1/order/get_order_external_files', method: 'POST', body: formData };
                },
                providesTags: ['OrderExternalDesignerFiles']
            }
        ),
        orderExternalDesignerFileChangeStatus: builder.mutation<
            any,
            { orderId: number; fileId: number; userId: number; status: OrderFileStatus; uploadedFiles?: File[]; comment?: string }
        >({
            query: ({ comment, orderId, fileId, status, uploadedFiles, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('user_id', userId.toString());
                formData.append('status', status);
                formData.append('last_drawing', JSON.stringify(false));
                comment && formData.append('comment', comment);
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/external_designers/change_status', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderExternalDesignerFiles']
        }),
        orderClientExternalDesignerFileChangeStatus: builder.mutation<
            unknown,
            { orderId: number; userId: number; fileId: number; newStatus: boolean }
        >({
            query: ({ orderId, userId, fileId, newStatus }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('new_status', JSON.stringify(newStatus));

                return { url: '/v1/order/client_approve', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderExternalDesignerFiles']
        }),
        deleteOrderExternalDesignerFile: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ fileId, orderId, userId }) => {
                const formData = new FormData();
                formData.append('id', fileId.toString());
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: '/v1/external_designers/delete_external_designers_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderExternalDesignerFiles']
        }),
        improveOrderExternalDesignerFile: builder.mutation<any, { orderId: number; fileId: number; userId: number; uploadedFiles: File[] }>(
            {
                query: ({ fileId, uploadedFiles, orderId, userId }) => {
                    const formData = new FormData();
                    formData.append('post_id', orderId.toString());
                    formData.append('file_id', fileId.toString());
                    formData.append('user_id', userId.toString());
                    if (uploadedFiles)
                        for (let i = 0; i < uploadedFiles.length; i++) {
                            formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                        }
                    return { url: '/v1/external_designers/upload_improvement_file', method: 'POST', body: formData };
                },
                invalidatesTags: ['OrderExternalDesignerFiles']
            }
        ),
        uploadOrderExternalDesignerFile: builder.mutation<any, { orderId: number; userId: number; uploadedFiles: File[] }>({
            query: ({ orderId, uploadedFiles, userId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/order/upload_external_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderExternalDesignerFiles']
        }),
        changeExternalDesignerFileSentToClientStatus: builder.mutation<
            any,
            { orderId: number; fileId: number; newStatus: boolean; userId: number }
        >({
            query: ({ orderId, userId, fileId, newStatus }) => {
                const formData = new FormData();
                formData.append('file_id', fileId.toString());
                formData.append('new_status', newStatus.toString());
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());

                return {
                    url: '/v1/external_designers/sent_to_client_change',
                    body: formData,
                    method: 'POST'
                };
            },
            invalidatesTags: ['OrderExternalDesignerFiles']
        }),
        downloadAllExternalDesignerFiles: builder.query<{ file: string; status: string }, { orderId: number }>({
            query: ({ orderId }) => {
                return {
                    url: '/v1/external_designers/download_all',
                    method: 'POST',
                    body: { postID: orderId }
                };
            }
        }),
        resetExternalDesignerFiles: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ orderId, fileId, userId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('file_id', fileId.toString());
                return {
                    url: '/v1/order/reset_external_designer_file',
                    method: 'POST',
                    body: formData
                };
            },
            invalidatesTags: ['OrderExternalDesignerFiles', 'Orders']
        }),
        //      Fabrication drawings
        fetchOrderFabricationFiles: builder.query<OrderFileWithAdditionsRoot[], { orderId: number; userId: number; userRole: string }>({
            query: ({ orderId, userId, userRole }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('user_roles', JSON.stringify([userRole]));

                return { url: '/v1/order/get_order_fabrication_files', method: 'POST', body: formData };
            },
            providesTags: ['OrderFabricationFiles']
        }),
        orderFabricationFileChangeStatus: builder.mutation<
            any,
            { orderId: number; fileId: number; userId: number; status: OrderFileStatus; uploadedFiles?: File[]; comment?: string }
        >({
            query: ({ comment, orderId, fileId, status, uploadedFiles, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('user_id', userId.toString());
                formData.append('status', status);
                formData.append('last_drawing', JSON.stringify(false));
                comment && formData.append('comment', comment);
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/fabrication_drawings/change_status', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFabricationFiles']
        }),
        orderClientFabricationFileChangeStatus: builder.mutation<
            unknown,
            { orderId: number; userId: number; fileId: number; newStatus: boolean }
        >({
            query: ({ orderId, userId, fileId, newStatus }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('new_status', JSON.stringify(newStatus));

                return { url: '/v1/order/client_approve', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFabricationFiles']
        }),
        deleteOrderFabricationFile: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ fileId, orderId, userId }) => {
                const formData = new FormData();
                formData.append('id', fileId.toString());
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: '/v1/fabrication_drawings/delete_fabrication_drawings_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFabricationFiles']
        }),
        improveOrderFabricationFile: builder.mutation<any, { orderId: number; fileId: number; userId: number; uploadedFiles: File[] }>({
            query: ({ fileId, uploadedFiles, orderId, userId }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('file_id', fileId.toString());
                formData.append('user_id', userId.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/fabrication_drawings/upload_improvement_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFabricationFiles']
        }),
        uploadOrderFabricationFile: builder.mutation<any, { orderId: number; userId: number; uploadedFiles: File[] }>({
            query: ({ orderId, uploadedFiles, userId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: 'v1/order/upload_fabrication_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderFabricationFiles']
        }),
        changeFabricationFileSentToClientStatus: builder.mutation<
            any,
            { orderId: number; fileId: number; newStatus: boolean; userId: number }
        >({
            query: ({ orderId, userId, fileId, newStatus }) => {
                const formData = new FormData();
                formData.append('file_id', fileId.toString());
                formData.append('new_status', newStatus.toString());
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());

                return {
                    url: '/v1/fabrication_drawings/sent_to_client_change',
                    body: formData,
                    method: 'POST'
                };
            },
            invalidatesTags: ['OrderFabricationFiles']
        }),
        downloadAllFabricationFiles: builder.query<{ file: string; status: string }, { orderId: number }>({
            query: ({ orderId }) => {
                return {
                    url: '/v1/fabrication_drawings/download_all',
                    method: 'POST',
                    body: { postID: orderId }
                };
            }
        }),
        resetFabricationDrawingFiles: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ orderId, fileId, userId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                formData.append('file_id', fileId.toString());
                return {
                    url: '/v1/order/reset_fabrication_drawings_file',
                    method: 'POST',
                    body: formData
                };
            },
            invalidatesTags: ['OrderFabricationFiles', 'Orders']
        }),
        //      Survey
        fetchOrderFilesFromSurvey: builder.query<OrderFile[], { orderId: number }>({
            query: ({ orderId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                return { url: '/v1/order/get_order_files', method: 'POST', body: formData };
            },
            providesTags: ['OrderSurveyFiles']
        }),
        uploadOrderFilesFromSurvey: builder.mutation<any, { orderId: number; userId: number; uploadedFiles: File[] }>({
            query: ({ orderId, userId, uploadedFiles }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/order/upload_order_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderSurveyFiles']
        }),
        deleteOrderFilesSurvey: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ orderId, userId, fileId }) => {
                const formData = new FormData();
                formData.append('id', fileId.toString());
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: '/v1/order/delete_order_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderSurveyFiles']
        }),
        downloadAllSurveyFiles: builder.query<{ file: string; status: string }, { orderId: number }>({
            query: ({ orderId }) => {
                return {
                    url: '/v1/order/download_all',
                    method: 'POST',
                    body: { postID: orderId }
                };
            }
        }),
        //      3D Files
        fetchOrder3dFiles: builder.query<OrderFile[], { orderId: number }>({
            query: ({ orderId }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                return { url: '/v1/order/get_dwg_files', method: 'POST', body: formData };
            },
            providesTags: ['Order3dFiles']
        }),
        uploadOrder3dFiles: builder.mutation<any, { orderId: number; userId: number; uploadedFiles: File[] }>({
            query: ({ orderId, userId, uploadedFiles }) => {
                const formData = new FormData();
                formData.append('order_id', orderId.toString());
                formData.append('user_id', userId.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }
                return { url: '/v1/order/upload_dwg_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['Order3dFiles']
        }),
        deleteOrder3dFiles: builder.mutation<any, { orderId: number; userId: number; fileId: number }>({
            query: ({ orderId, fileId, userId }) => {
                const formData = new FormData();
                formData.append('id', fileId.toString());
                formData.append('post_id', orderId.toString());
                formData.append('user_id', userId.toString());
                return { url: '/v1/dwg_files/delete_file', method: 'POST', body: formData };
            },
            invalidatesTags: ['Order3dFiles']
        }),
        downloadAll3dFiles: builder.query<{ file: string; status: string }, { orderId: number }>({
            query: ({ orderId }) => {
                return {
                    url: '/v1/dwg_files/download_all',
                    method: 'POST',
                    body: { postID: orderId }
                };
            }
        }),
        //      Gallery
        fetchGallery: builder.query<
            { categories: string[]; images: GalleryFile[]; search: string },
            { categories: string[]; userId: number; search: string; limit?: number }
        >({
            query: ({ categories, userId, search, limit }) => {
                const formData = new FormData();
                formData.append('categories', JSON.stringify(categories));
                formData.append('search', search);
                if (limit) formData.append('limit', limit.toString());
                formData.append('userId', userId.toString());

                return { url: '/v1/gallery', method: 'POST', body: formData };
            },
            providesTags: ['OrderGallery'],
            keepUnusedDataFor: 0
        }),
        downloadSelectedGalleryImages: builder.mutation<string | null, { userId: number; imagesIds: number[] }>({
            query: ({ userId, imagesIds }) => {
                const formData = new FormData();
                formData.append('images', JSON.stringify(imagesIds));
                formData.append('userId', userId.toString());
                return { url: '/v1/download_images', method: 'POST', body: formData };
            }
        }),
        fetchOrderGallery: builder.query<
            { categories: string[]; images: GalleryFile[]; search: string },
            { orderId: number; categories: string[]; search: string }
        >({
            query: ({ orderId, categories, search }) => {
                const formData = new FormData();
                formData.append('orderId', orderId.toString());
                formData.append('search', search);
                formData.append('categories', JSON.stringify(categories));

                return { url: '/v1/order/gallery', method: 'POST', body: formData };
            },
            providesTags: ['OrderGallery'],
            keepUnusedDataFor: 0
        }),
        deleteOrderGalleryImage: builder.mutation<any, { imageIds: number[] }>({
            query: ({ imageIds }) => {
                const formData = new FormData();
                formData.append('imageIds', JSON.stringify(imageIds));
                return { url: '/v1/order/delete_image', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderGallery']
        }),
        uploadOrderGalleryImage: builder.mutation<
            any,
            { orderId: number; uploadedFiles: FileList; categories: string[]; description: string }
        >({
            query: ({ orderId, uploadedFiles, categories, description }) => {
                const formData = new FormData();
                formData.append('orderId', orderId.toString());
                formData.append('categories', JSON.stringify(categories));
                formData.append('imageDescription', description.toString());
                if (uploadedFiles)
                    for (let i = 0; i < uploadedFiles.length; i++) {
                        formData.append(`uploaded_files${i}`, uploadedFiles[i]);
                    }

                return { url: '/v1/order/upload_gallery', method: 'POST', body: formData };
            },
            invalidatesTags: ['OrderGallery']
        }),
        editOrderGalleryImage: builder.mutation<any, { categories: string[] | null; imageIds: number[]; description: string | null }>({
            query: ({ categories, imageIds, description }) => {
                const formData = new FormData();
                formData.append('imageIds', JSON.stringify(imageIds));
                if (description !== null) formData.append('imageDescription', description.toString());
                if (categories) formData.append('categories', JSON.stringify(categories));

                return { url: '/v1/order/update_tags', method: 'POST', body: formData };
            },
            invalidatesTags: []
        }),
        fetchGalleryImageTags: builder.query<{ categories: string[]; imageDescription: null | string }, { imageId: number }>({
            query: ({ imageId }) => {
                const formData = new FormData();
                formData.append('imageId', imageId.toString());
                return { url: '/v1/order/get_image_tags', method: 'POST', body: formData };
            },
            providesTags: ['OrderGallery']
        })
    })
});
export const {
    useFetchCompiledOrdersQuery,
    useDeleteOrdersMutation,
    useCreateOrderMutation,
    useFetchOrderEmployeesQuery,
    useSaveOrderDataMutation,
    useDeleteOrderProductMutation,
    useFetchOrderProductsQuery,
    useSaveOrderProductsMutation,
    useSaveOrderProductsDrawingNeededMutation,
    useLazyCheckShippingEventsQuery,
    useLazyCheckShippingSuitableQuery,
    useCreateAndAssignShippingMutation,
    useCreateShippingNotificationMutation,
    useChangeCurrentShippingDateMutation,
    useFetchOrderFactoryFilesQuery,
    useDeleteOrderFactoryFileMutation,
    useImproveOrderFactoryFileMutation,
    useOrderFactoryFileChangeStatusMutation,
    useChangeFactoryFilesResolveDeadlineMutation,
    useFetchOrderDataQuery,
    useLazyFetchOrderDataQuery,
    useChangeFactoryFileSentToClientStatusMutation,
    useUploadOrderFactoryFileMutation,
    useFetchOrderExternalDesignerFilesQuery,
    useOrderExternalDesignerFileChangeStatusMutation,
    useDeleteOrderExternalDesignerFileMutation,
    useImproveOrderExternalDesignerFileMutation,
    useUploadOrderExternalDesignerFileMutation,
    useChangeExternalDesignerFileSentToClientStatusMutation,
    useChangeFabricationFileSentToClientStatusMutation,
    useOrderFabricationFileChangeStatusMutation,
    useDeleteOrderFabricationFileMutation,
    useFetchOrderFabricationFilesQuery,
    useImproveOrderFabricationFileMutation,
    useUploadOrderFabricationFileMutation,
    useCreateExtraOrderMutation,
    useCreateFixOrderMutation,
    useCreateLocalFixOrderMutation,
    useCreateRelatedOrderMutation,
    useFetchRelatedOrdersQuery,
    useFetchOrder3dFilesQuery,
    useFetchOrderFilesFromSurveyQuery,
    useUploadOrder3dFilesMutation,
    useUploadOrderFilesFromSurveyMutation,
    useDeleteOrder3dFilesMutation,
    useDeleteOrderFilesSurveyMutation,
    useDeleteOrderShippingsMutation,
    useFetchShippingOrdersQuery,
    useLazyFetchShippingProjectsOrdersQuery,
    useFetchAllShippingsOfOrderQuery,
    useFetchShippingInformationQuery,
    useChangeOldShippingStatusMutation,
    useChangeOrderShippingDateMutation,
    useUpdateShippingMutation,
    useChangeShippingReasonMutation,
    useAddPartialShippingMutation,
    useAssignOrderToFoundShippingMutation,
    useFetchGalleryQuery,
    useLazyFetchGalleryQuery,
    useFetchOrderGalleryQuery,
    useDeleteOrderGalleryImageMutation,
    useUploadOrderGalleryImageMutation,
    useEditOrderGalleryImageMutation,
    useFetchGalleryImageTagsQuery,
    useDownloadSelectedGalleryImagesMutation,
    useToggleOrderNotificationsMutation,
    useOrderClientFactoryFileChangeStatusMutation,
    useOrderClientFabricationFileChangeStatusMutation,
    useOrderClientExternalDesignerFileChangeStatusMutation,
    useLazyDownloadAllFactoryFilesQuery,
    useLazyDownloadAll3dFilesQuery,
    useLazyDownloadAllSurveyFilesQuery,
    useLazyDownloadAllFabricationFilesQuery,
    useLazyDownloadAllExternalDesignerFilesQuery,
    useToggleOrderClientDisplayMutation,
    useResetOrderFactoryFilesMutation,
    useResetExternalDesignerFilesMutation,
    useResetFabricationDrawingFilesMutation,
    useCreateOrderBackupMutation,
    useGetOrderBackupDataQuery
} = ordersApi;
