import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mui/material';
import ModalFormCard from 'ui-component/cards/ModalCard';
import NaturalPersonForm from 'views/forms/clients/NaturalPersonForm';

const NaturalPersonAddModal = NiceModal.create(() => {
    const modal = useModal();

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()} sx={{ overflowY: 'scroll' }}>
            <ModalFormCard title="Person">
                <NaturalPersonForm isCompaniesEnabled={false} />
            </ModalFormCard>
        </Modal>
    );
});

export default NaturalPersonAddModal;
