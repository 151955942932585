import { Notification, NotificationStatus } from 'types/notifications.types';
import { api } from './api';

const notificationsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        fetchUserNotifications: builder.query<
            { notifs: Notification[]; users: { id: number; name: string }[] },
            { userId: number; status: NotificationStatus }
        >({
            query: ({ userId, status }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                formData.append('status', status === 'read' ? '1' : '0');
                return { url: '/v1/notifications_new/get_user_notifications', method: 'POST', body: formData };
            },
            keepUnusedDataFor: 5,
            providesTags: ['Notifications']
        }),
        fetchUserOrderNotifications: builder.query<
            { notifs: Notification[]; users: { id: number; name: string }[] },
            { userId: number; status: NotificationStatus; orderId: number }
        >({
            query: ({ userId, status, orderId }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                formData.append('order_id', orderId.toString());
                formData.append('status', status === 'read' ? '1' : '0');
                return { url: '/v1/notifications_new/get_user_notifications_order', method: 'POST', body: formData };
            },
            keepUnusedDataFor: 5,
            providesTags: ['Notifications']
        }),
        fetchUnreadUserNotifications: builder.query<{ notifs: Notification[] }, { userId: number }>({
            query: ({ userId }) => {
                // const formData = new FormData();
                // formData.append('user_id', userId.toString());
                const requestBody = { user_id: userId };
                return { url: '/v1/users/get_unread_notifications_for_user', method: 'POST', body: requestBody };
            },
            providesTags: ['Notifications']
        }),
        fetchArchiveNotifications: builder.query<null, { userId: number }>({
            query: ({ userId }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                return {
                    url: '/v1/notifications/load_more_archive',
                    method: 'POST',
                    body: formData
                };
            },
            providesTags: ['Notifications']
        }),
        changeNotificationStatus: builder.mutation<
            { newStatus: boolean; changeId: number; notifId?: number; wholeRead?: boolean },
            { userId: number; changeId: number }
        >({
            query: ({ changeId, userId }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                formData.append('change_id', changeId.toString());
                return { url: '/v1/notifications_new/change_notification_status', method: 'POST', body: formData };
            },
            invalidatesTags: ['Notifications']
        }),
        changeUserNotificationStatus: builder.mutation<{ wholeRead?: boolean }, { userId: number; changeId: number; multi: boolean }>({
            query: ({ changeId, userId }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                formData.append('change_id', changeId.toString());
                return { url: '/v1/notifications/change_change_user_status', method: 'POST', body: formData };
            },
            invalidatesTags: ['Notifications']
        }),
        changeAllUserNotificationStatus: builder.mutation<null, { userId: number; changeIds: number[]; status: boolean }>({
            query: ({ userId, changeIds, status }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                formData.append('change_ids', JSON.stringify(changeIds));
                formData.append('status', JSON.stringify(status));
                return {
                    url: '/v1/notifications_new/change_all_notifications_status',
                    method: 'POST',
                    body: formData
                };
            },
            invalidatesTags: ['Notifications']
        }),
        fetchUnreadNotificationCount: builder.query<null, { userId: number }>({
            query: ({ userId }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                return {
                    url: '/v1/notifications/get_unread_notif_num_for_user',
                    body: formData,
                    method: 'POST'
                };
            },
            providesTags: ['Notifications']
        }),
        toggleUserEmailNotifications: builder.mutation<null, { userId: number }>({
            query: ({ userId }) => {
                const formData = new FormData();
                formData.append('user_id', userId.toString());
                return { url: '/v1/enotifications_new/stop_email_notifications', body: formData, method: 'POST' };
            },
            invalidatesTags: ['Users']
        })
    })
});

export const {
    useFetchUserNotificationsQuery,
    useLazyFetchUserNotificationsQuery,
    useLazyFetchUnreadUserNotificationsQuery,
    useFetchArchiveNotificationsQuery,
    useChangeNotificationStatusMutation,
    useFetchUserOrderNotificationsQuery,
    useChangeAllUserNotificationStatusMutation,
    useFetchUnreadNotificationCountQuery,
    useToggleUserEmailNotificationsMutation
} = notificationsApi;
