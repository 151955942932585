import { Box, BoxProps } from '@mui/system';
import useAppSelector from 'hooks/useAppSelector';
import useLightbox from 'hooks/useLightbox';
import React, { useEffect } from 'react';
import Lightbox, { CustomSlideProperties, Pdf, RenderSlideProps, Slide, SlideImage } from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import ReactPlayer from 'react-player';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { downloadUrl } from 'helpers/files.helper';

const LightboxProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const state = useAppSelector((state) => state.lightbox);

    const { remove } = useLightbox();

    return (
        <>
            {children}
            <Lightbox
                carousel={{ finite: true }}
                open={state.isOpen}
                slides={state.slides}
                index={state.index || 0}
                download={{
                    // @ts-ignore
                    download: ({ saveAs, slide }) => downloadUrl({ src: slide.src, name: slide.download.filename })
                }}
                close={remove}
                plugins={[Zoom, Download, Thumbnails]}
                render={{
                    // @ts-ignore
                    slide: handleRender
                }}
            />
        </>
    );
};

type RenderProps = RenderSlideProps<Slide & CustomSlideProperties>;

const handleRender = ({ slide }: RenderProps) => {
    if (slide.subtype === 'pdf') return <PdfPreview slide={slide as Pdf} />;
    else if (
        slide.subtype === 'jpeg' ||
        slide.subtype === 'png' ||
        slide.subtype === 'jpg' ||
        slide.subtype === 'svg+xml' ||
        slide.subtype === 'gif' ||
        slide.subtype === 'webp'
    )
        return <ImagePreview slide={slide as SlideImage} />;
    else if (slide.subtype === 'mp4' || slide.subtype === 'avi' || slide.subtype === 'mov' || slide.subtype === 'mkv')
        return <VideoPreview url={slide.src} />;
    // DOC, DOCX
    else if (
        slide.subtype === 'vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        slide.subtype === 'doc' ||
        slide.subtype === 'msword' ||
        slide.subtype === 'docx'
    )
        return <DocPreview url={slide.src} />;
    else return <>File Type not supported</>;
};

const PreviewWrapper: React.FC<React.PropsWithChildren & BoxProps> = ({ children, ...props }) => {
    return (
        <Box {...props} sx={{ height: { xs: 'auto', sm: '100%' }, width: 'auto', ...props.sx }}>
            {children}
        </Box>
    );
};

interface PdfProps {
    slide: Pdf;
}

const PdfPreview = ({ slide }: PdfProps) => {
    return (
        <PreviewWrapper>
            <Box component={'iframe'} src={slide.src} key={slide.id} sx={{ width: '90vw', height: '90vh' }} />
        </PreviewWrapper>
    );
};

interface DocProps {
    url: string;
}

const DocPreview = ({ url }: DocProps) => {
    return (
        <PreviewWrapper>
            <DocViewer documents={[{ uri: url }]} pluginRenderers={DocViewerRenderers} />
        </PreviewWrapper>
    );
};

interface ImageProps {
    slide: SlideImage;
}

const ImagePreview = ({ slide }: ImageProps) => {
    return (
        <PreviewWrapper>
            <Box
                component={'img'}
                src={slide.src}
                sx={{ height: { xs: 'auto', sm: '100%' }, width: { xs: '100%', sm: 'auto' }, objectFit: 'cover' }}
            />
        </PreviewWrapper>
    );
};

interface VideoProps {
    url: string;
}

const VideoPreview = ({ url }: VideoProps) => {
    return (
        <PreviewWrapper sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component={ReactPlayer} controls={true} url={url} width={900} height={1600} />
        </PreviewWrapper>
    );
};

export default LightboxProvider;
