import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, Grid, Link, Modal, SxProps, Theme, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { ChatMessage } from 'types/chat.types';

interface Props {
    message: ChatMessage;
    sx?: SxProps<Theme> | undefined;
    position?: 'left' | 'right';
    handleReply?: (message: ChatMessage) => void;
    reply?: boolean;
}

const ChatMessageAdditionalControls = ({ message, sx, position, handleReply, reply = false }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                ...sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 0.5,
                    alignItems: 'end',
                    marginX: '20px'
                }}
            >
                {reply && (
                    <Link
                        style={{
                            display: position === 'right' ? 'flex' : 'none',
                            cursor: 'pointer'
                        }}
                        underline="hover"
                        variant={'caption'}
                        onClick={() => {
                            handleReply!(message);
                        }}
                    >
                        Reply
                    </Link>
                )}
                {message.users_read && message.users_unread && message.users_read.length > 0 ? (
                    <Tooltip
                        sx={{ display: 'flex' }}
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        title={
                            <>
                                {message.users_read.map((user_read) => (
                                    <Typography color="white">{user_read.user_name}</Typography>
                                ))}
                                {message.users_unread.map((user_unread) => (
                                    <Typography color="lightgray">{user_unread.user_name}</Typography>
                                ))}
                            </>
                        }
                    >
                        <DoneAllIcon />
                    </Tooltip>
                ) : (
                    <Tooltip
                        sx={{
                            display: 'flex',
                            sx: { float: position }
                        }}
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        title={
                            <>
                                {message.users_read &&
                                    message.users_read.map((user_read) => <Typography color="white">{user_read.user_name}</Typography>)}
                                {message.users_unread &&
                                    message.users_unread.map((user_unread) => (
                                        <Typography color="lightgray">{user_unread.user_name}</Typography>
                                    ))}
                            </>
                        }
                    >
                        <DoneIcon />
                    </Tooltip>
                )}

                {reply && (
                    <Link
                        style={{
                            display: position === 'right' ? 'none' : 'flex',
                            cursor: 'pointer'
                        }}
                        underline="hover"
                        variant={'caption'}
                        onClick={() => {
                            handleReply!(message);
                        }}
                    >
                        Reply
                    </Link>
                )}
                <Modal
                    open={isModalOpen}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClose={() => {
                        setIsModalOpen(false);
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '80%', sm: '40%', md: '30%' },
                            height: 'auto',
                            display: 'flex',
                            backgroundColor: 'white',
                            flexDirection: 'column',
                            textAlign: 'center',
                            borderRadius: '5px',
                            p: 1,
                            py: 5
                        }}
                    >
                        <Typography variant="h4" color="#595959">
                            Message Information
                        </Typography>
                        <Grid container sx={{ mt: 2 }}>
                            <Grid xs={12} sm={6} sx={{ minHeight: '100%' }}>
                                <Typography variant="h6" sx={{ pb: 1 }}>
                                    Seen By
                                </Typography>
                                {message.users_read?.map((user_read) => (
                                    <Typography>{user_read.user_name}</Typography>
                                ))}
                            </Grid>
                            <Grid xs={12} sm={6} sx={{ minHeight: '100%' }}>
                                <Typography variant="h6" sx={{ pb: 1 }}>
                                    Unseen By
                                </Typography>
                                {message.users_unread?.map((user_unread) => (
                                    <Typography>{user_unread.user_name}</Typography>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Box>
        </Box>
    );
};

export default ChatMessageAdditionalControls;
