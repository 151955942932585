/**
 * axios setup to use mock service
 */

import axios from 'axios';

const { protocol, hostname } = window.location;
const apiPath = '/wp-json/jwt-auth';
const API_URL = `${protocol}//${hostname}${apiPath}`;

const axiosServices = axios.create({
    baseURL: API_URL
});

axiosServices.interceptors.request.use(
    (config) => {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken) {
            config.headers!['authorization'] = `Bearer ${localStorage.getItem('serviceToken')}`;
            return config;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

axiosServices.defaults.validateStatus = (status) => status >= 200 && status <= 500;

export default axiosServices;
