import { NavItemType } from 'types/index.types';
import { RoleId } from 'types/user.types';

export const isUserSuperAdmin = (role: string) => {
    if (role === 'super_admin') return true;
    else if (role === 'administrator') return true;
    else return false;
};

export const isUserClient = (role: string) => {
    if (role === 'client') return true;
    else return false;
};

export const generatePassword = () => {
    const length = 20;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789%/^$@!#';
    let password = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
};

interface roleCheckProps {
    item: NavItemType;
    role: RoleId;
}

// Retruns "true" if user has access to the page. Returns false if not.
export const roleCheck = ({ item, role }: roleCheckProps) => {
    if ('isAdminOnly' in item) {
        if (item.isAdminOnly && !isUserSuperAdmin(role)) return false;
    }
    if ('allowedRoles' in item) {
        if (item.allowedRoles!.some((itemRole) => itemRole !== role)) return false;
    } else if ('probhitedRoles' in item) {
        if (item.probhitedRoles!.some((itemRole) => itemRole === role)) return false;
    }

    if (item.children) {
        for (const children of item.children) {
            if ('isAdminOnly' in children) {
                if (children.isAdminOnly && !isUserSuperAdmin(role)) return false;
            }
            if ('allowedRoles' in children) {
                if (children.allowedRoles!.some((itemRole) => itemRole !== role)) return false;
            } else if ('probhitedRoles' in children) {
                if (children.probhitedRoles!.some((itemRole) => itemRole === role)) return false;
            }
        }
    }
    return true;
};
