import {
    Chat,
    Collections,
    ExpandMore,
    Fullscreen,
    Home,
    Info,
    InsertDriveFile,
    LocalShipping,
    Menu,
    Notifications,
    Schedule
} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Checkbox,
    Chip,
    Divider,
    Grid2 as Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popper,
    SpeedDial,
    SpeedDialAction,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import config from 'config';
import { handleGetStatusColor } from 'helpers/orders.helper';
import { isUserClient, isUserSuperAdmin } from 'helpers/users.helper';
import { atom, useAtom } from 'jotai';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, useScrollSections } from 'react-scroll-section';
import { toast } from 'react-toastify';
import { useFetchChatOrdersQuery, useFetchOrderChatUnreadMessagesCountQuery } from 'store/api/chat.api';
import { useFetchClientsQuery } from 'store/api/clients.api';
import { useFetchUserOrderNotificationsQuery } from 'store/api/notifications.api';
import {
    useCreateExtraOrderMutation,
    useCreateFixOrderMutation,
    useCreateLocalFixOrderMutation,
    useCreateOrderBackupMutation,
    useCreateRelatedOrderMutation,
    useFetchAllShippingsOfOrderQuery,
    useFetchCompiledOrdersQuery,
    useFetchOrder3dFilesQuery,
    useFetchOrderDataQuery,
    useFetchOrderEmployeesQuery,
    useFetchOrderExternalDesignerFilesQuery,
    useFetchOrderFabricationFilesQuery,
    useFetchOrderFactoryFilesQuery,
    useFetchOrderFilesFromSurveyQuery,
    useFetchOrderGalleryQuery,
    useFetchOrderProductsQuery,
    useFetchRelatedOrdersQuery,
    useFetchShippingInformationQuery,
    useGetOrderBackupDataQuery,
    useToggleOrderClientDisplayMutation,
    useToggleOrderNotificationsMutation
} from 'store/api/orders.api';
import {
    useFetchCountriesQuery,
    useFetchProductsQuery,
    useFetchUserFactoriesQuery,
    useFetchUserOrderFieldsVisabilityQuery
} from 'store/api/system.api';
import Swal from 'sweetalert2';
import Dot from 'ui-component/dot';
import Loader from 'ui-component/Loader';
import UserNotifications from 'ui-component/notifications/UserNotifications';
import OrderChat from 'ui-component/order/OrderChat';
import OrderFactoryFiles from 'ui-component/order/OrderFactoryFiles';
import OrderGallery from 'ui-component/order/OrderGallery';
import OrderMainDetails from 'ui-component/order/OrderMainDetails';
import OrderMountingScheduler from 'ui-component/order/OrderMountingScheduler';
import OrderProducts from 'ui-component/order/OrderProducts';
import OrderShippingsTable from 'ui-component/shipping/AllOrderShippingsTable';
import { handlePermissionCheck, hexToRgbA, loadCheck, openInNewTab } from '../../../helpers/global.helper';
import useAuth from '../../../hooks/useAuth';
import Order3dFiles from '../../../ui-component/order/Order3dFiles';
import OrderExternalDesignerFiles from '../../../ui-component/order/OrderExternalDesignerFiles';
import OrderFabricationDrawingsFiles from '../../../ui-component/order/OrderFabricationDrawingsFiles';
import OrderSurveyFiles from '../../../ui-component/order/OrderSurveyFiles';
import OrdersTable from '../../../ui-component/orders/OrdersTable';
import { getMessage } from '../../../utils/messages';
import Error from '../maintenance/Error';

export const orderIdAtom = atom<null | number>(null);

interface Props {
    orderId?: number;
    handleClose?: () => void;
}

const OrderManagePage = (props: Props) => {
    const theme = useTheme();
    const { orderId: paramOrderId } = useParams();
    const [orderId, setOrderId] = useAtom(orderIdAtom);
    const { user } = useAuth();
    setOrderId(props.orderId || parseInt(paramOrderId!));
    const isOrderIdUpdated = orderId === props.orderId || orderId === parseInt(paramOrderId!);
    const isFirstRender = useRef(true);
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);

    const [notificationTabEnabled, setNotificationTabEnabled] = React.useState(false);
    const [notificationTabExpanded, setNotificationTabExpanded] = React.useState(!isDesktop);
    const [chatTabEnabled, setChatTabEnabled] = React.useState(true);
    const [chatTabExpanded, setChatTabExpanded] = React.useState(!isDesktop);

    const {
        data: order,
        isError: isOrderError,
        refetch: refetchOrderData,
        ...orderData
    } = useFetchOrderDataQuery(
        { orderId: orderId!, userId: user?.id! },
        { skip: !orderId || !user || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    const { data: relatedOrders, ...relatedOrdersData } = useFetchRelatedOrdersQuery(
        { orderId: orderId!, userId: user?.id!, userRole: user?.role! },
        { skip: !orderId || !user || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: shippingOrders, ...shippingOrdersData } = useFetchAllShippingsOfOrderQuery(
        { orderId: orderId!, userId: user?.id! },
        { skip: !orderId || !user || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: orderNotifications, ...orderNotificationsData } = useFetchUserOrderNotificationsQuery(
        {
            userId: user?.id!,
            orderId: orderId!,
            status: 'unread'
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !orderId || !user || !isOrderIdUpdated,
            pollingInterval: config.notificationsPollingInterval
        }
    );

    const { data: orderChatUnreadMessages } = useFetchOrderChatUnreadMessagesCountQuery(
        { userId: user?.id!, orderId: orderId! },
        { skip: !orderId || !user || !isOrderIdUpdated, pollingInterval: config.notificationsPollingInterval }
    );

    const { data: chatOrders, ...chatOrdersData } = useFetchChatOrdersQuery(
        { userId: user?.id! },
        {
            skip: !orderId || !user || !isOrderIdUpdated,
            pollingInterval: config.notificationsPollingInterval
        }
    );

    const { data: orderSurveyFiles, ...orderSurveyFilesData } = useFetchOrderFilesFromSurveyQuery(
        { orderId: orderId! },
        { skip: !orderId || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: orderFactoryFiles, ...orderFactoryFilesData } = useFetchOrderFactoryFilesQuery(
        { orderId: orderId!, userId: user?.id!, userRole: user?.role! },
        { skip: !orderId || !user?.id || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: orderExternalDesignerFiles, ...orderExternalDesignerFilesData } = useFetchOrderExternalDesignerFilesQuery(
        { orderId: orderId!, userId: user?.id!, userRole: user?.role! },
        { skip: !orderId || !user?.id || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: orderFabricationFiles, ...orderFabricationFilesData } = useFetchOrderFabricationFilesQuery(
        { orderId: orderId!, userId: user?.id!, userRole: user?.role! },
        { skip: !orderId || !user?.id || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: order3dFiles, ...order3dFilesData } = useFetchOrder3dFilesQuery(
        { orderId: orderId! },
        { skip: !orderId || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { data: orderBackup, ...orderBackupData } = useGetOrderBackupDataQuery({ orderId: orderId! }, { skip: !orderId });

    const { data: shipping } = useFetchShippingInformationQuery(
        { orderId: orderId! },
        { skip: !orderId || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { ...productsData } = useFetchProductsQuery(null, { refetchOnMountOrArgChange: true });

    const { refetch: refetchOrderProducts, ...orderProductsData } = useFetchOrderProductsQuery(
        { orderId: orderId! },
        { skip: !orderId || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const { ...clientsData } = useFetchClientsQuery(null, { refetchOnMountOrArgChange: true });

    const { ...ordersData } = useFetchCompiledOrdersQuery({ userId: user!.id }, { skip: !user, refetchOnMountOrArgChange: true });

    const { ...factoriesData } = useFetchUserFactoriesQuery({ userId: user!.id }, { skip: !user, refetchOnMountOrArgChange: true });

    const { ...countriesData } = useFetchCountriesQuery(null, { refetchOnMountOrArgChange: true });

    const { ...employeesData } = useFetchOrderEmployeesQuery(null, { refetchOnMountOrArgChange: true });

    const { ...galleryData } = useFetchOrderGalleryQuery(
        { orderId: orderId!, categories: [], search: '' },
        { skip: !orderId || !isOrderIdUpdated, refetchOnMountOrArgChange: true }
    );

    const [createExtraOrder] = useCreateExtraOrderMutation();
    const [createFixOrder] = useCreateFixOrderMutation();
    const [createRelatedOrder] = useCreateRelatedOrderMutation();
    const [createLocalFixOrder] = useCreateLocalFixOrderMutation();
    const [toggleOrderNotifications] = useToggleOrderNotificationsMutation();
    const [toggleClientVisibility] = useToggleOrderClientDisplayMutation();
    const [createOrderBackup, { isLoading: isCreateOrderBackupLoading }] = useCreateOrderBackupMutation();

    const sections = useScrollSections();

    const handleBackup = async () => {
        if (!orderId) return;
        const orderData = (await refetchOrderData()).data!;
        const orderProducts = (await refetchOrderProducts()).data!;
        const content = { orderData, orderProducts };
        toast.promise(createOrderBackup({ orderId, userId: user?.id!, content: JSON.stringify(content) }), {
            pending: getMessage('exportPending'),
            error: getMessage('exportError'),
            success: getMessage('exported')
        });
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (!order || orderData.isFetching || order.orderType !== 'related') return;

        if (!order.orderChanged && !isUserClient(user?.role!))
            Swal.fire({
                title: 'All data may be coppied from the parent order.',
                text: 'Make sure to double-check and remove all information and files that are not necessary for completion of this related order.',
                icon: 'warning'
            });
    }, [order]);

    useEffect(() => {
        if (!orderNotifications) return;

        if (!isDesktop) {
            setNotificationTabEnabled(true);
            return;
        }

        if (orderNotifications?.notifs.length === 0) setNotificationTabEnabled(false);
        else setNotificationTabEnabled(true);

        if (orderNotifications?.notifs.length > 0) setNotificationTabExpanded(true);
    }, [orderNotifications]);

    const Header = () => (
        <List sx={{ flexDirection: 'row-reverse', display: 'flex', py: 0, gap: { xs: 1, md: 0 } }} dense>
            <Box
                sx={{
                    display: { xs: 'none', md: 'flex' }
                }}
            >
                {handlePermissionCheck(userFieldsVisibility!, 'orderNotifications') && (
                    <Badge
                        badgeContent={orderNotifications?.notifs.length}
                        color={'error'}
                        showZero
                        sx={{
                            mr: 1,
                            zIndex: 1110,
                            '& .MuiBadge-badge': {
                                right: 3,
                                top: 4
                            }
                        }}
                    >
                        <ListItemButton
                            selected={notificationTabEnabled}
                            sx={(theme) => ({
                                borderRadius: `36px`,
                                border: `1px solid ${theme.palette.primary.light}`,
                                py: 0,
                                my: 0,
                                display: { xs: 'none', md: 'flex' },
                                alignItems: 'center',
                                backgroundColor: 'inherit',
                                color: 'black !important',
                                width: { xs: '100%', md: 'initial' }
                            })}
                            color="primary"
                            disabled={orderNotifications?.notifs.length === 0}
                            onClick={() => {
                                if (!notificationTabEnabled === true) setNotificationTabExpanded(true);
                                setNotificationTabEnabled(!notificationTabEnabled);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    my: 'auto',
                                    minWidth: 24,
                                    mr: '6px',
                                    '& path': { color: 'black' }
                                }}
                                color="black"
                            >
                                <Notifications />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={notificationTabEnabled ? 'h5' : 'body1'}
                                        color="inherit"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontWeight: notificationTabEnabled ? '600' : 'initial'
                                        }}
                                    >
                                        Notifications
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </Badge>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'orderChat') && (
                    <Badge
                        badgeContent={orderChatUnreadMessages}
                        color={'error'}
                        showZero
                        sx={{
                            mr: 1,
                            '& .MuiBadge-badge': {
                                right: 3,
                                top: 4
                            }
                        }}
                    >
                        <ListItemButton
                            selected={chatTabEnabled}
                            sx={(theme) => ({
                                borderRadius: `36px`,
                                border: `1px solid ${theme.palette.primary.light}`,
                                py: 0,
                                my: 0,
                                display: { xs: 'none', md: 'flex' },
                                alignItems: 'center',
                                backgroundColor: 'inherit',
                                color: 'black !important',
                                width: { xs: '100%', md: 'initial' }
                            })}
                            color="primary"
                            onClick={() => {
                                if (!chatTabEnabled === true) setChatTabExpanded(true);
                                setChatTabEnabled(!chatTabEnabled);
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    my: 'auto',
                                    minWidth: 24,
                                    mr: '6px',
                                    '& path': { color: 'black' }
                                }}
                                color="black"
                            >
                                <Chat />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={chatTabEnabled ? 'h5' : 'body1'}
                                        color="inherit"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontWeight: chatTabEnabled ? '600' : 'initial'
                                        }}
                                    >
                                        Chat
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </Badge>
                )}
            </Box>
            {!props.handleClose &&
                sections.map(({ id, onClick, selected, meta }) => (
                    <ListItemButton
                        selected={selected}
                        sx={(theme) => ({
                            borderRadius: `36px`,
                            border: `1px solid ${theme.palette.primary.light}`,
                            py: 0,
                            px: 2,
                            my: 0,
                            mr: 1,
                            alignItems: 'center',
                            backgroundColor: 'inherit',
                            color: 'black !important',
                            width: { xs: '100%', md: 'initial' }
                        })}
                        color="primary"
                        onClick={onClick}
                    >
                        <ListItemIcon
                            sx={{
                                my: 'auto',
                                minWidth: !meta ? 18 : 24,
                                mr: '6px',
                                '& path': { color: 'black' }
                            }}
                            color="black"
                        >
                            {meta as React.ReactNode}
                        </ListItemIcon>
                        {id.charAt(0).toUpperCase() + id.slice(1) !== undefined && (
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={selected ? 'h5' : 'body1'}
                                        color="inherit"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontWeight: selected ? '600' : 'initial'
                                        }}
                                    >
                                        {id.charAt(0).toUpperCase() + id.slice(1)}
                                    </Typography>
                                }
                            />
                        )}
                    </ListItemButton>
                ))}
        </List>
    );

    if (
        loadCheck(orderData) ||
        loadCheck(relatedOrdersData) ||
        loadCheck(shippingOrdersData) ||
        loadCheck(productsData) ||
        loadCheck(orderProductsData) ||
        loadCheck(order3dFilesData) ||
        loadCheck(orderExternalDesignerFilesData) ||
        loadCheck(orderFabricationFilesData) ||
        loadCheck(orderFactoryFilesData) ||
        loadCheck(orderSurveyFilesData) ||
        loadCheck(clientsData) ||
        loadCheck(ordersData) ||
        loadCheck(factoriesData) ||
        loadCheck(countriesData) ||
        loadCheck(employeesData) ||
        loadCheck(galleryData) ||
        loadCheck(orderNotificationsData) ||
        loadCheck(chatOrdersData) ||
        loadCheck(orderBackupData)
    )
        return <Loader />;

    if (isOrderError || !orderId) return <Error />;

    return (
        <Stack sx={{ bgcolor: 'white', border: '1px solid rgb(211, 226, 253);' }}>
            <Grid
                container
                rowSpacing={1}
                wrap="nowrap"
                sx={{
                    position: { xs: 'initial', sm: 'sticky' },
                    top: props.handleClose ? 47 : { xs: 78, sm: 76, md: 60 },
                    bgcolor: 'white',
                    zIndex: 100,
                    px: 2,
                    py: 1,
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {!isUserClient(user?.role!) && user?.role !== 'mounting_worker' && shipping && (
                    <Grid
                        size={'auto'}
                        display="flex"
                        sx={{
                            alignItems: 'center',

                            display: { xs: 'initial', md: 'none', lg: 'initial', xl: 'none' }
                        }}
                    >
                        <Tooltip title={shipping.status?.label}>
                            <Box component={Link} to={`/shipping/${shipping.shipping_id}`} onClick={props.handleClose} sx={{ my: 'auto' }}>
                                <Dot sx={{ bgcolor: handleGetStatusColor(shipping.status?.value), mr: 1 }} />
                            </Box>
                        </Tooltip>
                    </Grid>
                )}

                <Grid size={'auto'}>
                    <Typography
                        fontSize={20}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                        variant="h1"
                    >
                        {order && `${order.orderNum}`}
                    </Typography>
                </Grid>

                <Grid
                    size={'grow'}
                    display="flex"
                    sx={{
                        alignItems: 'end'
                    }}
                >
                    <Tooltip title={order?.orderTitle}>
                        <Typography
                            component={'span'}
                            sx={{
                                ml: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mb: { xs: -0.25, md: 0, lg: 0.5 }
                            }}
                            variant="subtitle2"
                        >
                            {order && `${order.orderTitle}`}
                        </Typography>
                    </Tooltip>

                    {!isUserClient(user?.role!) && user?.role !== 'mounting_worker' && shipping && (
                        <Tooltip
                            title={shipping.status?.label}
                            sx={{ ml: 2, display: { xs: 'none', md: 'initial', lg: 'none', xl: 'initial' } }}
                        >
                            <Box component={Link} to={`/shipping/${shipping.shipping_id}`} onClick={props.handleClose}>
                                <Chip
                                    label={`Shipping Number ${shipping.number}`}
                                    size="small"
                                    sx={{ bgcolor: handleGetStatusColor(shipping.status?.value), fontSize: 11 }}
                                />
                            </Box>
                        </Tooltip>
                    )}

                    <Box sx={{ display: { xs: 'none', lg: 'block' }, ml: 2 }}>
                        <Header />
                    </Box>
                </Grid>

                <Grid size={'auto'} sx={{ display: 'flex', justifyContent: 'end', ml: 2 }}>
                    <IconButton
                        onClick={(event) => setMenuAnchorElement(menuAnchorElement ? null : event.currentTarget)}
                        size="small"
                        disableFocusRipple
                        disableRipple
                        sx={{
                            p: '0px !important'
                        }}
                    >
                        <Menu sx={{ width: 20, height: 20 }} />
                    </IconButton>
                </Grid>
                <Popper
                    open={Boolean(menuAnchorElement)}
                    anchorEl={menuAnchorElement}
                    placement="bottom-start"
                    sx={{ zIndex: 1200 }}
                    // modifiers={[
                    //     {
                    //         name: 'offset',
                    //         options: {
                    //             offset: [0, -5]
                    //         }
                    //     }
                    // ]}
                >
                    <List
                        sx={{
                            whiteSpace: 'nowrap',
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            border: '1px solid rgb(211, 226, 253)',
                            borderRadius: 4
                        }}
                    >
                        {!isUserClient(user?.role!) && user?.role !== 'mounting_worker' && shipping && (
                            <ListItem
                                disablePadding
                                sx={{
                                    display: { xs: 'initial', md: 'none', lg: 'initial', xl: 'none' }
                                }}
                            >
                                <ListItemButton
                                    dense
                                    sx={{ bgcolor: `${hexToRgbA(handleGetStatusColor(shipping.status?.value), 0.3)} !important` }}
                                    disabled={orderData.isFetching}
                                    onChange={() => {
                                        toast.promise(
                                            toggleOrderNotifications({ orderId: orderId, userId: user?.id! }),
                                            {
                                                pending: getMessage('updatePending'),
                                                error: getMessage('updateError'),
                                                success: getMessage('updated')
                                            },
                                            { autoClose: 1000 }
                                        );
                                    }}
                                >
                                    <Link
                                        to={`/shipping/${shipping.shipping_id}`}
                                        onClick={props.handleClose}
                                        style={{ textDecoration: 'none', color: 'initial' }}
                                    >
                                        Shipping Number {shipping.number}
                                    </Link>
                                </ListItemButton>
                            </ListItem>
                        )}

                        {order && !isUserClient(user?.role!) && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    dense
                                    disabled={orderData.isFetching}
                                    onChange={() => {
                                        toast.promise(
                                            toggleOrderNotifications({ orderId: orderId, userId: user?.id! }),
                                            {
                                                pending: getMessage('updatePending'),
                                                error: getMessage('updateError'),
                                                success: getMessage('updated')
                                            },
                                            { autoClose: 1000 }
                                        );
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={!order?.notifications_disabled}
                                            size="small"
                                            sx={{ py: 0 }}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={`Notifications`} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {order && isUserSuperAdmin(user?.role!) && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    dense
                                    disabled={orderData.isFetching}
                                    onChange={() => {
                                        toast.promise(
                                            toggleClientVisibility({ orderId: orderId, userId: user?.id! }),
                                            {
                                                pending: getMessage('updatePending'),
                                                error: getMessage('updateError'),
                                                success: getMessage('updated')
                                            },
                                            { autoClose: 1000 }
                                        );
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            disableRipple
                                            checked={order?.client_display_status}
                                            size="small"
                                            sx={{ py: 0 }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={`Show To Client`} />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {order && order.originalOrder !== 0 && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={() => {
                                        openInNewTab(`/order/${order.originalOrder}`);
                                    }}
                                >
                                    <ListItemText primary="Original Order" />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {order && !order.originalOrder && user?.role! !== 'client' && (
                            <>
                                {user?.role! !== 'mounting_worker' && (
                                    <Tooltip
                                        title="
                        When you have an order and you have made something wrong or you need to order more for some reason.
                        "
                                    >
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                dense
                                                onClick={() => {
                                                    Swal.fire({ title: 'Do you want to create a extra order?' }).then(({ isConfirmed }) => {
                                                        if (isConfirmed)
                                                            toast.promise(
                                                                createExtraOrder({ orderId, userId: user?.id! }).then((result) => {
                                                                    if ('data' in result) {
                                                                        openInNewTab(`/order/${result.data.order_id}`);
                                                                    }
                                                                }),
                                                                {
                                                                    pending: getMessage('createPending'),
                                                                    success: getMessage('created')
                                                                }
                                                            );
                                                    });
                                                }}
                                            >
                                                <ListItemText
                                                    primary="
                                        Extra Order
"
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Tooltip>
                                )}
                                <Tooltip title="When factory have made something wrong and need to redo/fix something.">
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            dense
                                            onClick={() => {
                                                Swal.fire({ title: 'Do you want to create a fix order?' }).then(({ isConfirmed }) => {
                                                    if (isConfirmed)
                                                        toast.promise(
                                                            createFixOrder({ orderId, userId: user?.id! }).then((result) => {
                                                                if ('data' in result) {
                                                                    openInNewTab(`/order/${result.data.order_id}`);
                                                                }
                                                            }),
                                                            {
                                                                pending: getMessage('createPending'),
                                                                success: getMessage('created')
                                                            }
                                                        );
                                                });
                                            }}
                                        >
                                            <ListItemText primary="Fix Order" />
                                        </ListItemButton>
                                    </ListItem>
                                </Tooltip>
                                {user?.role! !== 'mounting_worker' && (
                                    <Tooltip
                                        title="
                            Copy current order data to new order which will be linked to this order (Ie. Related Order)."
                                    >
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                dense
                                                onClick={() => {
                                                    Swal.fire({ title: 'Do you want to create a related order?' }).then(
                                                        ({ isConfirmed }) => {
                                                            if (isConfirmed)
                                                                toast.promise(
                                                                    createRelatedOrder({ orderId, userId: user?.id! }).then((result) => {
                                                                        if ('data' in result) {
                                                                            openInNewTab(`/order/${result.data.order_id}`);
                                                                        }
                                                                    }),
                                                                    {
                                                                        pending: getMessage('createPending'),
                                                                        success: getMessage('created')
                                                                    }
                                                                );
                                                        }
                                                    );
                                                }}
                                            >
                                                <ListItemText
                                                    primary="
                                        Related Order
"
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Tooltip>
                                )}
                                <Tooltip title="When factory have made something wrong and need to redo/fix something locally.">
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            dense
                                            onClick={() => {
                                                Swal.fire({ title: 'Do you want to create a local fix order?' }).then(({ isConfirmed }) => {
                                                    if (isConfirmed)
                                                        toast.promise(
                                                            createLocalFixOrder({ orderId, userId: user?.id! }).then((result) => {
                                                                if ('data' in result) {
                                                                    openInNewTab(`/order/${result.data.order_id}`);
                                                                }
                                                            }),
                                                            {
                                                                pending: getMessage('createPending'),
                                                                success: getMessage('created')
                                                            }
                                                        );
                                                });
                                            }}
                                        >
                                            <ListItemText
                                                primary="
                                        Local Fix Order
"
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </Tooltip>
                            </>
                        )}
                        <Box component={'label'}>
                            <ListItemButton
                                dense
                                disabled={
                                    isCreateOrderBackupLoading ||
                                    orderBackupData.isFetching ||
                                    orderData.isFetching ||
                                    orderProductsData.isFetching
                                }
                                onClick={handleBackup}
                            >
                                <ListItemText primary="Backup" />
                            </ListItemButton>
                        </Box>
                        {orderBackup && (
                            <ListItem sx={{}}>
                                <ListItemText
                                    primary={`Last Backup: ${moment.unix(orderBackup.timestamp).format()} ${moment
                                        .unix(orderBackup.timestamp)
                                        .format('HH:mm')} `}
                                />
                            </ListItem>
                        )}
                    </List>
                </Popper>
            </Grid>
            <Box
                sx={{
                    display: { xs: 'none', sm: 'block', lg: 'none' },
                    px: 2,
                    py: 1
                }}
            >
                <Header />
            </Box>
            {/* <Divider /> */}
            <Grid container spacing={2} className="order-grid">
                {(handlePermissionCheck(userFieldsVisibility!, 'orderTitle') ||
                    handlePermissionCheck(userFieldsVisibility!, 'orderStatus') ||
                    handlePermissionCheck(userFieldsVisibility!, 'shippingAddress') ||
                    handlePermissionCheck(userFieldsVisibility!, 'onHoldReason') ||
                    handlePermissionCheck(userFieldsVisibility!, 'customAddress') ||
                    handlePermissionCheck(userFieldsVisibility!, 'client') ||
                    handlePermissionCheck(userFieldsVisibility!, 'factory') ||
                    handlePermissionCheck(userFieldsVisibility!, 'mounting') ||
                    handlePermissionCheck(userFieldsVisibility!, 'country') ||
                    handlePermissionCheck(userFieldsVisibility!, 'employees') ||
                    handlePermissionCheck(userFieldsVisibility!, 'deadlineDate') ||
                    handlePermissionCheck(userFieldsVisibility!, 'deadlineDate')) && (
                    <Grid size={{ xs: 12 }}>
                        <Section id="details" meta={<Info />}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                    sx={{
                                        background: '#424242',
                                        color: 'white',
                                        height: '40px',
                                        minHeight: '0 !important',
                                        justifyContent: 'center !important',
                                        '& .MuiAccordionSummary-content': {
                                            justifyContent: 'center'
                                        }
                                    }}
                                >
                                    Order Details
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: { md: '16px 16px 16px 16px', xs: '8px 0' } }}>
                                    <OrderMainDetails />
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                    </Grid>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'relatedOrders') && (
                    <>
                        {relatedOrders && relatedOrders.rows.length > 0 && (
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <Section id="relatedOrders" meta={<Home />}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                                sx={{
                                                    background: '#424242',
                                                    color: 'white',
                                                    height: '40px',
                                                    minHeight: '0 !important',

                                                    '& .MuiAccordionSummary-content': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                            >
                                                Related Orders
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    paddingTop: '16px',
                                                    padding: { md: '16px 8px 16px 16px', xs: '8px 0' }
                                                }}
                                            >
                                                {relatedOrders && <OrdersTable tableData={relatedOrders!} direction="" filters />}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Section>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                    </>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'shippingOrders') && (
                    <>
                        {shippingOrders && shippingOrders.rows?.length > 1 && (
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <Section id="shippingOrders" meta={<Home />}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                                sx={{
                                                    background: '#424242',
                                                    color: 'white',
                                                    height: '40px',
                                                    minHeight: '0 !important',

                                                    '& .MuiAccordionSummary-content': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                            >
                                                Shipping Orders
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    border: '1px solid #424242',
                                                    paddingTop: '16px',
                                                    padding: { md: '16px 8px 16px 16px', xs: '8px 0' }
                                                }}
                                            >
                                                {shippingOrders && <OrderShippingsTable tableData={shippingOrders!} />}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Section>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                    </>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'products') && (
                    <Grid size={{ xs: 12 }}>
                        <Section id="products" meta={<LocalShipping />}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                    sx={{
                                        background: '#424242',
                                        color: 'white',
                                        height: '40px',
                                        minHeight: '0 !important',

                                        '& .MuiAccordionSummary-content': {
                                            justifyContent: 'center'
                                        }
                                    }}
                                >
                                    Products
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        paddingTop: '16px',
                                        padding: { md: '16px 8px 16px 16px', xs: '16px 16px' }
                                    }}
                                >
                                    <OrderProducts />
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                    </Grid>
                )}

                {(handlePermissionCheck(userFieldsVisibility!, 'orderSurveyFiles') ||
                    handlePermissionCheck(userFieldsVisibility!, 'orderFactoryFiles') ||
                    handlePermissionCheck(userFieldsVisibility!, 'orderExternalDesignerFiles') ||
                    handlePermissionCheck(userFieldsVisibility!, 'orderFabricationFiles') ||
                    handlePermissionCheck(userFieldsVisibility!, 'order3dFiles')) && (
                    <Grid size={{ xs: 12 }}>
                        <Section id="files" meta={<InsertDriveFile />}>
                            {handlePermissionCheck(userFieldsVisibility!, 'orderSurveyFiles') && (
                                <>
                                    {orderSurveyFiles && (
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                                sx={{
                                                    background: '#424242',
                                                    color: 'white',
                                                    height: '40px',
                                                    minHeight: '0 !important',

                                                    '& .MuiAccordionSummary-content': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                            >
                                                Files From Survey And Client{' '}
                                                <Typography sx={{ ml: 2, my: 'auto', color: 'white' }} variant="h5">
                                                    {orderSurveyFiles.length} Files
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: { md: '16px 8px 16px 16px', xs: '8px 0' } }}>
                                                <OrderSurveyFiles />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </>
                            )}

                            {handlePermissionCheck(userFieldsVisibility!, 'orderFactoryFiles') && (
                                <>
                                    {orderFactoryFiles && (
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                                sx={{
                                                    background: '#424242',
                                                    color: 'white',
                                                    height: '40px',
                                                    minHeight: '0 !important',
                                                    '& .MuiAccordionSummary-content': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                            >
                                                Files from factory for approval
                                                <Typography sx={{ ml: 2, my: 'auto', color: 'white' }} variant="h5">
                                                    {
                                                        orderFactoryFiles.filter((item) =>
                                                            isUserClient(user?.role!) ? item.sent_to_client : true
                                                        ).length
                                                    }{' '}
                                                    Files
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: { md: '16px 8px 16px 16px', xs: '8px 0' } }}>
                                                <OrderFactoryFiles />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </>
                            )}
                            {handlePermissionCheck(userFieldsVisibility!, 'orderExternalDesignerFiles') && (
                                <>
                                    {orderExternalDesignerFiles && (
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                                sx={{
                                                    background: '#424242',
                                                    color: 'white',
                                                    height: '40px',
                                                    minHeight: '0 !important',

                                                    '& .MuiAccordionSummary-content': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                            >
                                                External Designer Files For Approval
                                                <Typography sx={{ ml: 2, my: 'auto', color: 'white' }} variant="h5">
                                                    {
                                                        orderExternalDesignerFiles.filter((item) =>
                                                            isUserClient(user?.role!) ? item.sent_to_client : true
                                                        ).length
                                                    }{' '}
                                                    Files
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: { md: '16px 8px 16px 16px', xs: '8px 0' } }}>
                                                <OrderExternalDesignerFiles />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </>
                            )}
                            {handlePermissionCheck(userFieldsVisibility!, 'orderFabricationFiles') && (
                                <>
                                    {orderFabricationFiles && (
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                                sx={{
                                                    background: '#424242',
                                                    color: 'white',
                                                    height: '40px',
                                                    minHeight: '0 !important',

                                                    '& .MuiAccordionSummary-content': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                            >
                                                Fabrication Drawings
                                                <Typography sx={{ ml: 2, my: 'auto', color: 'white' }} variant="h5">
                                                    {
                                                        orderFabricationFiles.filter((item) =>
                                                            isUserClient(user?.role!) ? item.sent_to_client : true
                                                        ).length
                                                    }{' '}
                                                    Files
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: { md: '16px 8px 16px 16px', xs: '8px 0' } }}>
                                                <OrderFabricationDrawingsFiles />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </>
                            )}

                            {handlePermissionCheck(userFieldsVisibility!, 'order3dFiles') && (
                                <>
                                    {order3dFiles && (
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                sx={{
                                                    background: '#424242',
                                                    color: 'white',
                                                    height: '40px',
                                                    minHeight: '0 !important',

                                                    '& .MuiAccordionSummary-content': {
                                                        justifyContent: 'center'
                                                    }
                                                }}
                                            >
                                                3D Files
                                                <Typography sx={{ ml: 2, my: 'auto', color: 'white' }} variant="h5">
                                                    {order3dFiles.length} Files
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: { md: '16px 8px 16px 16px', xs: '8px 0' } }}>
                                                <Order3dFiles />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </>
                            )}
                        </Section>
                    </Grid>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'orderChat') && !isDesktop && (
                    <Grid
                        size={{ xs: 12 }}
                        sx={{
                            display: { xs: 'flex', md: 'none' }
                        }}
                    >
                        <Section id="chat" meta={<Chat />} style={{ width: '100%' }}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                    sx={{
                                        background: orderChatUnreadMessages! > 0 ? 'red' : '#424242',
                                        color: 'white',
                                        height: '40px',
                                        minHeight: '0 !important',

                                        '& .MuiAccordionSummary-content': {
                                            justifyContent: 'center'
                                        }
                                    }}
                                >
                                    Order Chat
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0 }}>
                                    <OrderChat />{' '}
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                    </Grid>
                )}
                {handlePermissionCheck(userFieldsVisibility!, 'scheduler') && (
                    <Grid size={{ xs: 12 }}>
                        <Section id="scheduler" meta={<Schedule />}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                    sx={{
                                        background: '#424242',
                                        color: 'white',
                                        height: '40px',
                                        minHeight: '0 !important',

                                        '& .MuiAccordionSummary-content': {
                                            justifyContent: 'center'
                                        }
                                    }}
                                >
                                    Mounting Scheduler
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0 }}>
                                    <OrderMountingScheduler />
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                    </Grid>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'gallery') && (
                    <Grid size={{ xs: 12 }}>
                        <Section id="gallery" meta={<Collections />}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                                    sx={{
                                        background: '#424242',
                                        color: 'white',
                                        height: '40px',
                                        minHeight: '0 !important',
                                        '& .MuiAccordionSummary-content': {
                                            justifyContent: 'center'
                                        }
                                    }}
                                >
                                    Gallery
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        padding: { md: '16px 8px 16px 16px', xs: '8px 0' }
                                    }}
                                >
                                    <OrderGallery />
                                </AccordionDetails>
                            </Accordion>
                        </Section>
                    </Grid>
                )}
            </Grid>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: isDesktop ? 'row' : 'column',
                    mx: isDesktop ? 2.5 : 11,
                    gap: isDesktop ? 2 : 0,
                    zIndex: 500,
                    alignItems: 'end',
                    bgcolor: 'transparent',
                    width: { xs: '100%', md: 'initial' }
                }}
            >
                {handlePermissionCheck(userFieldsVisibility!, 'orderNotifications') && notificationTabEnabled && (
                    <Accordion
                        expanded={notificationTabExpanded}
                        onChange={() => {
                            setNotificationTabExpanded(!notificationTabExpanded);
                        }}
                        sx={{
                            '&:before': { display: 'none' },
                            display: 'initial',
                            width: { xs: '65%', sm: '50%', md: 'initial' }
                        }}
                        disableGutters
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                            sx={{
                                background: orderNotifications?.notifs.length! > 0 ? 'red' : '#424242',
                                color: 'white',
                                height: '40px',
                                minHeight: '0 !important',
                                justifyContent: 'center !important',
                                '& .MuiAccordionSummary-content': {
                                    justifyContent: 'center'
                                }
                            }}
                        >
                            Notifications
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                maxWidth: 350
                                // width: '65%'
                            }}
                        >
                            <UserNotifications orderId={orderId} userId={user?.id!} filters={false} />
                        </AccordionDetails>
                    </Accordion>
                )}
                {handlePermissionCheck(userFieldsVisibility!, 'orderChat') && chatTabEnabled && (
                    <Accordion
                        sx={{
                            '&:before': { display: 'none' },
                            display: isDesktop ? 'initial' : 'none'
                        }}
                        expanded={chatTabExpanded}
                        onChange={() => {
                            setChatTabExpanded(!chatTabExpanded);
                        }}
                        disableGutters
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{ color: 'white' }} />}
                            sx={{
                                background: orderChatUnreadMessages! > 0 ? 'red' : '#424242',
                                color: 'white',
                                height: '40px',
                                minHeight: '0 !important',
                                justifyContent: 'center !important',
                                '& .MuiAccordionSummary-content': {
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            }}
                        >
                            {chatOrders?.find((order) => order.post_id === orderId) !== undefined ? (
                                <Link to={`/chat/${orderId}`} style={{ marginRight: 'auto' }}>
                                    <IconButton sx={{ color: 'white', p: 0 }}>
                                        <Fullscreen />
                                    </IconButton>
                                </Link>
                            ) : (
                                <Tooltip title="Order chat is not initialized yet, send a message to expand">
                                    <label style={{ marginRight: 'auto' }}>
                                        <IconButton sx={{ color: 'lightgray', p: 0, pointerEvents: 'none' }}>
                                            <Fullscreen />
                                        </IconButton>
                                    </label>
                                </Tooltip>
                            )}
                            Chat
                            <Box sx={{ flexGrow: 0.5 }} />
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                width: isDesktop ? 380 : 300,
                                py: 0
                            }}
                        >
                            {chatTabExpanded && <OrderChat />}
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
            {user?.role! !== 'client' && (
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'fixed', bottom: 16, right: 16, display: { xs: 'flex', sm: 'none' } }}
                    icon={sections.find((section) => section.selected)?.meta as React.ReactNode}
                >
                    {sections.reverse().map(({ id, onClick, selected, meta }) => (
                        <SpeedDialAction
                            key={id}
                            tooltipTitle={id.charAt(0).toUpperCase() + id.slice(1)}
                            onClick={onClick}
                            icon={meta as React.ReactNode}
                            FabProps={{
                                sx: {
                                    '&:hover': { bgcolor: (theme) => (selected ? theme.palette.primary.dark : 'initial') },
                                    bgcolor: (theme) => (selected ? theme.palette.primary.main : 'white'),
                                    color: selected ? 'white' : 'initial',
                                    zIndex: 10000
                                }
                            }}
                            PopperProps={{ sx: { bgcolor: 'white' } }}
                            tooltipOpen
                        />
                    ))}
                </SpeedDial>
            )}
        </Stack>
    );
};

export default OrderManagePage;
