import { Typography, Grid2 as Grid, Stack } from '@mui/material';
import { File as CustomFile } from '../../types/file.types';
import React from 'react';
import FileIcon from './FileIcon';

interface Props {
    files: CustomFile[];
    handleRemove?: (index: number) => void;
}

const FileGrid: React.FC<Props> = ({ files, handleRemove }) => {
    return (
        <Grid container sx={{ width: '100%' }}>
            {files.map((file) => (
                <Grid size={{ xs: 12, sm: 6 }} sx={{ display: 'flex', gap: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <FileIcon
                        type={file.type}
                        src={file.src}
                        id={file.id}
                        name={file.name}
                        subtype={file.subtype}
                        live={true}
                        style={{ maxWidth: '50px', maxHeight: '50px', width: '100%', height: '100%' }}
                    />
                    <Stack sx={{ justifyContent: 'center' }}>
                        <Typography variant={'body1'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {file.name}
                        </Typography>
                        <Typography variant={'body2'} sx={{ opacity: 0.5 }}>
                            {file.type}
                        </Typography>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};

export default FileGrid;
