import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Button,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Grid2 as Grid
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAddCountryMutation, useDeleteCountryMutation, useEditCountryMutation, useFetchCountriesQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import { Country, CountrySchema } from 'types/global.types';
import MainCard from 'ui-component/cards/MainCard';
import { getMessage } from 'utils/messages';

const SystemCountriesPage = () => {
    const { control, handleSubmit } = useForm<Country>({
        resolver: zodResolver(CountrySchema.omit({ id: true })),
        defaultValues: { abbr: undefined, name: undefined }
    });

    const { data: countries } = useFetchCountriesQuery({});

    const [addCountry] = useAddCountryMutation();
    const [deleteCountry] = useDeleteCountryMutation();

    const onSubmit: SubmitHandler<Country> = (data) => {
        addCountry({
            country_name: data.name,
            country_abbreviation: data.abbr
        }).then(() => toast.success(getMessage('added')));
    };

    const handleDelete = (countryId: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                deleteCountry({
                    country_id: countryId
                }).then(() => toast.success(getMessage('deleted')));
            }
        });
    };

    return (
        <MainCard
            title="Countries"
            divider
            dense
            sx={{
                width: '100%'
            }}
            content={false}
            Header={
                <Grid
                    component={'form'}
                    container
                    spacing={2}
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        mx: 2,
                        alignItems: 'center'
                    }}
                >
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label="Country Name"
                                    size="small"
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                    required
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="abbr"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label="Country Abbreviation"
                                    size="small"
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                    error={!!error}
                                    fullWidth
                                    helperText={error?.message}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Button variant="contained" type="submit" size="small" fullWidth>
                            Add New
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Country Name</TableCell>
                            <TableCell>Country Name Abbreviation</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {countries &&
                            countries.map((country) => {
                                return (
                                    <TableRow
                                        key={country.id}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell>{country.name}</TableCell>
                                        <TableCell>{country.abbr}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => NiceModal.show(EditModal, { country })}
                                                    sx={{ width: 80 }}
                                                >
                                                    Edit
                                                </Button>
                                                <Button variant="contained" color="error" onClick={() => handleDelete(country.id)}>
                                                    Delete
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
};

const EditModal = NiceModal.create(({ country }: { country: Country }) => {
    const modal = useModal();

    const { control, handleSubmit } = useForm<Country>({
        resolver: zodResolver(CountrySchema),
        defaultValues: country
    });
    const [editCountry] = useEditCountryMutation();

    const handleEdit: SubmitHandler<Country> = (data) => {
        editCountry({
            country_id: data.id,
            edited_country_name: data.name,
            edited_abbreviation_name: data.abbr
        }).then(() => toast.success(getMessage('edited')));
        modal.remove();
    };

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()}>
            <Paper
                component={'form'}
                onSubmit={handleSubmit(handleEdit)}
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    transform: 'translate(-50%, -50%)',
                    p: 2
                }}
            >
                <Stack gap={2}>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                placeholder="Metalform Latvia"
                                label="Country Name"
                                {...field}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="abbr"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                placeholder="MLF"
                                label="Country Abbreviation"
                                {...field}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />

                    <Button sx={{ height: 50 }} variant="contained" type="submit">
                        EDIT COUNTRY
                    </Button>
                </Stack>
            </Paper>
        </Modal>
    );
});

export default SystemCountriesPage;
