import { Optional } from 'types/global.types';
import { File } from '../types/file.types';

export const downloadUrl = async ({ src, name }: Pick<File, 'src' | 'name'>) => {
    if (!src) return;
    let link = document.createElement('a');
    document.body.appendChild(link);
    link.download = name;
    link.href = src;
    link.target = '_blank';
    link.click();
    link.remove();
};

export const getFileType = (filePath: string) => {
    const parts = filePath.split('.');
    return parts[parts.length - 1];
};

export const objectToCSVBlob = (data: any) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
        const values = headers.map((header) => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }

    const csvString = csvRows.join('\r\n');
    const csvBlob = new Blob([csvString], { type: 'text/csv' });

    return csvBlob;
};

export const urlToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const result = reader.result as string; // Ensure reader.result is a string
            resolve(result.split(',')[1]); // Extract base64 content after the "base64," prefix
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};
