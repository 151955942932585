import { clients, getMountingSchedulerMenu, orders, shippingList, system, gallery, dashboard } from './export';
import { NavItemType } from 'types/index.types';

const getMenuItems = async () => {
    const items: NavItemType[] = [dashboard, orders, shippingList, clients, gallery, system];
    const mountingScheduler = await getMountingSchedulerMenu();
    items.splice(-4, 0, mountingScheduler);
    return { items };
};

export default getMenuItems;
