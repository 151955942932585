import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Info, Search } from '@mui/icons-material';
import {
    Box,
    BoxProps,
    Button,
    Grid2 as Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { fuzzyFilter } from 'helpers/table.helper';
import useAuth from 'hooks/useAuth';
import { atom, useAtom } from 'jotai';
import moment from 'moment';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    useChangeAllUserNotificationStatusMutation,
    useChangeNotificationStatusMutation,
    useFetchUserNotificationsQuery,
    useFetchUserOrderNotificationsQuery
} from 'store/api/notifications.api';
import { BackButtonExtended } from 'types/global.types';
import { MultiNotification, Notification, NotificationStatus } from 'types/notifications.types';
import Cover from 'ui-component/Cover';
import DrawerCard from 'ui-component/cards/DrawerCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Drawer from 'ui-component/extended/Drawer';
import DebouncedTextField from 'ui-component/inputs/DebouncedTextField';
import { OrderDrawer } from 'ui-component/orders/OrdersTable';
import { useImmer } from 'use-immer';

interface UserNotificationsProps extends BoxProps {
    //target id
    userId: number;
    orderId?: number;
    isDrawer?: boolean;
    filters?: boolean;
    viewOrderInDrawer?: boolean;
}

export const notificationStatusFilterAtom = atom<NotificationStatus>('unread');
export const notificationStepAtom = atom<BackButtonExtended>(0);
export const notificationIdAtom = atom<null | number>(null);
export const notificationTargetIdAtom = atom<null | number>(null);

const UserNotifications = ({
    isDrawer = false,
    userId,
    orderId,
    filters = true,
    viewOrderInDrawer = false,
    ...props
}: UserNotificationsProps) => {
    const columnHelper = createColumnHelper<Notification>();

    const [step, setStep] = useAtom(notificationStepAtom);
    const [notificationId, setNotificationId] = useAtom(notificationIdAtom);
    const [notificationStatusFilter, setNotificationStatusFilter] = useAtom(notificationStatusFilterAtom);
    const [tableData, setTableData] = useImmer<Notification[]>([]);
    const [changeUserNotificationStatus] = useChangeNotificationStatusMutation();

    const { data: userNotifications } = useFetchUserNotificationsQuery(
        {
            userId,
            status: notificationStatusFilter
        },
        { refetchOnMountOrArgChange: true, skip: orderId !== undefined ? true : false }
    );

    const { data: orderNotifications } = useFetchUserOrderNotificationsQuery(
        {
            userId,
            orderId: orderId!,
            status: notificationStatusFilter
        },
        { refetchOnMountOrArgChange: true, skip: orderId !== undefined ? false : true }
    );

    const [notifications, setNotifications] = useImmer<null | { notifs: Notification[]; users: { id: number; name: string }[] }>(null);

    useEffect(() => {
        if (userNotifications) {
            setNotifications(userNotifications);
        } else if (orderNotifications) {
            setNotifications(orderNotifications);
        }
    }, [orderNotifications, userNotifications]);

    useEffect(() => {
        if (!notifications) return;
        setTableData(notifications.notifs);
    }, [notifications]);

    const columns = [
        columnHelper.accessor((row) => row.notif_title, {
            id: 'title',
            cell: (info) => {
                let to = null;
                switch (info.row.original.postType) {
                    case 'sale_opportunity':
                        to = `/order/${info.row.original.postId}`;
                        break;
                    case 'shipping_order':
                        to = `/shipping/${info.row.original.postId}`;
                        break;
                }
                let numOfUnread = info.row.original?.multiData?.filter((itm) => itm.status === false).length || 0;
                let numOfRead = info.row.original?.multiData?.filter((itm) => itm.status === true).length || 0;
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: isDrawer ? 'column' : 'row' },
                            flexWrap: { xs: 'wrap' },
                            gap: 2
                        }}
                    >
                        <Box
                            sx={{ display: 'flex', gap: 1, width: '100%' }}
                            onClick={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <Stack
                                sx={{
                                    maxWidth: 200
                                }}
                            >
                                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                                    {info.getValue()}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontWeight: 400, opacity: 0.7 }}>
                                    {moment(info.row.original.date, 'DD-MM-YYYY HH:mm').format()}{' '}
                                    {moment(info.row.original.date, 'DD-MM-YYYY HH:mm').format('HH:mm')}
                                </Typography>
                            </Stack>
                        </Box>
                        <Stack sx={{ overflow: 'hidden', width: '100%' }}>
                            <Typography
                                variant="body2"
                                component="p"
                                sx={{
                                    opacity: 0.7,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxHeight: '1.5em'
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: info.row.original.multiData[0].notif_text
                                }}
                            />
                            {info.row.original.multiData.length > 1 && (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {numOfUnread > 0 && numOfRead > 0 ? (
                                        <Typography color="textSecondary" sx={{ marginBottom: 0 }}>
                                            {'Changes: '}
                                            <span>{`${info.row.original?.multiData?.length}`}</span>
                                        </Typography>
                                    ) : null}
                                    {numOfUnread > 0 ? (
                                        <Typography color="textSecondary">
                                            {'Unread: '}
                                            <Typography
                                                component={'span'}
                                                sx={{
                                                    color: 'red',
                                                    fontWeight: 700
                                                }}
                                            >{`${numOfUnread}`}</Typography>
                                        </Typography>
                                    ) : null}
                                    {numOfRead > 0 ? (
                                        <Typography color="textSecondary">
                                            {'Read: '}
                                            <Typography
                                                component={'span'}
                                                sx={{
                                                    fontWeight: 700
                                                }}
                                            >{`${numOfRead}`}</Typography>
                                        </Typography>
                                    ) : null}
                                </Box>
                            )}
                        </Stack>
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end', marginLeft: { md: 'auto' }, flex: { xs: 1 } }}>
                            {info.row.original?.multiData.length > 1 ? (
                                <>
                                    {info.row.original?.multiData.length > 0 && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                if (isDrawer) {
                                                    setStep('extended');
                                                    setNotificationId(info.row.original.notifId);
                                                } else {
                                                    setNotificationId(info.row.original.notifId);
                                                    NiceModal.show(ExtendedNotificationDrawer, {
                                                        userId
                                                    });
                                                }
                                            }}
                                        >
                                            View Changes
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <>
                                    {info.row.original.multiData[0].status === true ? (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                changeUserNotificationStatus({
                                                    userId: userId!,
                                                    changeId: info.row.original.multiData[0].id
                                                });
                                            }}
                                        >
                                            Mark As Unread
                                        </Button>
                                    ) : (
                                        <Tooltip
                                            title={
                                                info.row.original.multiData.some((notification) => notification.freeze === 1)
                                                    ? 'Notification cannot be dismissed because it includes freezed entries'
                                                    : undefined
                                            }
                                        >
                                            <Box component={'label'}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    disabled={info.row.original.multiData.some((notification) => notification.freeze === 1)}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        changeUserNotificationStatus({
                                                            userId: userId!,
                                                            changeId: info.row.original.multiData[0].notif_id
                                                        });
                                                    }}
                                                >
                                                    Mark As Read
                                                </Button>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            {viewOrderInDrawer ? (
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ height: '100%' }}
                                    onClick={() => {
                                        NiceModal.show(OrderDrawer, { orderId: info.row.original.postId });
                                    }}
                                >
                                    View Order
                                </Button>
                            ) : (
                                <Link to={to} target="_blank">
                                    <Button variant="contained" size="small" sx={{ height: '100%' }}>
                                        View Order
                                    </Button>
                                </Link>
                            )}
                        </Box>
                    </Box>
                );
            }
        }),
        columnHelper.accessor((row) => row.date, {
            id: 'date',
            sortingFn: (a, b) => {
                const dateA = moment(a.original.date, 'YYYYMMDDHHmmssSSS');
                const dateB = moment(b.original.date, 'YYYYMMDDHHmmssSSS');

                if (dateA.isBefore(dateB)) {
                    return -1;
                } else if (dateA.isAfter(dateB)) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }),
        columnHelper.accessor((row) => <></>, {
            id: 'userId',
            filterFn: (row, columnId, filterValue: string) => row.original.all_users.includes(parseInt(filterValue))
        })
    ];

    const [columnFilters, setColumnFilters] = useImmer<ColumnFiltersState>([]);

    const table = useReactTable({
        data: tableData,
        columns,
        state: {
            // sorting,
            columnFilters
            // columnOrder,
            // columnVisibility
        },
        enableSorting: true,
        enableFilters: true,
        enableColumnFilters: true,
        globalFilterFn: fuzzyFilter,
        // onColumnOrderChange: setColumnOrder,
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // onSortingChange: setSorting,
        getFacetedRowModel: getFacetedRowModel(),
        initialState: {
            columnVisibility: { status: false, type: false, userId: false, date: false },
            columnFilters: [{ id: 'userId', value: '' }]
        }
    });

    return (
        <Box {...props}>
            {filters &&
                (isDrawer ? (
                    <Box sx={{ py: 2, px: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        <DebouncedTextField
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(value) => {
                                table.getColumn('title')?.setFilterValue(value);
                            }}
                            label="Search"
                            disabled={notifications?.users.length === 0}
                            value={table.getColumn('title')?.getFilterValue() as string}
                            size="small"
                        />
                        <TextField
                            size="small"
                            label="Status"
                            onChange={(event) => {
                                setNotificationStatusFilter(event.target.value as NotificationStatus);
                            }}
                            value={notificationStatusFilter}
                            select
                            fullWidth
                        >
                            <MenuItem value={'unread'}>Unread</MenuItem>
                            <MenuItem value={'read'}>Read</MenuItem>
                        </TextField>
                        <TextField
                            size="small"
                            label="From User"
                            onChange={(event) => {
                                if (event.target.value === 'all') table.getColumn('userId')?.setFilterValue('');
                                else table.getColumn('userId')?.setFilterValue(event.target.value.toString());
                            }}
                            value={(table.getColumn('userId')?.getFilterValue()?.toString() as string) || 'all'}
                            disabled={notifications?.users.length === 0}
                            select
                            fullWidth
                        >
                            <MenuItem value={'all'}>All</MenuItem>
                            {notifications &&
                                notifications.users!.map((user) => (
                                    <MenuItem key={user.id} value={user.id.toString()}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                table.resetColumnFilters();
                            }}
                            color="secondary"
                        >
                            Reset filters
                        </Button>
                    </Box>
                ) : (
                    <Grid container sx={{ py: 2, px: 2 }} spacing={2}>
                        <Grid size={{ xs: 12, sm: 3, md: 2 }}>
                            <DebouncedTextField
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(value) => {
                                    table.getColumn('title')?.setFilterValue(value);
                                }}
                                label="Search"
                                value={table.getColumn('title')?.getFilterValue() as string}
                                disabled={tableData.length === 0}
                                size="small"
                            />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3, md: 1 }}>
                            <TextField
                                size="small"
                                label="Status"
                                onChange={(event) => {
                                    setNotificationStatusFilter(event.target.value as NotificationStatus);
                                }}
                                value={notificationStatusFilter}
                                select
                                fullWidth
                            >
                                <MenuItem value={'unread'}>Unread</MenuItem>
                                <MenuItem value={'read'}>Read</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3, md: 1 }}>
                            <TextField
                                size="small"
                                label="From User"
                                onChange={(event) => {
                                    if (event.target.value === 'all') table.getColumn('userId')?.setFilterValue('');
                                    else table.getColumn('userId')?.setFilterValue(event.target.value.toString());
                                }}
                                value={(table.getColumn('userId')?.getFilterValue()?.toString() as string) || 'all'}
                                disabled={tableData.length === 0}
                                select
                                fullWidth
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                {notifications &&
                                    notifications.users!.map((user) => (
                                        <MenuItem key={user.id} value={user.id.toString()}>
                                            {user.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 'auto' }}>
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    sx={{ height: '100%' }}
                                    fullWidth
                                    onClick={() => {
                                        table.resetColumnFilters();
                                    }}
                                    color="secondary"
                                >
                                    Reset filters
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                ))}
            <Stack sx={{ px: 2, gap: 2, flexGrow: 1, overflowY: 'auto', height: '100%' }}>
                {!notifications ? (
                    <Cover />
                ) : table.getRowModel().rows.length > 0 ? (
                    <>
                        {table.getRowModel().rows.map((row) => {
                            if (row.original.multiData) {
                                return (
                                    <Box
                                        key={row.id}
                                        sx={{
                                            display: 'flex',
                                            gap: 2,
                                            width: '100%',
                                            p: 1,
                                            borderBottom: '1px solid #e3e8ef',
                                            flexDirection: { xs: 'column', sm: 'row' }
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <Box
                                                key={cell.id}
                                                sx={{ width: '100%', cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    // Cast e.target to HTMLElement to access tagName
                                                    const targetElement = e.target as HTMLElement;

                                                    // Check if the click occurred inside a button element
                                                    if (targetElement.tagName.toLowerCase() === 'button') {
                                                        return;
                                                    }

                                                    if (isDrawer) {
                                                        setStep('extended');
                                                        //@ts-ignore
                                                        setNotificationId(row.original.notifId);
                                                    } else {
                                                        //@ts-ignore
                                                        setNotificationId(row.original.notifId);
                                                        NiceModal.show(ExtendedNotificationDrawer, {
                                                            userId
                                                        });
                                                    }
                                                }}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Box>
                                        ))}
                                    </Box>
                                );
                            } else {
                                let to = '';
                                switch (row.original.postType) {
                                    case 'sale_opportunity':
                                        to = `/order/${row.original.postId}`;
                                        break;
                                    case 'shipping_order':
                                        to = `/shipping/${row.original.postId}`;
                                        break;
                                }

                                return (
                                    <Box
                                        key={row.id}
                                        sx={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            gap: 2,
                                            width: '100%',
                                            p: 1,
                                            textDecoration: 'none',
                                            borderBottom: '1px solid #e3e8ef',

                                            flexDirection: { xs: 'column', sm: 'row' }
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <Box key={cell.id} sx={{ width: '100%' }}>
                                                    <Link to={to} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </Link>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                );
                            }
                        })}
                    </>
                ) : (
                    <Box>
                        <Typography>No notifications found!</Typography>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export const UserNotificationsDrawer = NiceModal.create(({ userId }: UserNotificationsProps) => {
    const modal = useModal();

    const [step, setStep] = useAtom(notificationStepAtom);
    const [notificationId, setNotificationId] = useAtom(notificationIdAtom);
    const [notificationStatusFilter, setNotificationStatusFilter] = useAtom(notificationStatusFilterAtom);
    const [tableData, setTableData] = useImmer<Notification[]>([]);

    const { data: notifications, isLoading: isNotificationsLoading } = useFetchUserNotificationsQuery({
        userId,
        status: notificationStatusFilter
    });

    useEffect(() => {
        if (!notifications) return;
        setTableData(notifications.notifs);
    }, [notifications]);

    return (
        <Drawer
            open={modal.visible}
            onClose={() => {
                modal.remove();
                setStep(0);
            }}
            anchor="right"
            PaperProps={{
                sx: {
                    width: 600,
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                    overflow: 'hidden'
                }
            }}
        >
            {step === 0 && (
                <DrawerCard title={`Notifications`} onClose={modal.remove}>
                    <UserNotifications userId={userId} isDrawer={true} />
                </DrawerCard>
            )}
            {step === 'extended' && (
                <DrawerCard title={`Notifications`} onClose={modal.remove} onBack={() => setStep(0)}>
                    {notificationId && <ExtendedNotifications userId={userId} handleClose={() => setStep(0)} />}
                </DrawerCard>
            )}
        </Drawer>
    );
});

interface ExtendedNotificationsProps {
    handleClose?: () => void;
    //target
    userId: number;
}

export const ExtendedNotifications = ({ handleClose, userId }: ExtendedNotificationsProps) => {
    const [notificationId, setNotificationId] = useAtom(notificationIdAtom);
    const [notificationStatusFilter, setNotificationStatusFilter] = useAtom(notificationStatusFilterAtom);
    const [tableData, setTableData] = useImmer<Notification[]>([]);
    const navigate = useNavigate();

    const {
        data: notifications,
        isFetching: isNotificationsFetching,
        isLoading: isNotificationsLoading
    } = useFetchUserNotificationsQuery({
        userId: userId,
        status: notificationStatusFilter
    });

    useEffect(() => {
        if (!notifications) return;
        setTableData(notifications.notifs);
    }, [notifications]);
    const [changeUserNotificationStatus] = useChangeNotificationStatusMutation();
    const [changeAllUserNotificationStatus] = useChangeAllUserNotificationStatusMutation();

    const columnHelper = createColumnHelper<MultiNotification>();

    const columns = [
        columnHelper.accessor((row) => row.notif_text, {
            id: 'text',
            cell: (info) => (
                <Stack sx={{}}>
                    <Typography
                        component="p"
                        variant="h5"
                        dangerouslySetInnerHTML={{
                            __html: info.getValue()
                        }}
                    />
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                        {moment(info.row.original.date_readable, 'DD-MM-YYYY HH:mm').format()}{' '}
                        {moment(info.row.original.date_readable, 'DD-MM-YYYY HH:mm').format('HH:mm')}
                    </Typography>

                    {info.row.original.freeze === 1 && (
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'red',
                                    fontWeight: 700
                                }}
                            >
                                Requires Action
                            </Typography>

                            <Tooltip title={'Notification cannot be dismissed before requirements are not fulfilled'}>
                                <IconButton sx={{ p: 0, ml: 0.5 }} size="small">
                                    <Info sx={{ width: 15, height: 15, my: 'auto' }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </Stack>
            )
        }),
        columnHelper.display({
            id: 'controls',
            cell: (info) => {
                return (
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end' }}>
                        {info.row.original.freeze === 1 ? (
                            <>
                                <AnimateButton>
                                    <Button variant="contained" size="small">
                                        Go To Order
                                    </Button>
                                </AnimateButton>
                            </>
                        ) : (
                            <>
                                {info.row.original.status ? (
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                changeUserNotificationStatus({
                                                    userId: userId!,
                                                    changeId: info.row.original.notif_id
                                                });
                                            }}
                                        >
                                            Mark As Unread
                                        </Button>
                                    </AnimateButton>
                                ) : (
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                changeUserNotificationStatus({
                                                    userId: userId,
                                                    changeId: info.row.original.notif_id
                                                }).then((result) => {
                                                    if ('data' in result && result.data!.wholeRead) {
                                                        if (handleClose) handleClose();
                                                    }
                                                });
                                            }}
                                        >
                                            Mark As Read
                                        </Button>
                                    </AnimateButton>
                                )}
                            </>
                        )}
                    </Box>
                );
            }
        })
    ];

    const [data, setData] = useImmer<MultiNotification[]>([]);
    const [notification, setNotification] = useImmer<Notification | undefined>(undefined);

    const table = useReactTable({
        data,
        columns,
        enableSorting: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            columnFilters: [{ id: 'userId', value: '' }]
        }
    });

    useEffect(() => {
        if (!tableData) return;
        setNotification(tableData.find((notification) => notification.notifId === notificationId!)!);
    }, [tableData, notificationId]);

    useEffect(() => {
        if (!notification) return;
        setData(notification?.multiData!);
    }, [notification]);

    return (
        <>
            <Grid container sx={{ py: 2, px: 2 }} spacing={2}>
                {notification && notification!.multiData.some((notif) => notif.freeze) ? (
                    <></>
                ) : (
                    <>
                        {notification && notification!.multiData.some((notif) => notif.status === false) ? (
                            <Grid size={12}>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        sx={{ height: '100%' }}
                                        fullWidth
                                        onClick={() => {
                                            if (!notification) return;
                                            const changeIds = notification?.multiData.map((notification) => notification.id);
                                            changeAllUserNotificationStatus({ changeIds, userId: userId, status: true }).then((result) => {
                                                if (handleClose) handleClose();
                                            });
                                        }}
                                        disabled={!notification?.multiData.some((notification) => !notification.freeze)}
                                        color="secondary"
                                    >
                                        Read All
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        ) : (
                            <Grid size={12}>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        sx={{ height: '100%' }}
                                        fullWidth
                                        onClick={() => {
                                            if (!notification) return;
                                            const changeIds = notification?.multiData.map((notification) => notification.id);
                                            changeAllUserNotificationStatus({ changeIds, userId: userId, status: false }).then((result) => {
                                                if (handleClose) handleClose();
                                            });
                                        }}
                                        color="secondary"
                                    >
                                        Unread All
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            {notification ? (
                <TableContainer>
                    <Table>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => {
                                let to = '';
                                switch (notification.postType) {
                                    case 'sale_opportunity':
                                        to = `/order/${notification.postId}`;
                                        break;
                                    case 'shipping_order':
                                        to = `/shipping/${notification.postId}`;
                                        break;
                                }
                                return (
                                    <TableRow
                                        key={row.id}
                                        sx={{ bgcolor: !row.original.status ? '#EEF2F6' : 'white', textDecoration: 'none' }}
                                        component={Link}
                                        onClick={() => {}}
                                        to={to}
                                        target="_blank"
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Cover />
            )}
        </>
    );
};

export const ExtendedNotificationDrawer = NiceModal.create(({ handleClose, userId }: ExtendedNotificationsProps) => {
    const modal = useModal();
    const { user } = useAuth();

    const [notificationId, setNotificationId] = useAtom(notificationIdAtom);
    const [notificationStatusFilter, setNotificationStatusFilter] = useAtom(notificationStatusFilterAtom);

    const [notification, setNotification] = useImmer<Notification | undefined>(undefined);

    const { data: notifications, isLoading: isNotificationsLoading } = useFetchUserNotificationsQuery({
        userId: userId,
        status: notificationStatusFilter
    });

    useEffect(() => {
        if (!notifications) return;
        setNotification(notifications?.notifs.find((notification) => notification.notifId === notificationId!)!);
    }, [notifications, notificationId]);

    return (
        <Drawer
            open={modal.visible}
            onClose={modal.remove}
            anchor="right"
            PaperProps={{
                sx: {
                    width: 600
                }
            }}
        >
            <DrawerCard title={`${notification?.notif_title} Changes`} onClose={modal.remove}>
                <ExtendedNotifications handleClose={modal.remove} userId={userId} />
            </DrawerCard>
        </Drawer>
    );
});

export default UserNotifications;
