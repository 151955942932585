import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Add, Close, Draw, Edit, Factory, Info, ManageAccounts, PersonAdd, Public, Star, SupervisorAccount } from '@mui/icons-material';
import {
    Autocomplete,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2 as Grid,
    IconButton,
    InputAdornment,
    Paper,
    Popover,
    Skeleton,
    Stack,
    TextareaAutosize,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { intervalToDuration } from 'date-fns';
import { handlePermissionCheck } from 'helpers/global.helper';
import { isUserClient, isUserSuperAdmin } from 'helpers/users.helper';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCreateClientUserMutation, useFetchClientQuery, useFetchClientsQuery } from 'store/api/clients.api';
import { useFetchCalendarInformationQuery } from 'store/api/mounting.api';
import {
    useAssignOrderToFoundShippingMutation,
    useChangeCurrentShippingDateMutation,
    useCreateAndAssignShippingMutation,
    useCreateShippingNotificationMutation,
    useFetchOrderDataQuery,
    useFetchOrderEmployeesQuery,
    useFetchShippingInformationQuery,
    useLazyCheckShippingEventsQuery,
    useLazyCheckShippingSuitableQuery,
    useSaveOrderDataMutation
} from 'store/api/orders.api';
import { useFetchCountriesQuery, useFetchUserFactoriesQuery, useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import { CompanyClient, NaturalPersonClient } from 'types/client.types';
import { Nullable } from 'types/global.types';
import { OrderManage, orderManageSchema } from 'types/orders.types';
import Cover from 'ui-component/Cover';
import AnimateButton from 'ui-component/extended/AnimateButton';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import DebouncedTextField from 'ui-component/inputs/DebouncedTextField';
import DebouncedWysiwyg from 'ui-component/inputs/DebouncedWysiwyg';
import ClientManageModal, { createdClientIdAtom } from 'ui-component/modals/clients/ClientManageModal';
import { dateFormat } from 'utils/data/constant';
import { orderStatuses } from 'utils/data/orders';
import { getMessage } from 'utils/messages';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';
import { z } from 'zod';

const OrderMainDetails = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);
    const [orderMainClientId, setOrderMainClientId] = useAtom(createdClientIdAtom);
    const { watch, control, setValue, reset, getValues, setError } = useForm<Nullable<OrderManage>>({
        defaultValues: {
            orderTitle: '',
            factory: null,
            author: null,
            client: null,
            clientId: null,
            country: null,
            countryId: null,
            customAddress: null,
            deadlineDate: '',
            drawings: null,
            employeeIds: [],
            employees: [],
            factoryId: null,
            mainStatus: '',
            mounting: '',
            onHoldReason: null,
            statusReason: null,
            orderCreated: '',
            orderId: 0,
            orderNum: '',
            orderOwner: '',
            orderParent: null,
            orderParentId: null,
            orderStatus: null,
            ownerInitials: '',
            representative: null,
            shippingAddress: '',
            shippingDate: '',
            shippingId: 0,
            shippingNo: '',
            shippingStatus: null,
            inquiry: '',
            mounting_crews: [],
            mounting_range: [],
            mounting_duration: null
        },
        resolver: zodResolver(orderManageSchema),
        mode: 'all',
        reValidateMode: 'onChange'
    });

    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    const { data: order, isLoading: isOrderLoading } = useFetchOrderDataQuery(
        { orderId: orderId!, userId: user?.id! },
        { skip: !orderId || !user }
    );

    console.log(watch());

    const orderStatus = watch('orderStatus');
    const shippingAddress = watch('shippingAddress');
    const inquiry = watch('inquiry');

    const [isFormInitializing, setIsFormInitializing] = useState(true);

    const { data: clients } = useFetchClientsQuery(null);
    const { data: crews } = useFetchCalendarInformationQuery({ userId: user!.id, calendarId: '' });
    const { data: factories } = useFetchUserFactoriesQuery({ userId: user!.id }, { skip: !user });
    const { data: countries } = useFetchCountriesQuery(null);
    const { data: employees } = useFetchOrderEmployeesQuery(null);
    const { data: shipping } = useFetchShippingInformationQuery({ orderId: orderId! }, { skip: !orderId });

    const [saveOrderData] = useSaveOrderDataMutation();
    const [createClientUser] = useCreateClientUserMutation();
    const [checkShippingEvents] = useLazyCheckShippingEventsQuery();
    const [checkShippingSuitable] = useLazyCheckShippingSuitableQuery();
    const [changeCurrentDate] = useChangeCurrentShippingDateMutation();
    const [createAndAssignShipping] = useCreateAndAssignShippingMutation();
    const [createShippingNotification] = useCreateShippingNotificationMutation();
    const [assignOrderToFoundShipping] = useAssignOrderToFoundShippingMutation();
    const [isOpenShipping, setIsOpenShipping] = useState(false);
    const [isOpenDeadline, setIsOpenDeadline] = useState(false);

    useEffect(() => {
        if (!order || !countries || !factories || !clients) return;
        console.log({ order });
        const country = order.countryId ? countries.find((country) => country.id === order.countryId) : null;
        let factory = null;
        if (factories.length > 0) {
            factory = order.factoryId ? factories.find((factory) => factory.id === order.factoryId) : null;
        } else {
            factory = order.factoryId ? order.factory_object : null;
        }
        const client = order.clientId ? clients.find((client) => client.id === order.clientId) : null;
        const orderStatus = orderStatuses.find((status) => status.id === order.orderStatusId);
        let mounting_range = order.mounting_range;
        if (mounting_range === null && order.mounting_duration) {
            mounting_range = [
                {
                    startDate: new Date(0),
                    endDate: new Date(0),
                    key: 'selection'
                }
            ];
        }

        reset({ ...order, country, factory, client, orderStatus, mounting_range }, { keepDefaultValues: true });
        setIsFormInitializing(false);
    }, [order, countries, factories, clients]);

    const {
        data: client,
        isError: isClientError,
        isLoading: isClientLoading
    } = useFetchClientQuery({ clientId: order?.clientId! }, { skip: !order });

    const [clientInformationString, setClientInformationString] = useState<string | null>(null);

    useEffect(() => {
        if (!client) return;
        if (client.person_or_company === 'natural_person') {
            // @ts-ignore
            const naturalPersonClient: NaturalPersonClient = client;
            setClientInformationString(
                `Client Name: ${naturalPersonClient?.first_name!} ${naturalPersonClient?.last_name!}\nClient Address: ${
                    naturalPersonClient?.person_address! ? naturalPersonClient?.person_address! : 'Unset'
                }\nClient Phone Number: ${
                    naturalPersonClient?.person_phone_number! ? naturalPersonClient?.person_phone_number! : 'Unset'
                }\nClient Postal Code: ${
                    naturalPersonClient?.person_postal_code ? naturalPersonClient?.person_postal_code : 'Unset'
                }\nClient Postal Place: ${naturalPersonClient?.postal_place! ? naturalPersonClient?.postal_place! : 'Unset'}`
            );
        } else {
            // @ts-ignore
            const companyClient: CompanyClient = client;
            setClientInformationString(
                `Company Name: ${companyClient?.company_name ? companyClient.company_name : 'Unset'}\nCompany Address: ${
                    companyClient?.company_address ? companyClient.company_address : 'Unset'
                }\nCompany Phone Number: ${
                    companyClient?.company_mobile_phone_number ? companyClient.company_mobile_phone_number : 'Unset'
                }\nCompany Postal Code: ${
                    companyClient?.company_postal_code ? companyClient?.company_postal_code : 'Unset'
                }\nCompany Postal Place: ${companyClient?.postal_place ? companyClient?.postal_place : 'Unset'}\n\nRepresentatives:\n${
                    companyClient?.representatives?.length! > 0
                        ? companyClient?.representatives?.map(
                              (rep, index) =>
                                  `Name: ${rep.title}\nPhone Number: ${rep.phone_number ? rep.phone_number : 'Unset'}${
                                      companyClient.representatives?.length! - 1 !== index && '\n'
                                  }`
                          )
                        : `None`
                }`
            );
        }
    }, [client]);

    const handleUpdateValue = ({ name, value, notification = true }: { name: string; value: any; notification?: boolean }) => {
        if (!orderId) return;

        if (notification)
            toast.promise(
                saveOrderData({ name, value, orderId: orderId, userId: user?.id! }),
                {
                    pending: getMessage('updatePending'),
                    error: getMessage('updateError'),
                    success: getMessage('updated')
                },
                { autoClose: 1000 }
            );
        else saveOrderData({ name, value, orderId: orderId, userId: user?.id! });
    };

    const employeeChipRender = ({ option }: { option: any }) => {
        let icon;

        switch (option.role) {
            case 'super_admin':
                icon = <Star fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'manager':
                icon = <SupervisorAccount fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'country_manager':
                icon = <Public fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'factory_manager':
                icon = <Factory fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'designer':
                icon = <Draw fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'designer_admin':
                icon = <Draw fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            default:
                icon = null;
        }

        return (
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 !important',
                    alignItems: 'center'
                }}
            >
                {icon}
                <Container
                    sx={{
                        padding: '0 !important'
                    }}
                >
                    <Typography>{option.username}</Typography>
                    {option.role === 'factory_manager' && (
                        <Typography sx={{ fontSize: '9px', color: '#3f51b5', textAlign: 'center', marginTop: '-5px' }}>
                            {option.factories}
                        </Typography>
                    )}
                </Container>
            </Container>
        );
    };

    const employeeListRender = ({ props, option, state }: { option: any; props: object; state: object }) => {
        let icon;

        switch (option.role) {
            case 'super_admin':
                icon = <Star fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'manager':
                icon = <SupervisorAccount fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'country_manager':
                icon = <Public fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'factory_manager':
                icon = <Factory fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'designer':
                icon = <Draw fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            case 'designer_admin':
                icon = <Draw fontSize="small" sx={{ marginRight: '8px' }} />;
                break;
            default:
                icon = null;
        }

        return (
            <Container
                {...props}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                {icon}
                <Container
                    sx={{
                        paddingLeft: '8px !important'
                    }}
                >
                    <Typography>{option.username}</Typography>
                    {option.role === 'factory_manager' && (
                        <Typography sx={{ fontSize: '9px', color: '#3f51b5', marginTop: '-3px' }}>{option.factories}</Typography>
                    )}
                </Container>
            </Container>
        );
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // useEffect(() => {
    //     if (!clients) return;

    //     if (firstFetch === true) {
    //         setFirstFetch(false);
    //         return;
    //     } else {
    //         setValue('client', clients[clients.length - 1]);
    //         handleUpdateValue({ name: 'client', value: clients[clients.length - 1] });
    //     }
    // }, [clients]);

    useEffect(() => {
        if (!clients || !orderMainClientId) return;
        const client = clients.find((client) => client.id === orderMainClientId);
        if (!client) return;

        setValue('client', client);
        handleUpdateValue({ name: 'client', value: client, notification: false });
        setOrderMainClientId(null);
    }, [orderMainClientId, clients]);

    const parseInput = ({ name, value }: { name: keyof typeof orderManageSchema.shape; value: any }) =>
        orderManageSchema.shape[name].safeParse(value).success;

    if (isFormInitializing || !orderId) return <Cover sx={{ minHeight: 400 }} />;

    return (
        <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
            <Grid container spacing={2} sx={{ flex: '0.50', px: { xs: 2, md: 0 } }}>
                {handlePermissionCheck(userFieldsVisibility!, 'orderTitle') && (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <InputLabel>Order Title</InputLabel>
                        <Controller
                            control={control}
                            name="orderTitle"
                            render={({ field, fieldState: { error } }) => (
                                <DebouncedTextField
                                    {...field}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                    }}
                                    debounce={1500}
                                    placeholder="Big big windows"
                                    disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                    size="small"
                                    fullWidth
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Grid>
                )}
                {handlePermissionCheck(userFieldsVisibility!, 'orderStatus') && (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <InputLabel>Status</InputLabel>
                        <Controller
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <Autocomplete
                                    {...field}
                                    value={field.value}
                                    onChange={(event, value) => {
                                        field.onChange(value);
                                        parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                    }}
                                    disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                    options={orderStatuses}
                                    getOptionLabel={(option) => option.text}
                                    isOptionEqualToValue={(option) => option === field.value}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" fullWidth error={!!error} helperText={error?.message} />
                                    )}
                                    getOptionDisabled={(option) => {
                                        if (getValues('orderType') === 'related') {
                                            return (
                                                option.id === 'confirmed' ||
                                                option.id === 'on_hold' ||
                                                option.id === 'request' ||
                                                option.id === 'passive_request' ||
                                                option.id === 'cancelled'
                                            );
                                        } else {
                                            return option.id === 'supplier_confirmed' || option.id === 'supplier_request';
                                        }
                                    }}
                                />
                            )}
                            name="orderStatus"
                        />
                    </Grid>
                )}
                {handlePermissionCheck(userFieldsVisibility!, 'shippingAddress') && orderStatus?.id === 'confirmed' && (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <InputLabel>Shipping Address</InputLabel>
                        <Controller
                            control={control}
                            name="shippingAddress"
                            render={({ field }) => (
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    sx={{ width: '100%', whiteSpace: 'nowrap' }}
                                    value={field.value}
                                    disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                    onChange={(event, value) => {
                                        field.onChange(value);
                                        parseInput({ name: field.name, value }) && handleUpdateValue({ value, name: field.name });
                                    }}
                                >
                                    <ToggleButton sx={{ width: '100%' }} fullWidth value="storage" size="small">
                                        Storage
                                    </ToggleButton>
                                    <ToggleButton sx={{ width: '100%' }} fullWidth value="client" size="small">
                                        Client
                                    </ToggleButton>
                                    <ToggleButton sx={{ width: '100%' }} fullWidth value="custom" size="small">
                                        Custom
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            )}
                        />
                        {watch('shippingAddress') === 'client' && client && (
                            <Box
                                component={TextareaAutosize}
                                disabled={true}
                                value={clientInformationString ? clientInformationString!.split(',').join('') : ''}
                                minRows={5}
                                maxRows={5}
                                sx={{ width: '100%', mt: 1 }}
                            />
                        )}
                    </Grid>
                )}
                {handlePermissionCheck(userFieldsVisibility!, 'onHoldReason') && orderStatus?.id === 'on_hold' && (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <InputLabel>On Hold Reason</InputLabel>
                        <Controller
                            control={control}
                            name="onHoldReason"
                            render={({ field, fieldState: { error } }) => (
                                <DebouncedTextField
                                    {...field}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                    }}
                                    debounce={1500}
                                    disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                    placeholder="Logistics problems"
                                    size="small"
                                    fullWidth
                                    helperText={error?.message}
                                    error={!!error}
                                />
                            )}
                        />
                    </Grid>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'onHoldReason') &&
                    (orderStatus?.id === 'cancelled' || orderStatus?.id === 'passive_request') && (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel>Status Reason</InputLabel>
                            <Controller
                                control={control}
                                name="statusReason"
                                render={({ field, fieldState: { error } }) => (
                                    <DebouncedTextField
                                        {...field}
                                        onChange={(value) => {
                                            field.onChange(value);
                                            parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                        }}
                                        debounce={1500}
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        placeholder="Package mismatch"
                                        size="small"
                                        fullWidth
                                        helperText={error?.message}
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                {handlePermissionCheck(userFieldsVisibility!, 'customAddress') &&
                    orderStatus?.id === 'confirmed' &&
                    shippingAddress === 'custom' && (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel>Custom Shipping Address</InputLabel>
                            <Controller
                                control={control}
                                name="customAddress"
                                render={({ field, fieldState: { error } }) => (
                                    <DebouncedTextField
                                        {...field}
                                        onChange={(value) => {
                                            field.onChange(value);
                                            parseInput({ name: field.name, value: value }) &&
                                                handleUpdateValue({ name: field.name, value });
                                        }}
                                        debounce={1500}
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        multiline
                                        placeholder="London"
                                        size="small"
                                        error={!!error}
                                        helperText={error?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    )}

                {handlePermissionCheck(userFieldsVisibility!, 'client') &&
                    (clients ? (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel>Client</InputLabel>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Controller
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <>
                                            {/* @ts-ignore */}
                                            <Autocomplete
                                                {...field}
                                                options={clients!}
                                                sx={{ width: '100%' }}
                                                getOptionLabel={(option) => option.title}
                                                disableClearable
                                                disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                                onChange={(event, value) => {
                                                    field.onChange(value);
                                                    setValue('clientId', value!.id);
                                                    parseInput({ name: field.name, value }) &&
                                                        handleUpdateValue({ name: field.name, value });
                                                }}
                                                renderInput={(options) => (
                                                    <TextField
                                                        {...options}
                                                        size="small"
                                                        fullWidth
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                            />
                                            {!isUserClient(user?.role!) && (
                                                <>
                                                    <Box
                                                        bgcolor={'lightblue'}
                                                        sx={{ display: 'flex', alignItems: 'center', borderRadius: 2 }}
                                                    >
                                                        <Tooltip title="Edit client">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    NiceModal.show(ClientManageModal, { clientId: field.value?.id })
                                                                }
                                                                disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                    <Box
                                                        bgcolor={'lightgreen'}
                                                        sx={{ display: 'flex', alignItems: 'center', borderRadius: 2 }}
                                                    >
                                                        <Tooltip title="Create new client">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => NiceModal.show(ClientManageModal)}
                                                                disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                    {isUserSuperAdmin(user?.role!) && (
                                                        <Box
                                                            bgcolor={'gold'}
                                                            sx={{ display: 'flex', alignItems: 'center', borderRadius: 2 }}
                                                        >
                                                            {field.value?.clientId === null ? (
                                                                <Tooltip
                                                                    title={
                                                                        field.value.email_address === null ||
                                                                        field.value.email_address === ''
                                                                            ? 'Creating a client account requires assigned email address'
                                                                            : 'Create new client account'
                                                                    }
                                                                >
                                                                    <label>
                                                                        <IconButton
                                                                            size="small"
                                                                            disabled={
                                                                                field.value.email_address === null ||
                                                                                field.value.email_address === ''
                                                                            }
                                                                            onClick={() => {
                                                                                if (!field.value) return;
                                                                                Swal.fire({
                                                                                    title: `Are you sure you want to create an account for user ${field.value.title}?`,
                                                                                    text: 'Once created, it is irreversible, and an email with login credentials will be sent.',
                                                                                    icon: 'warning',
                                                                                    showCancelButton: true
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        toast.promise(
                                                                                            createClientUser({ clientId: field.value!.id }),
                                                                                            {
                                                                                                success: getMessage('created'),
                                                                                                pending: getMessage('createPending')
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                });
                                                                            }}
                                                                        >
                                                                            <PersonAdd />
                                                                        </IconButton>
                                                                    </label>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Manage client account">
                                                                    <Link to={`/user/manage/${field.value!.clientId}`} target={'_blank'}>
                                                                        <IconButton size="small">
                                                                            <ManageAccounts />
                                                                        </IconButton>
                                                                    </Link>
                                                                </Tooltip>
                                                            )}
                                                        </Box>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                    name="client"
                                />
                            </Box>
                        </Grid>
                    ) : (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Skeleton width={'100%'} height={40} />
                        </Grid>
                    ))}

                {handlePermissionCheck(userFieldsVisibility!, 'factory') &&
                    (factories || order?.factory_object! ? (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel>Factory</InputLabel>
                            <Controller
                                control={control}
                                name="factory"
                                render={({ field, fieldState: { error } }) => (
                                    //@ts-ignore
                                    <Autocomplete
                                        {...field}
                                        options={factories || [order?.factory_object!]}
                                        disableClearable
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        onChange={(event, value) => {
                                            const oldValue = watch('factory');
                                            field.onChange(value);
                                            setValue('factoryId', value!.id);
                                            parseInput({ name: field.name, value }) &&
                                                Swal.fire({
                                                    title: 'Are you sure?',
                                                    text: 'Changing factory leads to minor order changes and it cannot be undone',
                                                    icon: 'info',
                                                    showCancelButton: true
                                                }).then(({ isConfirmed }) => {
                                                    if (isConfirmed) handleUpdateValue({ name: field.name, value: value });
                                                    else {
                                                        setValue('factory', oldValue);
                                                        setValue('factoryId', oldValue ? oldValue.id : null);
                                                    }
                                                });
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(options) => (
                                            <TextField {...options} size="small" fullWidth error={!!error} helperText={error?.message} />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    ) : (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Skeleton width={'100%'} height={40} />
                        </Grid>
                    ))}

                {handlePermissionCheck(userFieldsVisibility!, 'mounting') && (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <InputLabel>Mounting</InputLabel>
                        <Controller
                            control={control}
                            name="mounting"
                            render={({ field }) => (
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        {...field}
                                        exclusive
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        onChange={(event, value) => {
                                            field.onChange(value);
                                            parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                        }}
                                        sx={{ width: '100%', whiteSpace: 'nowrap' }}
                                    >
                                        <ToggleButton sx={{ width: '100%' }} fullWidth value="supply" size="small">
                                            Supply Only
                                        </ToggleButton>
                                        <ToggleButton sx={{ width: '100%' }} fullWidth value="mounting" size="small">
                                            Mounting by us
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            )}
                        />
                    </Grid>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'employees') &&
                    (employees ? (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel sx={{ display: 'flex', alignItems: 'center' }}>
                                Owners
                                <Tooltip title='Owners have additional responsibilities for the order. They receive "action required" notifications to address specific tasks or issues. Note: Owners must be part of the Employees list; adding someone as an Owner automatically includes them as an Employee.'>
                                    <Info
                                        sx={{
                                            width: 14,
                                            height: 14,
                                            cursor: 'pointer',
                                            ml: 0.5,
                                            '&:hover': {
                                                transform: 'scale(1.2)'
                                            }
                                        }}
                                    />
                                </Tooltip>
                            </InputLabel>
                            <Controller
                                control={control}
                                name="owners"
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        multiple
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        filterSelectedOptions={true}
                                        disableClearable={true}
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        {...field}
                                        value={field.value || undefined}
                                        options={employees}
                                        groupBy={(option) => option.readable_role}
                                        onChange={(event, value) => {
                                            field.onChange(value);
                                            parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                        }}
                                        getOptionLabel={(option) => option.username}
                                        renderOption={(props: object, option: any, state: object) =>
                                            employeeListRender({ props, option, state })
                                        }
                                        renderInput={(options) => (
                                            <TextField {...options} fullWidth size="small" error={!!error} helperText={error?.message} />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        variant="filled"
                                                        label={employeeChipRender({ option })}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    ) : (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Skeleton width={'100%'} height={40} />
                        </Grid>
                    ))}

                {handlePermissionCheck(userFieldsVisibility!, 'employees') &&
                    (employees ? (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel sx={{ display: 'flex', alignItems: 'center' }}>
                                Employees
                                <Tooltip title="Adding a user to the Employees list grants them access to this order. Employees can view, edit, and participate in the order chat, and they receive standard notifications about updates.">
                                    <Info
                                        sx={{
                                            width: 14,
                                            height: 14,
                                            cursor: 'pointer',
                                            ml: 0.5,
                                            '&:hover': {
                                                transform: 'scale(1.2)'
                                            }
                                        }}
                                    />
                                </Tooltip>
                            </InputLabel>
                            <Controller
                                control={control}
                                name="employees"
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        multiple
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        filterSelectedOptions={true}
                                        disableClearable={true}
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        {...field}
                                        value={field.value || undefined}
                                        options={employees}
                                        groupBy={(option) => option.readable_role}
                                        onChange={(event, value) => {
                                            field.onChange(value);
                                            parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                        }}
                                        getOptionLabel={(option) => option.username}
                                        renderOption={(props: object, option: any, state: object) =>
                                            employeeListRender({ props, option, state })
                                        }
                                        renderInput={(options) => (
                                            <TextField {...options} fullWidth size="small" error={!!error} helperText={error?.message} />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        variant="filled"
                                                        label={employeeChipRender({ option })}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    ) : (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Skeleton width={'100%'} height={40} />
                        </Grid>
                    ))}

                {handlePermissionCheck(userFieldsVisibility!, 'country') &&
                    (countries ? (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel>Country</InputLabel>
                            <Controller
                                control={control}
                                name="country"
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        options={countries}
                                        {...field}
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        onChange={async (event, value) => {
                                            const oldValue = watch().country;
                                            const shippingValue = moment(watch().shippingDate);
                                            let shouldContinue = true;
                                            const resetValues = () => {
                                                setValue('country', oldValue);
                                                shouldContinue = false;
                                                return;
                                            };
                                            field.onChange(value);

                                            let isPartial =
                                                shipping?.status?.value === 'en_route' || shipping?.status?.value === 'delivered'
                                                    ? true
                                                    : false;

                                            console.log({ shippingValue });
                                            console.log(shippingValue.isValid());
                                            if (shippingValue.isValid()) {
                                                if (parseInput({ name: field.name, value })) {
                                                    Swal.fire({
                                                        title: `Changing country will affect shipping, are you sure?`,
                                                        icon: 'question',
                                                        showCloseButton: true,
                                                        showCancelButton: true,
                                                        showConfirmButton: true,
                                                        confirmButtonText: 'Yes',
                                                        cancelButtonText: 'No'
                                                    }).then(async ({ isConfirmed }) => {
                                                        handleUpdateValue({ name: field.name, value });
                                                        if (isConfirmed) {
                                                            const shippingSuitable = await checkShippingSuitable({
                                                                orderId: orderId!,
                                                                selectedDate: shippingValue!
                                                            });

                                                            if (shippingSuitable.isSuccess) {
                                                                const result = shippingSuitable.data;

                                                                if (!shouldContinue) return;
                                                                // Check for key 'transfer' in request
                                                                if ('transfer' in result) {
                                                                    // Suitable shipping found
                                                                    if (result.transfer) {
                                                                        // Transfer order and inform user
                                                                        assignOrderToFoundShipping({
                                                                            selectedDate: shippingValue!,
                                                                            isPartial,
                                                                            orderId: orderId!,
                                                                            shipId: result.shippingID!,
                                                                            userId: user?.id!
                                                                        }).then(() => {
                                                                            createShippingNotification({
                                                                                orderId: orderId,
                                                                                // @ts-ignore
                                                                                selectedDate: moment(result.selected_date),
                                                                                // @ts-ignore
                                                                                shippingId: result.shippingID,
                                                                                userId: user?.id!
                                                                            });
                                                                            toast.success(getMessage('edited'));
                                                                        });
                                                                    } else {
                                                                        // Inform user that order is already in shipping order for that date
                                                                        resetValues();
                                                                    }
                                                                } else if ('new' in result) {
                                                                    // Create new shipping order and assign order to it
                                                                    createAndAssignShipping({
                                                                        country: result.country!,
                                                                        isPartial,
                                                                        orderId: orderId,
                                                                        region: result.region!,
                                                                        selectedDate: moment(result.selDate),
                                                                        userId: user?.id!
                                                                    }).then((createAssignRes) => {
                                                                        if ('data' in createAssignRes) {
                                                                            createShippingNotification({
                                                                                orderId: orderId,
                                                                                selectedDate: moment(result.selDate),
                                                                                shippingId: createAssignRes.data.ship_id,
                                                                                userId: user?.id!
                                                                            });
                                                                        }
                                                                    });
                                                                } else if ('changeDate' in result && result.changeDate === true) {
                                                                    changeCurrentDate({
                                                                        orderId,
                                                                        selectedDate: shippingValue!,
                                                                        reason: getValues('changeReason')!,
                                                                        shippingId: watch('shippingId')!,
                                                                        userId: user!.id
                                                                    });
                                                                    changeCurrentDate({
                                                                        selectedDate: moment(result.selDate),
                                                                        shippingId: result.shippingID,
                                                                        orderId: orderId,
                                                                        userId: user?.id!
                                                                    }).then((changeDateResult) => {
                                                                        // Check for error
                                                                        if (changeDateResult && 'error' in changeDateResult) {
                                                                            resetValues();
                                                                            toast.error(getMessage('editError'), { autoClose: 1000 });
                                                                        }
                                                                    });
                                                                }
                                                            } else {
                                                                resetValues();
                                                            }
                                                        } else {
                                                            resetValues();
                                                        }
                                                    });
                                                }
                                            } else
                                                parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        isOptionEqualToValue={(option) => option.id === field.value!.id}
                                        renderInput={(options) => (
                                            <TextField {...options} fullWidth size="small" error={!!error} helperText={error?.message} />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    ) : (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Skeleton width={'100%'} height={40} />
                        </Grid>
                    ))}

                {handlePermissionCheck(userFieldsVisibility!, 'deadlineDate') && (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <InputLabel>Deadline Date</InputLabel>
                        <Controller
                            control={control}
                            name="deadlineDate"
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    value={moment(field.value)}
                                    format={dateFormat}
                                    disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        parseInput({ name: field.name, value }) &&
                                            handleUpdateValue({ name: field.name, value: value?.format('MM/DD/YYYY') });
                                    }}
                                    open={isOpenDeadline}
                                    onClose={() => setIsOpenDeadline(false)}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                            onClick: () => setIsOpenDeadline(true)
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                )}

                {handlePermissionCheck(userFieldsVisibility!, 'shippingDate') &&
                    (orderStatus?.id === 'confirmed' || orderStatus?.id === 'supplier_confirmed') && (
                        <Grid size={{ xs: 12, md: 6 }}>
                            <InputLabel>
                                Shipping Date
                                {watch().changeReason && (
                                    <Tooltip title={watch().changeReason}>
                                        <IconButton sx={{ p: 0, ml: 1 }} size="small">
                                            <Info sx={{ width: 15, height: 15, my: 'auto' }} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </InputLabel>
                            <Controller
                                control={control}
                                name="shippingDate"
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        {...field}
                                        format={dateFormat}
                                        value={moment(field.value)}
                                        disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                        onChange={async (value) => {
                                            const oldValue = watch().shippingDate;
                                            let shouldContinue = true;
                                            const resetValues = () => {
                                                setValue('shippingDate', oldValue);
                                                shouldContinue = false;
                                                return;
                                            };
                                            field.onChange(value);

                                            let isPartial =
                                                shipping?.status?.value === 'en_route' || shipping?.status?.value === 'delivered'
                                                    ? true
                                                    : false;

                                            if (parseInput({ name: field.name, value })) {
                                                Swal.fire({
                                                    title: `Is region ${watch().factory?.abbr} correct?`,
                                                    icon: 'question',
                                                    showCloseButton: true,
                                                    showCancelButton: true,
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'Yes',
                                                    cancelButtonText: 'No'
                                                }).then(async ({ isConfirmed }) => {
                                                    if (isConfirmed) {
                                                        const shippingEvents = await checkShippingEvents({
                                                            orderId: orderId,
                                                            selectedDate: value!
                                                        });

                                                        if (
                                                            shippingEvents &&
                                                            shippingEvents.data === 'There is a mounting event before shipping date!'
                                                        )
                                                            await Swal.fire({
                                                                icon: 'warning',
                                                                title: 'There is a mounting event before shipping date!',
                                                                text: '',
                                                                showCloseButton: false,
                                                                showConfirmButton: true,
                                                                showCancelButton: true
                                                            }).then((result) => {
                                                                if (result.isDenied || result.isDismissed || !result.isConfirmed) {
                                                                    resetValues();
                                                                }
                                                            });

                                                        if (!shouldContinue) return;

                                                        const shippingSuitable = await checkShippingSuitable({
                                                            orderId: orderId!,
                                                            selectedDate: value!
                                                        });

                                                        if (shippingSuitable.isSuccess) {
                                                            const result = shippingSuitable.data;

                                                            if (
                                                                moment(order?.shippingDate) !== moment(value) &&
                                                                order?.shippingDate !== null
                                                            ) {
                                                                await NiceModal.show(shippingReasonChangeDialog, {
                                                                    submit: ({ isConfirmed, reason }) => {
                                                                        if (isConfirmed) {
                                                                            setValue('changeReason', reason);
                                                                        } else {
                                                                            resetValues();
                                                                        }
                                                                    }
                                                                });
                                                            }

                                                            if (!shouldContinue) return;
                                                            // Check for key 'transfer' in request
                                                            if ('transfer' in result) {
                                                                // Suitable shipping found
                                                                if (result.transfer) {
                                                                    // Transfer order and inform user
                                                                    assignOrderToFoundShipping({
                                                                        selectedDate: value!,
                                                                        isPartial,
                                                                        orderId: orderId!,
                                                                        shipId: result.shippingID!,
                                                                        userId: user?.id!
                                                                    }).then(() => {
                                                                        createShippingNotification({
                                                                            orderId: orderId,
                                                                            // @ts-ignore
                                                                            selectedDate: moment(result.selected_date),
                                                                            // @ts-ignore
                                                                            shippingId: result.shippingID,
                                                                            userId: user?.id!
                                                                        });
                                                                        toast.success(getMessage('edited'));
                                                                    });
                                                                } else {
                                                                    // Inform user that order is already in shipping order for that date
                                                                    resetValues();
                                                                    toast.info('Shipping order found, aborting', { autoClose: 1000 });
                                                                }
                                                            } else if ('new' in result) {
                                                                // Create new shipping order and assign order to it
                                                                createAndAssignShipping({
                                                                    country: result.country!,
                                                                    isPartial,
                                                                    orderId: orderId,
                                                                    region: result.region!,
                                                                    selectedDate: moment(result.selDate),
                                                                    userId: user?.id!
                                                                }).then((createAssignRes) => {
                                                                    if ('data' in createAssignRes) {
                                                                        createShippingNotification({
                                                                            orderId: orderId,
                                                                            selectedDate: moment(result.selDate),
                                                                            shippingId: createAssignRes.data.ship_id,
                                                                            userId: user?.id!
                                                                        });
                                                                        toast.success('Request assigned successfully', { autoClose: 1000 });
                                                                    }
                                                                });
                                                            } else if ('changeDate' in result && result.changeDate === true) {
                                                                changeCurrentDate({
                                                                    orderId,
                                                                    selectedDate: value!,
                                                                    reason: getValues('changeReason')!,
                                                                    shippingId: watch('shippingId')!,
                                                                    userId: user!.id
                                                                });
                                                                changeCurrentDate({
                                                                    selectedDate: moment(result.selDate),
                                                                    shippingId: result.shippingID,
                                                                    orderId: orderId,
                                                                    userId: user?.id!
                                                                }).then((changeDateResult) => {
                                                                    // Check for error
                                                                    if (changeDateResult && 'error' in changeDateResult) {
                                                                        resetValues();
                                                                        toast.error(getMessage('editError'), { autoClose: 1000 });
                                                                    } else {
                                                                        toast.success('Date change complete', { autoClose: 1000 });
                                                                    }
                                                                });
                                                            }
                                                        } else {
                                                            resetValues();
                                                        }
                                                    } else {
                                                        resetValues();
                                                    }
                                                });
                                            }
                                        }}
                                        shouldDisableDate={(date: Moment) => {
                                            const day = date.day();
                                            return day !== 2 && day !== 5;
                                        }}
                                        open={isOpenShipping}
                                        onClose={() => setIsOpenShipping(false)}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                                onClick: () => setIsOpenShipping(true)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                {isUserSuperAdmin(user?.role!) && watch('client')?.clientId !== null && (
                    <Grid size={{ xs: 12 }}>
                        <Stack gap={2} component={Paper} elevation={4} sx={{ p: 1 }}>
                            {crews ? (
                                <Box>
                                    <InputLabel>Mounting crews</InputLabel>
                                    <Controller
                                        control={control}
                                        name="mounting_crews"
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                multiple
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                filterSelectedOptions={true}
                                                disableClearable={true}
                                                {...field}
                                                value={field.value || undefined}
                                                options={cloneDeep(crews)
                                                    .filter((crew) =>
                                                        field.value && field.value[0]
                                                            ? crew.start_of_week === field.value[0].start_of_week
                                                            : true
                                                    )
                                                    .sort((a, b) => (parseInt(a.start_of_week) > parseInt(b.start_of_week) ? -1 : 1))}
                                                onChange={(event, value) => {
                                                    field.onChange(value);
                                                    parseInput({ name: field.name, value }) &&
                                                        handleUpdateValue({ name: field.name, value });
                                                }}
                                                getOptionLabel={(option) => option.crew_name}
                                                groupBy={(option) => {
                                                    if (option.start_of_week === '1') return 'Monday';
                                                    else if (option.start_of_week === '2') return 'Tuesday';
                                                    else if (option.start_of_week === '3') return 'Wednesday';
                                                    else if (option.start_of_week === '4') return 'Thursday';
                                                    else if (option.start_of_week === '5') return 'Friday';
                                                    else if (option.start_of_week === '6') return 'Saturday';
                                                    else if (option.start_of_week === '0') return 'Sunday';
                                                    else return 'Unknown';
                                                }}
                                                renderInput={(options) => (
                                                    <TextField
                                                        {...options}
                                                        fullWidth
                                                        size="small"
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => {
                                                        return (
                                                            <Chip variant="filled" label={option.crew_name} {...getTagProps({ index })} />
                                                        );
                                                    })
                                                }
                                            />
                                        )}
                                    />
                                </Box>
                            ) : (
                                <Box>
                                    <Skeleton width={'100%'} height={40} />
                                </Box>
                            )}

                            <Box>
                                <InputLabel>Mounting duration (days)</InputLabel>
                                <Controller
                                    control={control}
                                    name="mounting_duration"
                                    render={({ field, fieldState: { error } }) => (
                                        <DebouncedTextField
                                            {...field}
                                            value={field.value}
                                            onChange={(value) => {
                                                const mountingDuration = value ? parseInt(value! as string) : null;
                                                if (mountingDuration === null) {
                                                    setError('mounting_duration', {
                                                        message: 'Value unsaved, clear the value using button'
                                                    });

                                                    return;
                                                }
                                                const mountingRange = getValues('mounting_range');

                                                if (mountingRange && mountingRange.length > 0) {
                                                    const valueMountingDuration = intervalToDuration({
                                                        start: mountingRange[0].startDate,
                                                        end: mountingRange[0].endDate
                                                    }).days!;
                                                    if (
                                                        mountingDuration !== null &&
                                                        valueMountingDuration !== null &&
                                                        valueMountingDuration < mountingDuration - 1
                                                    ) {
                                                        setError('mounting_duration', {
                                                            message: 'Duration cannot be less than seleted range'
                                                        });

                                                        return;
                                                    }
                                                }
                                                parseInput({ name: field.name, value: mountingDuration }) &&
                                                    handleUpdateValue({ name: field.name, value: mountingDuration });
                                            }}
                                            debounce={1500}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            sx={{ p: 0 }}
                                                            onClick={() => {
                                                                parseInput({ name: field.name, value: null }) &&
                                                                    handleUpdateValue({ name: field.name, value: null });
                                                            }}
                                                        >
                                                            <Close fontSize="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            sx={{
                                                'input::-webkit-inner-spin-button': {
                                                    '-webkit-appearance': 'none',
                                                    margin: 0
                                                }
                                            }}
                                            size="small"
                                            type="number"
                                            error={!!error}
                                            helperText={error?.message}
                                            fullWidth={true}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Tooltip title={!watch('mounting_duration') ? 'Mounting duration is not set' : null}>
                                    <label>
                                        <Button
                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                                            variant="contained"
                                            disabled={!watch('mounting_duration')}
                                        >
                                            Date Range Selection
                                        </Button>
                                    </label>
                                </Tooltip>
                                <Controller
                                    control={control}
                                    name="mounting_range"
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <>
                                            {value !== null &&
                                                (!!error ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            gap: 0.5,
                                                            flexDirection: 'column',
                                                            ml: 'auto'
                                                        }}
                                                    >
                                                        <Typography variant="h5" color={'red'} sx={{ whiteSpace: 'nowrap' }}>
                                                            {error.message}
                                                        </Typography>
                                                        <Typography>Value not saved</Typography>
                                                    </Box>
                                                ) : (
                                                    <>
                                                        {value &&
                                                            value.length > 0 &&
                                                            value[0].startDate &&
                                                            moment(value[0].startDate).unix() !== moment(0).unix() &&
                                                            value[0].endDate && (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ml: 'auto' }}>
                                                                    From{' '}
                                                                    <Typography variant="h5">
                                                                        {' '}
                                                                        {moment(value[0].startDate).format()}
                                                                    </Typography>
                                                                    until{' '}
                                                                    <Typography variant="h5">
                                                                        {' '}
                                                                        {moment(value[0].endDate).format()}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                    </>
                                                ))}
                                        </>
                                    )}
                                />
                            </Box>

                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                            >
                                <Box>
                                    <Controller
                                        control={control}
                                        name="mounting_range"
                                        render={({ field, fieldState: { error } }) => (
                                            <DateRange
                                                onChange={(item: any) => {
                                                    const value = [item.selection];

                                                    const mountingDuration = getValues('mounting_duration')
                                                        ? getValues('mounting_duration')!
                                                        : null;
                                                    const valueMountingDuration = intervalToDuration({
                                                        start: value[0].startDate,
                                                        end: value[0].endDate
                                                    }).days;

                                                    if (
                                                        mountingDuration &&
                                                        valueMountingDuration &&
                                                        valueMountingDuration < mountingDuration - 1
                                                    ) {
                                                        setError('mounting_range', {
                                                            message: 'Range cannot be less than selected duration'
                                                        });
                                                        // Reset to old value;
                                                        setValue('mounting_range', order?.mounting_range!);
                                                        return;
                                                    }

                                                    field.onChange(value);
                                                    //Probhites one day selection, forces to chose range above one day.
                                                    if (
                                                        new Date(item.selection.startDate).getTime() ===
                                                        new Date(item.selection.endDate).getTime()
                                                    )
                                                        return;

                                                    parseInput({ name: field.name, value }) &&
                                                        handleUpdateValue({ name: field.name, value });
                                                }}
                                                months={1}
                                                shownDate={new Date()}
                                                ranges={field.value !== null ? field.value : []}
                                                direction="horizontal"
                                            />
                                        )}
                                    />
                                </Box>
                            </Popover>
                        </Stack>
                    </Grid>
                )}
            </Grid>

            {handlePermissionCheck(userFieldsVisibility!, 'inquiry') && inquiry !== undefined && (
                <Box
                    sx={{ padding: 0, width: { xs: 'unset', md: 'calc(100% + 24px)' }, flex: '0.50', minHeight: 200, mx: { xs: 2, md: 0 } }}
                >
                    <Controller
                        name="inquiry"
                        control={control}
                        render={({ field }) => (
                            <DebouncedWysiwyg
                                {...field}
                                value={field.value!}
                                debounce={1500}
                                disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                onChange={(value) => {
                                    field.onChange(value);
                                    parseInput({ name: field.name, value }) && handleUpdateValue({ name: field.name, value });
                                }}
                            />
                        )}
                    />
                </Box>
            )}
        </Box>
    );
};

const shippingReasonChangeDialogSchema = z.object({
    reason: z.string().min(1),
    isConfirmed: z.boolean()
});

type ShippingReasonChangeDialog = z.infer<typeof shippingReasonChangeDialogSchema>;

const shippingReasonChangeDialog = NiceModal.create(({ submit }: { submit: (data: ShippingReasonChangeDialog) => void }) => {
    const modal = useModal();

    const { control, handleSubmit } = useForm<ShippingReasonChangeDialog>({
        resolver: zodResolver(shippingReasonChangeDialogSchema),
        defaultValues: { reason: '', isConfirmed: false }
    });

    const onSubmit: SubmitHandler<ShippingReasonChangeDialog> = (data) => {
        submit({ ...data, isConfirmed: true });
        modal.resolve();
        modal.remove();
    };

    const onCancel = () => {
        submit({ reason: '', isConfirmed: false });
        modal.resolve();
        modal.remove();
    };

    return (
        <Dialog open={modal.visible} onClose={onCancel}>
            <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Reason for changing the shipping date</DialogTitle>
                <DialogContent>
                    <Controller
                        control={control}
                        name="reason"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                error={!!error}
                                helperText={error?.message}
                                {...field}
                                size="small"
                                label="Reason"
                                fullWidth
                                sx={{ my: 1 }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <AnimateButton>
                        <Button variant="contained" size="small" type="submit">
                            Continue
                        </Button>
                    </AnimateButton>
                    <AnimateButton>
                        <Button variant="contained" size="small" color="error" onClick={onCancel}>
                            Cancel
                        </Button>
                    </AnimateButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
});

export default OrderMainDetails;
