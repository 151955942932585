// import { MessageList } from "react-chat-elements";
import { Backdrop, Box, Paper } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { MessageBox, SystemMessage } from 'react-chat-elements';
import { ChatMessage } from 'types/chat.types';
import ChatMessageAdditionalControls from '../ChatMessageAdditionalControls';
import useLightbox from 'hooks/useLightbox';
import { downloadUrl } from 'helpers/files.helper';
import { SxProps } from '@mui/system';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { useDeleteChatMessageMutation } from 'store/api/chat.api';

interface Props {
    message: ChatMessage;
    handleReply: (message: ChatMessage) => void;
}

function ChatMessageItem({ message, handleReply }: Props) {
    const { user } = useAuth();

    const { show } = useLightbox();

    const [deleteChatMessage] = useDeleteChatMessageMutation();

    const clientStyles: SxProps = {
        '.rce-mbox': { bgcolor: 'lightgreen' },
        '.rce-mbox-left-notch': { fill: 'lightgreen' }
    };

    const mountingWorkerStyles: SxProps = {
        '.rce-mbox': { bgcolor: 'lightblue' },
        '.rce-mbox-left-notch': { fill: 'lighblue' }
    };

    const renderMessage = () => {
        if (message.deleted) {
            return (
                <Box sx={{ display: 'block' }}>
                    {/* @ts-ignore */}
                    <MessageBox
                        position={message.ID === user?.id! ? 'right' : 'left'}
                        title={`${message.display_name} ${
                            message.role === 'client' ? '(client)' : message.role === 'mounting_worker' ? '(mounting worker)' : ''
                        }`}
                        type="text"
                        text={message.message}
                        date={new Date(message.datetime)}
                    />
                    <ChatMessageAdditionalControls
                        message={message}
                        position={message.ID === user?.id! ? 'right' : 'left'}
                        handleReply={handleReply}
                        reply
                    />
                </Box>
            );
        } else if (message.files && message.files.length > 0) {
            return (
                <Box sx={{ display: 'block' }}>
                    {message.message.length > 0 && (
                        <>
                            {/* @ts-ignore */}
                            <MessageBox
                                key={message.ID}
                                position={message.ID === user?.id! ? 'right' : 'left'}
                                title={`${message.display_name} ${
                                    message.role === 'client' ? '(client)' : message.role === 'mounting_worker' ? '(mounting worker)' : ''
                                }`}
                                type="text"
                                text={message.message}
                                date={new Date(message.datetime)}
                            />
                            <ChatMessageAdditionalControls
                                message={message}
                                position={message.ID === user?.id! ? 'right' : 'left'}
                                reply
                                handleReply={handleReply}
                            />
                        </>
                    )}

                    {message.files.map((file) => {
                        if (file.file_type === 'image/png' || file.file_type === 'image/jpeg' || file.file_type === 'image/gif') {
                            return (
                                <Box sx={{ display: 'block' }}>
                                    {/* @ts-ignore */}
                                    <MessageBox
                                        position={message.ID === user?.id! ? 'right' : 'left'}
                                        title={`${message.display_name} ${
                                            message.role === 'client'
                                                ? '(client)'
                                                : message.role === 'mounting_worker'
                                                ? '(mounting worker)'
                                                : ''
                                        } (${file.file_name})`}
                                        type="photo"
                                        data={{ uri: file.url, name: file.file_name, status: { download: true } }}
                                        date={new Date(message.datetime)}
                                        onTitleClick={() => downloadUrl({ name: file.file_name, src: file.url })}
                                        styles={{ cursor: 'pointer' }}
                                        onClick={() => show(file.url)}
                                    />
                                    <ChatMessageAdditionalControls
                                        message={message}
                                        position={message.ID === user?.id! ? 'right' : 'left'}
                                    />
                                </Box>
                            );
                        } else if (
                            // PDF, DOC, DOCX
                            file.file_type === 'application/pdf' ||
                            file.file_type === 'application/msword' ||
                            file.file_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        ) {
                            return (
                                <Box sx={{ display: 'block' }}>
                                    {/* @ts-ignore */}
                                    <MessageBox
                                        position={message.ID === user?.id! ? 'right' : 'left'}
                                        type="file"
                                        text={file.file_name}
                                        data={{
                                            uri: file.url,
                                            status: { download: true }
                                        }}
                                        onClick={() => show(file.url)}
                                        date={new Date(message.datetime)}
                                    />
                                    <ChatMessageAdditionalControls
                                        message={message}
                                        position={message.ID === user?.id! ? 'right' : 'left'}
                                    />
                                </Box>
                            );
                        } else {
                            return (
                                <Box sx={{ display: 'block' }}>
                                    {/* @ts-ignore */}
                                    <MessageBox
                                        position={message.ID === user?.id! ? 'right' : 'left'}
                                        type="file"
                                        text={file.file_name}
                                        data={{
                                            uri: file.url,
                                            status: {
                                                click: false,
                                                loading: 0
                                            }
                                        }}
                                        onDownload={() => downloadUrl({ name: file.file_name, src: file.url })}
                                        date={new Date(message.datetime)}
                                    />
                                    <ChatMessageAdditionalControls
                                        message={message}
                                        position={message.ID === user?.id! ? 'right' : 'left'}
                                    />
                                </Box>
                            );
                        }
                    })}
                </Box>
            );
        } else if (message.reply_to) {
            return (
                <Box sx={{ display: 'block' }}>
                    {/* @ts-ignore */}
                    <MessageBox
                        position={message.ID === user?.id! ? 'right' : 'left'}
                        title={`${message.display_name} ${
                            message.role === 'client' ? '(client)' : message.role === 'mounting_worker' ? '(mounting worker)' : ''
                        }`}
                        type="text"
                        text={message.message}
                        date={new Date(message.datetime)}
                        reply={{
                            title: message.reply_to.user_nicename,
                            message: message.reply_to.message
                        }}
                    />
                    <ChatMessageAdditionalControls
                        message={message}
                        position={message.ID === user?.id! ? 'right' : 'left'}
                        handleReply={handleReply}
                        reply
                    />
                </Box>
            );
        } else {
            return (
                <Box sx={{ display: 'block' }}>
                    {/* @ts-ignore */}
                    <MessageBox
                        position={message.ID === user?.id! ? 'right' : 'left'}
                        title={`${message.display_name} ${
                            message.role === 'client' ? '(client)' : message.role === 'mounting_worker' ? '(mounting worker)' : ''
                        }`}
                        type="text"
                        text={message.message}
                        date={new Date(message.datetime)}
                    />
                    <ChatMessageAdditionalControls
                        message={message}
                        position={message.ID === user?.id! ? 'right' : 'left'}
                        handleReply={handleReply}
                        reply
                    />
                </Box>
            );
        }
    };

    return (
        <Box sx={message.role === 'client' ? clientStyles : message.role === 'mounting_worker' ? mountingWorkerStyles : {}}>
            {/* @ts-ignore */}
            <ContextMenuTrigger
                id={message.message_id.toString()}
                disable={(message.ID === user?.id! ? false : true) || message.deleted}
                holdToDisplay={1000}
            >
                {renderMessage()}
            </ContextMenuTrigger>
            {/* @ts-ignore */}
            <ContextMenu id={message.message_id.toString()} style={{ zIndex: 1000 }}>
                <Paper elevation={4}>
                    {/* @ts-ignore */}
                    <ContextMenuItem onClick={() => deleteChatMessage({ messageId: message.message_id })}>
                        <Box
                            sx={{
                                cursor: 'pointer',
                                bgcolor: 'rgba(0,0,0,0.01)',
                                '&:hover': { bgcolor: 'rgba(0,0,0,0.05)' },
                                px: 2,
                                py: 1
                            }}
                        >
                            Delete message
                        </Box>
                    </ContextMenuItem>
                </Paper>
            </ContextMenu>
        </Box>
    );
}

export default ChatMessageItem;
