import { z } from 'zod';
export const MinimalClientSchema = z.object(
    {
        id: z.number(),
        clientId: z.number().nullable(),
        title: z.string(),
        company_number: z.string().nullable(),
        company_name: z.string().nullable(),
        phone_number: z.string().nullable(),
        email_address: z.string().nullable(),
        address: z.string().nullable().optional(),
        person_or_company: z.enum(['company', 'natural_person'])
    },
    { invalid_type_error: 'Client is required', required_error: 'Client is required' }
);

export type MinimalClient = z.infer<typeof MinimalClientSchema>;

export const ClientPositionEnum = z.enum([
    'manager',
    'sales_manager',
    'marketing_manager',
    'director',
    'owner',
    'owner_director',
    'private_person',
    'custom'
]);

export const PersonOrCompanyEnum = z.enum(['natural_person', 'company']).default('company');

export const ClientSchema = z.object({
    person_or_company: PersonOrCompanyEnum,
    post_title: z.string().optional()
});

export type Client = z.infer<typeof ClientSchema>;

// export const MinimalNaturalPersonClientSchema = z.object({
//     id: z.string(),
//     title: z.string(),
//     first_name: z.string(),
//     last_name: z.string(),
//     email_address: z.string(),
//     person_or_company: PersonOrCompanyEnum
// });

// export type MinimalNaturalPersonClient = z.infer<typeof MinimalNaturalPersonClientSchema>;

export const CompanyClientSchema = ClientSchema.extend({
    company_name: z.string().min(4),
    company_number: z.string().min(4),
    company_country: z.string(),
    company_address: z.string().optional(),
    company_postal_code: z.string().optional(),
    company_postal_address: z.string().nullable().optional(),
    company_mobile_phone_number: z.string().optional(),
    company_email_address: z.string().optional(),
    company_url: z.string().optional(),
    additional_phone_number: z.string().nullable().optional(),
    representatives: MinimalClientSchema.array().optional(),
    postal_place: z.string().nullable()
});

export type CompanyClient = z.infer<typeof CompanyClientSchema>;

// export const MinimalCompanyClientSchema = z.object({
//     id: z.string(),
//     title: z.string(),
//     company_number: z.string(),
//     company_name: z.string(),
//     phone_number: z.string(),
//     person_or_company: PersonOrCompanyEnum
// });

// export type MinimalCompanyClient = z.infer<typeof MinimalCompanyClientSchema>;

export const NaturalPersonClientSchema = ClientSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string(),
    person_country: z.string(),
    person_postal_code: z.string().nullable(),
    person_phone_number: z.string().nullable(),
    person_email: z.string().nullable(),
    person_address: z.string().nullable(),
    person_url: z.string().nullable().optional(),
    position: ClientPositionEnum,
    customPosition: z.string().nullable().optional(),
    additional_phone_number: z.string().nullable(),
    companies: MinimalClientSchema.array().optional(),
    postal_place: z.string().nullable()
});

export type NaturalPersonClient = z.infer<typeof NaturalPersonClientSchema>;
