import { OrderFieldsEnum, OrderStatus } from 'types/orders.types';

export const orderStatuses: OrderStatus[] = [
    {
        id: 'confirmed',
        text: 'Confirmed'
    },
    {
        id: 'request',
        text: 'Request'
    },
    {
        id: 'on_hold',
        text: 'On Hold'
    },
    {
        id: 'cancelled',
        text: 'Cancelled'
    },
    {
        id: 'passive_request',
        text: 'Passive Request'
    },
    {
        id: 'supplier_request',
        text: 'Related Order Request'
    },
    {
        id: 'supplier_confirmed',
        text: 'Related Order Confirmed'
    }
];

export const orderFields: { id: OrderFieldsEnum; label: string }[] = [
    { id: 'orderTitle', label: 'Order Title' },
    { id: 'orderStatus', label: 'Order Status' },
    { id: 'shippingAddress', label: 'Shipping Address' },
    { id: 'onHoldReason', label: 'On Hold Reason' },
    { id: 'customAddress', label: 'Custom Address' },
    { id: 'client', label: 'Client' },
    { id: 'factory', label: 'Factory' },
    { id: 'mounting', label: 'Mounting' },
    { id: 'country', label: 'Country' },
    { id: 'employees', label: 'Employees' },
    { id: 'deadlineDate', label: 'Deadline Date' },
    { id: 'shippingDate', label: 'Shipping Date' },
    { id: 'inquiry', label: 'Order Details' },
    { id: 'relatedOrders', label: 'Related Orders' },
    { id: 'shippingOrders', label: 'Shipping Orders' },
    { id: 'products', label: 'Products' },
    { id: 'orderSurveyFiles', label: 'Survey Files' },
    { id: 'orderFactoryFiles', label: 'Factory Files' },
    { id: 'orderExternalDesignerFiles', label: 'External Designer Files' },
    { id: 'orderFabricationFiles', label: 'Fabrication Files' },
    { id: 'order3dFiles', label: 'Order 3D Files' },
    { id: 'orderChat', label: 'Order Chat' },
    { id: 'orderNotifications', label: 'Order Notifications' },
    { id: 'globalChat', label: 'Global Chat' },
    { id: 'globalNotifications', label: 'Global Notifications' },
    { id: 'scheduler', label: 'Scheduler' },
    { id: 'gallery', label: 'Gallery' },
    { id: 'orderAllowChange', label: 'Order Allow Changes' }
];

export const drawingTooltip = [
    {
        id: 'uploaded',
        explanation: 'The drawing file has been successfully uploaded to the system and is now available for review.'
    },
    {
        id: 'sent_to_client',
        explanation: 'The drawing has been sent to the client for their review or approval. We are now awaiting feedback from them.'
    },
    {
        id: 'revision_pending',
        explanation: 'The drawing requires revisions based on feedback or internal review. Updates are needed before it can move forward.'
    },
    {
        id: 'requested',
        explanation: 'Drawing is not yet uploaded. Waiting for factory to upload it.'
    },
    {
        id: 'not_asked_for',
        explanation: 'This drawing was not requested or is not required for the current project.'
    },
    {
        id: 'drawing_uploaded',
        explanation:
            'The final drawing has been uploaded, marking it ready for confirmation, approval, or further review by relevant parties.'
    },
    {
        id: 'approved',
        explanation: 'The drawing has been reviewed and approved. It is now considered final and ready for implementation or archiving.'
    }
];
