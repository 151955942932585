import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Delete, Download, Launch, List, ViewQuilt } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    Grid2 as Grid
} from '@mui/material';
import FileSaver from 'file-saver';
import { downloadUrl, urlToBase64 } from 'helpers/files.helper';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    useDeleteOrderGalleryImageMutation,
    useDownloadSelectedGalleryImagesMutation,
    useEditOrderGalleryImageMutation,
    useFetchGalleryImageTagsQuery,
    useFetchGalleryQuery,
    useFetchOrderGalleryQuery,
    useUploadOrderGalleryImageMutation
} from 'store/api/orders.api';
import Swal from 'sweetalert2';
import { GalleryDisplay, GalleryFile } from 'types/orders.types';
import Cover from 'ui-component/Cover';
import MainCard from 'ui-component/cards/MainCard';
import ModalFormCard from 'ui-component/cards/ModalCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import FileListComponent from 'ui-component/files/FileList';
import Gallery, { gallerySelectedImagesAtom } from 'ui-component/gallery/Gallery';
import DebouncedTextField from 'ui-component/inputs/DebouncedTextField';
import MobileCompactor from 'ui-component/mobile/MobileCompactor';
import { useImmer } from 'use-immer';
import { getMessage } from 'utils/messages';
import { ReactSwal } from 'utils/reactswal';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';
import { z } from 'zod';

const GalleryPage = () => {
    const { user } = useAuth();

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedImages, setSelectedImages] = useAtom(gallerySelectedImagesAtom);
    const [gallery, setGallery] = useImmer<GalleryFile[]>([]);

    const {
        data: galleryInitialData,
        isLoading: isGalleryLoading,
        isFetching: isGalleryFetching
    } = useFetchGalleryQuery({ categories: selectedCategories, userId: user?.id!, search: searchTerm }, { skip: !user });

    useEffect(() => {
        if (!galleryInitialData) return;
        setGallery(galleryInitialData.images);
    }, [galleryInitialData]);

    const [deleteGalleryImage] = useDeleteOrderGalleryImageMutation();
    const [downloadSelectedImages] = useDownloadSelectedGalleryImagesMutation();

    const [galleryDisplay, setGalleryDisplay] = useState<GalleryDisplay>('list');

    const handleDeleteImages = (imageIds: number[]) => {
        deleteGalleryImage({ imageIds });
    };

    const handleEditImage = (imageId: number) => {
        NiceModal.show(GalleryImageEditModal, { imageId });
    };

    const [editGalleryImage] = useEditOrderGalleryImageMutation();

    return (
        <MainCard
            title="Gallery"
            dense
            border
            content={false}
            Header={
                <MobileCompactor collapsedText="Show filters" expandedText="Hide filters" sx={{ mx: 1, py: 0 }}>
                    <Grid size={{ xs: 12, sm: 2, lg: 1 }}>
                        <DebouncedTextField
                            value={searchTerm}
                            onChange={(value) => setSearchTerm(value as string)}
                            label="Search"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            sx={{
                                '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                            }}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 2, lg: 1 }}>
                        <FormControl sx={{ width: { xs: 280, sm: '100%' } }} size="small">
                            <InputLabel shrink={true}>
                                {galleryInitialData?.categories && galleryInitialData.categories.length === 0
                                    ? 'No categories found!'
                                    : 'Categories'}
                            </InputLabel>
                            <Select
                                multiple
                                value={selectedCategories}
                                disabled={galleryInitialData?.categories && galleryInitialData.categories.length === 0 ? true : false}
                                MenuProps={{
                                    sx: {
                                        maxHeight: 500
                                    }
                                }}
                                input={
                                    <OutlinedInput
                                        label="Categories"
                                        size="small"
                                        notched
                                        sx={{
                                            '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                        }}
                                    />
                                }
                                onChange={(event) => setSelectedCategories(event.target.value as [])}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value, index) => (
                                            <Typography variant="body1">
                                                {value}
                                                {index !== selected.length - 1 && ','}
                                            </Typography>
                                        ))}
                                    </Box>
                                )}
                            >
                                {galleryInitialData && galleryInitialData.categories.length > 0 ? (
                                    galleryInitialData?.categories.map((category) => (
                                        <MenuItem key={category} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem key="empty" value="empty" disabled>
                                        No categories found
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 3, lg: 'auto' }}>
                        <ToggleButtonGroup
                            value={galleryDisplay}
                            exclusive
                            onChange={(event, value) => setGalleryDisplay(value)}
                            sx={{
                                display: 'flex',
                                gap: 0.5,
                                justifyContent: 'space-between'
                            }}
                        >
                            <ToggleButton size="small" value="list" sx={{ p: '3px' }}>
                                <List sx={{ width: 20, height: 20 }} />
                            </ToggleButton>
                            <ToggleButton size="small" value={'masonry'} sx={{ p: '3px' }}>
                                <ViewQuilt sx={{ width: 20, height: 20 }} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    {galleryDisplay === 'list' && selectedImages && selectedImages.size > 0 && (
                        <Grid
                            size={{ xs: 12, sm: 3, md: 'grow' }}
                            justifyContent={'flex-end'}
                            display={'flex'}
                            color="rgb(211, 226, 253)"
                            gap={1}
                        >
                            <Button
                                variant="outlined"
                                sx={{ height: '100%', p: 0, minWidth: 48 }}
                                color="inherit"
                                onClick={async () => {
                                    const imagesIds: number[] = [];
                                    selectedImages?.forEach((imageId) => imagesIds.push(imageId));

                                    if (imagesIds.length === 1) {
                                        const image = gallery.find((image) => image.id === imagesIds[0]);
                                        if (!image) return;

                                        downloadUrl({
                                            name: image?.name,
                                            src: image?.src
                                        });
                                    } else {
                                        const file = await downloadSelectedImages({ imagesIds, userId: user?.id! });

                                        if ('data' in file && file.data !== null)
                                            downloadUrl({ src: file.data, name: 'Gallery Export.zip' });
                                    }

                                    // @ts-ignore
                                    selectedImages.clear();
                                }}
                                size="small"
                            >
                                <Download sx={{ color: 'black', width: 20, height: 20 }} />
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ height: '100%', p: 0, minWidth: 48 }}
                                color="inherit"
                                onClick={() => {
                                    const selectedImagesArray: number[] = [];
                                    selectedImages?.forEach((imageId) => selectedImagesArray.push(imageId));
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: `This will delete ${selectedImagesArray.length} selected files. You won't be able to revert this!`,
                                        icon: 'warning',
                                        showCancelButton: true
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            handleDeleteImages(selectedImagesArray);
                                            // @ts-ignore
                                            selectedImages.clear();
                                        }
                                    });
                                }}
                                size="small"
                            >
                                <Delete sx={{ color: 'black', width: 20, height: 20 }} />
                            </Button>
                        </Grid>
                    )}
                </MobileCompactor>
            }
        >
            <Box
                sx={{
                    width: '100%'
                }}
            >
                {isGalleryLoading || isGalleryFetching ? (
                    <Cover sx={{ width: '100%', height: '300px', mx: 'auto', my: 'auto' }} />
                ) : (
                    <Gallery
                        images={gallery}
                        isImagesFetching={isGalleryFetching}
                        onDelete={handleDeleteImages}
                        onEdit={handleEditImage}
                        sx={{ height: 'calc(100vh - 149px)', overflow: 'scroll' }}
                        display={galleryDisplay}
                        categories={galleryInitialData?.categories}
                        rowsEditEnabled
                        additionalColumn={{
                            key: 'additional',
                            name: 'Additional',
                            headerRenderer: () => <></>,
                            width: 133,
                            minWidth: 129,
                            frozen: true,
                            sortable: false,
                            formatter: (props) => (
                                <>
                                    {props.row.title && (
                                        <Chip label={props.row.title} size="small" variant="outlined" sx={{ my: 'auto' }} />
                                    )}
                                    {props.row.link && (
                                        <Link to={props.row.link}>
                                            <IconButton size="medium" edge="start" sx={{ m: 0 }} color="primary">
                                                <Launch fontSize="inherit" />
                                            </IconButton>
                                        </Link>
                                    )}
                                </>
                            )
                        }}
                        onRowsChange={async (rows, edited) => {
                            let shouldContinue = true;
                            const row = rows[edited.indexes[0]];
                            const imageIds: number[] = [row.id];
                            const images: GalleryFile[] = [gallery?.find((image) => image.id === row.id)!];
                            selectedImages?.forEach((imageId) => {
                                if (!imageIds.includes(imageId)) {
                                    if (gallery) {
                                        images.push(gallery?.find((image) => image.id === imageId)!);
                                    }
                                    imageIds.push(imageId);
                                }
                            });

                            if (imageIds.length > 1) {
                                await ReactSwal.fire({
                                    title: 'Are you sure?',
                                    html: (
                                        <Stack sx={{ overflow: 'hidden', display: 'flex' }} gap={2}>
                                            <Typography sx={{ textAlign: 'start' }}>
                                                Are you sure you want to perform the following update on those {images.length} records?
                                            </Typography>
                                            <Stack sx={{ display: 'flex', justifyContent: 'start' }} gap={0.5}>
                                                <Typography sx={{ textAlign: 'start' }}>Field: {edited.column.name}</Typography>
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Typography sx={{ whiteSpace: 'nowrap' }}>Update to: </Typography>
                                                    {edited.column.key === 'categories' ? (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                maxWidth: '70%',
                                                                overflowX: 'scroll',
                                                                gap: 1
                                                            }}
                                                        >
                                                            {row.categories.map((category) => (
                                                                <Chip variant="outlined" label={category} size="small" />
                                                            ))}
                                                        </Box>
                                                    ) : (
                                                        <Typography>{row.text}</Typography>
                                                    )}
                                                </Box>
                                            </Stack>
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{ overflowX: 'hidden', width: '100%', height: '100%', overflowY: 'auto' }}
                                            >
                                                {images.map((image) => (
                                                    <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                                                        <Box
                                                            src={image.src}
                                                            component={'img'}
                                                            sx={{
                                                                height: 50,
                                                                width: '100%',
                                                                objectFit: 'cover',
                                                                border: '1px solid #e3e8ef'
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>
                                    ),
                                    icon: 'warning',
                                    showCancelButton: true
                                }).then((result) => {
                                    if (result.isDenied || result.isDismissed || !result.isConfirmed) {
                                        shouldContinue = false;
                                        return;
                                    }
                                });
                            }
                            if (!shouldContinue) return;

                            setGallery((draft) => {
                                imageIds?.forEach((imageId) => {
                                    if (edited.column.key === 'categories') {
                                        draft[gallery.findIndex((image) => image.id === imageId)].categories = row.categories;
                                    } else {
                                        draft[gallery.findIndex((image) => image.id === imageId)].text = row.text;
                                    }
                                });
                            });
                            toast.promise(
                                editGalleryImage({
                                    imageIds,
                                    categories: edited.column.key === 'categories' ? row.categories : null,
                                    description: edited.column.key === 'text' ? row.text || '' : null
                                }),
                                { pending: getMessage('savePending'), success: getMessage('saved') },
                                {
                                    autoClose: 1000
                                }
                            );
                            // @ts-ignore
                            if (selectedImages?.size > 0) selectedImages.clear();
                        }}
                    />
                )}
            </Box>
        </MainCard>
    );
};

export const galleryImageUploadConfirmationSchema = z.object({
    files: z.instanceof(FileList),
    categories: z.string().array(),
    description: z.string()
});

export type GalleryImageUploadConfirmation = z.infer<typeof galleryImageUploadConfirmationSchema>;

export const GalleryImageUploadConfirmationModal = NiceModal.create(
    ({ files, categories, orderId }: { files: FileList; categories: string[]; orderId: number }) => {
        const {
            handleSubmit,
            control,
            formState: { errors }
        } = useForm<GalleryImageUploadConfirmation>({
            resolver: zodResolver(galleryImageUploadConfirmationSchema),
            defaultValues: { files, categories: [], description: '' }
        });

        const [uploadGalleryImage, {}] = useUploadOrderGalleryImageMutation();

        const modal = useModal();

        const onSubmit: SubmitHandler<GalleryImageUploadConfirmation> = (data) => {
            if (!orderId) return;
            modal.remove();
            Swal.showLoading();
            toast
                .promise(
                    uploadGalleryImage({ categories: data.categories, uploadedFiles: data.files, orderId, description: data.description }),
                    { success: getMessage('uploaded'), pending: getMessage('uploadPending'), error: getMessage('uploadError') }
                )
                .then(() => Swal.close());
        };

        return (
            <Modal open={modal.visible} onClose={modal.remove}>
                <ModalFormCard title="Select categories" sx={{ width: 400 }} onClose={modal.remove}>
                    <Stack spacing={2} sx={{ my: 1 }} component={'form'} onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            name="categories"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Autocomplete
                                    freeSolo
                                    multiple
                                    value={value}
                                    onChange={(event, value) => {
                                        onChange(value);
                                    }}
                                    noOptionsText="No categories found"
                                    getOptionLabel={(option) => option}
                                    options={categories}
                                    renderInput={(params) => <TextField {...params} size="small" label="Categories" />}
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((option: string, index: number) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="description"
                            render={({ field, fieldState: { error } }) => <TextField {...field} label="Description" size="small" />}
                        />
                        <FileListComponent files={files} />
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                            <AnimateButton>
                                <Button variant="contained" size="small" type="submit">
                                    Save
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Stack>
                </ModalFormCard>
            </Modal>
        );
    }
);

export const galleryImageEditSchema = z.object({
    categories: z.string().array(),
    description: z.string()
});

export type GalleryImageEdit = z.infer<typeof galleryImageEditSchema>;

export const GalleryImageEditModal = NiceModal.create(({ imageId }: { imageId: number }) => {
    const modal = useModal();

    const [orderId] = useAtom(orderIdAtom);

    const { handleSubmit, reset, control } = useForm<GalleryImageEdit>({
        resolver: zodResolver(galleryImageEditSchema),
        defaultValues: { categories: [], description: '' }
    });

    const { data: imageTags, isFetching: isImageTagsLoading } = useFetchGalleryImageTagsQuery({ imageId });

    const { data: gallery, isLoading: isGalleryLoading } = useFetchOrderGalleryQuery(
        { orderId: orderId!, categories: [], search: '' },
        { skip: !orderId }
    );

    useEffect(() => {
        if (!gallery || !imageTags) return;
        reset({ categories: imageTags.categories, description: imageTags.imageDescription === null ? '' : imageTags.imageDescription });
    }, [imageTags]);

    const [editGalleryImage] = useEditOrderGalleryImageMutation();

    const onSubmit: SubmitHandler<GalleryImageEdit> = (data) => {
        if (!orderId) return;
        editGalleryImage({ categories: data.categories, imageIds: [imageId], description: data.description });
        modal.remove();
    };

    return (
        <Modal open={modal.visible} onClose={modal.remove}>
            <ModalFormCard title="Edit categories" sx={{ width: 400 }} onClose={modal.remove}>
                <Stack spacing={2} sx={{ my: 1 }} component={'form'} onSubmit={handleSubmit(onSubmit)}>
                    {gallery && (
                        <Controller
                            control={control}
                            name="categories"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Autocomplete
                                    freeSolo
                                    multiple
                                    value={value}
                                    onChange={(event, value) => {
                                        onChange(value);
                                    }}
                                    getOptionLabel={(option) => option}
                                    options={gallery?.categories}
                                    renderInput={(params) => <TextField {...params} size="small" label="Categories" />}
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((option: string, index: number) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                />
                            )}
                        />
                    )}
                    <Controller
                        control={control}
                        name="description"
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} label="Description" size="small" error={!!error} helperText={error?.message} />
                        )}
                    />
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                        <AnimateButton>
                            <Button variant="contained" size="small" type="submit">
                                Save
                            </Button>
                        </AnimateButton>
                    </Box>
                </Stack>
            </ModalFormCard>
        </Modal>
    );
});

export default GalleryPage;
