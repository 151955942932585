import { ChatMessage, ChatOrder, ChatOrderInformation } from 'types/chat.types';
import { api } from './api';
import { SuggestionDataItem } from 'react-mentions';

export const chatApi = api.injectEndpoints({
    endpoints: (builder) => ({
        newChatMessage: builder.mutation<
            ChatMessage,
            { orderId: number; message: string; replyToId?: Number; userId: number; files: File[] }
        >({
            query: ({ message, orderId, userId, replyToId, files }) => {
                const formData = new FormData();
                formData.append('post_id', orderId.toString());
                formData.append('message', message);
                formData.append('reply_to', replyToId ? replyToId.toString()! : '0');
                formData.append('user_id', userId.toString());
                for (let i = 0; i < files!.length; i++) {
                    formData.append('file' + i, files![i]);
                }
                return { url: '/v1/chat/new_message', method: 'POST', body: formData };
            },
            invalidatesTags: ['ChatMessages']
        }),
        fetchChatOrders: builder.query<ChatOrder[], { userId: number }>({
            query: ({ userId }) => {
                return { url: '/v1/chat/load_orders', method: 'POST', body: JSON.stringify({ userID: userId }) };
            },
            providesTags: ['Orders']
        }),
        fetchChatOrder: builder.query<ChatOrder[], { userId: number; orderId: number }>({
            query: ({ userId, orderId }) => {
                return { url: '/v1/chat/load_order_chat', method: 'POST', body: JSON.stringify({ userID: userId, orderId: orderId }) };
            },
            providesTags: ['Orders']
        }),
        fetchChatMessages: builder.query<ChatMessage[], { participantId: number; orderId: number; roomId: number }>({
            query: ({ participantId, orderId, roomId }) => {
                return {
                    url: '/v1/chat/load_messages',
                    method: 'POST',
                    body: { room_id: roomId, participant_id: participantId, current_post_id: orderId }
                };
            },
            providesTags: ['ChatMessages']
        }),
        fetchChatOrderInformation: builder.query<ChatOrderInformation, { userId: number; orderId: number; roomId: number }>({
            query: ({ orderId }) => {
                return {
                    url: '/v1/chat/get_order_information',
                    method: 'POST',
                    body: {
                        order_id: orderId
                    }
                };
            },
            transformResponse: (response: { order_information: ChatOrderInformation }) => response.order_information,
            providesTags: ['Orders']
        }),
        fetchChatOrderParticipants: builder.query<SuggestionDataItem[], { userId: number; orderId: number; roomId: number }>({
            query: ({ orderId }) => {
                return {
                    url: '/v1/chat/chat_participans',
                    method: 'POST',
                    body: {
                        order_id: orderId
                    }
                };
            },
            transformResponse: (response: { key: string; value: number }[]) => {
                const object: SuggestionDataItem[] = [];
                for (const element of response) {
                    object.push({ id: element.value, display: element.key });
                }
                return object;
            },
            providesTags: ['Orders']
        }),
        setChatUnread: builder.mutation<any, { roomId: number; userId: number }>({
            query: ({ roomId, userId }) => {
                return { url: '/v1/chat/set_chat_unread', body: { room_id: roomId, participant_id: userId }, method: 'POST' };
            },
            invalidatesTags: ['Orders']
        }),
        fetchChatUnreadMessagesCount: builder.query<number, { userId: number }>({
            query: ({ userId }) => {
                const requestBody = { user_id: userId };
                return { url: '/v1/chat/unread_messages', body: requestBody, method: 'POST' };
            },
            providesTags: ['ChatMessages']
        }),
        fetchOrderChatUnreadMessagesCount: builder.query<number, { userId: number; orderId: number }>({
            query: ({ userId, orderId }) => {
                const requestBody = { user_id: userId, order_id: orderId };
                return { url: '/v1/chat/order_unread_messages', body: requestBody, method: 'POST' };
            },
            providesTags: ['ChatMessages']
        }),
        deleteChatMessage: builder.mutation<any, { messageId: number }>({
            query: ({ messageId }) => {
                const requestBody = { message_id: messageId };
                return {
                    url: '/v1/chat/delete_message',
                    body: requestBody,
                    method: 'POST'
                };
            },
            invalidatesTags: ['ChatMessages']
        })
    })
});

export const {
    useLazyFetchChatMessagesQuery,
    useLazyFetchChatOrderInformationQuery,
    useLazyFetchChatOrderParticipantsQuery,
    useFetchChatOrdersQuery,
    useNewChatMessageMutation,
    useSetChatUnreadMutation,
    useFetchChatOrderInformationQuery,
    useFetchChatMessagesQuery,
    useFetchChatOrderQuery,
    useFetchChatOrderParticipantsQuery,
    useFetchChatUnreadMessagesCountQuery,
    useFetchOrderChatUnreadMessagesCountQuery,
    useDeleteChatMessageMutation
} = chatApi;
