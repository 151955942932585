import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Launch } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography, Grid2 as Grid } from '@mui/material';
import { handleGetStatusColor } from 'helpers/orders.helper';
import { isUserClient, isUserSuperAdmin } from 'helpers/users.helper';
import useAuth from 'hooks/useAuth';
import { atom, useAtom } from 'jotai';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Column, SelectColumn, SortColumn } from 'react-data-grid';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import DrawerCard from 'ui-component/cards/DrawerCard';
import Drawer from 'ui-component/extended/Drawer';
import { OrdersGet } from '../../store/api/orders.api';
import OrderManagePage from '../../views/pages/order/OrderManagePage';
import { CompiledOrder } from 'types/orders.types';
import { useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import { handlePermissionCheck } from 'helpers/global.helper';
import { drawingTooltip } from 'utils/data/orders';
import slugify from 'react-slugify';
import Dot from 'ui-component/dot';

// Display Mode Disables:
//     Selection
//     Filters
//     Deletions
//     Sorting
//     Order Slider

interface Props {
    tableData: OrdersGet;
    direction: string;
    filters?: boolean;
}

export const filterCriteriaAtom = atom<{
    orderNum: string;
    orderOwner: string;
    orderEmployee: string;
    country: string;
    factory: string;
    mainStatus: string;
    orderStatus: string;
    shippingStatus: string;
    requiresAttention: boolean;
}>({
    orderNum: '',
    orderOwner: 'All',
    orderEmployee: 'All',
    country: 'All',
    factory: 'All',
    mainStatus: 'All',
    orderStatus: 'All',
    shippingStatus: 'All',
    requiresAttention: false
});

export const selectedRowsAtom = atom<Set<string> | null>(null);

const OrdersTable = ({ tableData, direction, filters = false }: Props) => {
    const { user } = useAuth();

    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    const column: readonly Column<CompiledOrder>[] = [
        {
            ...SelectColumn,
            width: filters ? 0 : 35,
            minWidth: filters ? 0 : 35
        },
        {
            key: 'orderNum',
            name: 'No.',
            width: 130,
            resizable: false,
            frozen: true,
            sortable: true,
            formatter(props) {
                const value = props.row;
                return (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                        {value.orderNum}
                        {value.requiresAttention === true && (
                            <Tooltip title="Requires Attention" placement="top">
                                <Dot />
                            </Tooltip>
                        )}
                    </Box>
                );
            }
        },
        {
            key: 'requiresAttention',
            name: 'requiresAttention',
            width: 0,
            minWidth: 0,
            maxWidth: 0
        },
        {
            key: 'orderTitle',
            name: 'Title',
            resizable: true,
            width: handlePermissionCheck(userFieldsVisibility!, 'orderTitle') ? null : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'orderTitle') ? null : 0,
            formatter(props) {
                const value = props.row;
                return (
                    <>
                        {filters ? (
                            <Tooltip title={value.orderTitle} placement="top">
                                <Typography
                                    variant="body1"
                                    display={'inline'}
                                    fontWeight={600}
                                    lineHeight={'20px'}
                                    component={Link}
                                    sx={{ my: 'auto', cursor: 'pointer', textDecoration: 'inherit', color: 'inherit' }}
                                    to={`/order/${value.orderId}`}
                                >
                                    {value.orderTitle}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <>
                                <BrowserView>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Tooltip title="Click to open">
                                            <Link to={`/order/${value.orderId}`} style={{ textDecoration: 'inherit', color: 'inherit' }}>
                                                <IconButton size={'small'} disableRipple sx={{ p: 0 }}>
                                                    <Launch sx={{ width: 20, height: 20 }} />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip title={value.orderTitle} placement="top">
                                            <Typography
                                                variant="body1"
                                                display={'inline'}
                                                fontWeight={600}
                                                lineHeight={'20px'}
                                                sx={{ my: 'auto', cursor: 'pointer', textDecoration: 'inherit', color: 'inherit' }}
                                                onClick={() => {
                                                    NiceModal.show(OrderDrawer, { orderId: value.orderId });
                                                }}
                                            >
                                                {value.orderTitle}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </BrowserView>
                                <MobileView>
                                    <Tooltip title={value.orderTitle} placement="top">
                                        <Link to={`/order/${value.orderId}`} style={{ textDecoration: 'inherit', color: 'inherit' }}>
                                            <Typography
                                                variant="body1"
                                                display={'inline'}
                                                fontWeight={600}
                                                lineHeight={'20px'}
                                                sx={{ my: 'auto', cursor: 'pointer', textDecoration: 'inherit', color: 'inherit' }}
                                            >
                                                {value.orderTitle}
                                            </Typography>
                                        </Link>
                                    </Tooltip>
                                </MobileView>
                            </>
                        )}
                    </>
                );
            }
        },
        {
            key: 'clientTitle',
            name: 'Client',
            sortable: true,
            width: handlePermissionCheck(userFieldsVisibility!, 'client') ? 200 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'client') ? 200 : 0,
            resizable: true,
            frozen: false,
            formatter(props) {
                const value = props.row;
                return (
                    <Tooltip title={value.clientTitle} placement="top">
                        {isUserSuperAdmin(user?.role!) ? (
                            <Typography
                                variant="body1"
                                display={'inline'}
                                fontWeight={600}
                                component={Link}
                                to={`/client/manage/${value.clientID}`}
                                sx={{ textDecoration: 'inherit', color: 'inherit' }}
                            >
                                {value.clientTitle}
                            </Typography>
                        ) : (
                            <Typography variant="body1" display={'inline'} fontWeight={400}>
                                {value.clientTitle}
                            </Typography>
                        )}
                    </Tooltip>
                );
            }
        },
        {
            key: 'shippingAddress',
            name: 'Address',
            width: handlePermissionCheck(userFieldsVisibility!, 'shippingAddress') ? 200 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'shippingAddress') ? 200 : 0,
            resizable: true,
            frozen: false,
            formatter(props) {
                const value = props.row;
                return (
                    <Tooltip title={value.shippingAddress} placement="top">
                        <Typography display={'inline'}>{value.shippingAddress}</Typography>
                    </Tooltip>
                );
            }
        },
        {
            key: 'ownerInitials',
            name: 'Owner',
            width: isUserSuperAdmin(user?.role!) ? 100 : 0,
            minWidth: isUserSuperAdmin(user?.role!) ? 100 : 0,
            formatter(props) {
                const value = props.row;
                return (
                    <Tooltip title={value.orderOwner} placement="top">
                        {!isUserSuperAdmin(user?.role!) ? (
                            <Box
                                sx={{
                                    border: '1px solid darkgray',
                                    borderRadius: '50px',
                                    textAlign: 'center',
                                    '&:hover': {
                                        background: 'darkgray',
                                        transition: 'all 0.3s ease-in-out',
                                        color: 'white'
                                    },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    height: '25px',
                                    marginTop: '5px'
                                }}
                            >
                                <Typography
                                    display={'inline'}
                                    color={'inherit'}
                                    fontWeight={600}
                                    sx={{
                                        textDecoration: 'none',
                                        marginTop: '-10px',
                                        lineHeight: '35px',
                                        alignSelf: 'center',
                                        height: '25px'
                                    }}
                                >
                                    {value.ownerInitials}
                                </Typography>
                            </Box>
                        ) : (
                            <Link to={`/user/manage/${value.employeeId}`} style={{ textDecoration: 'inherit', color: 'inherit' }}>
                                <Box
                                    sx={{
                                        border: '1px solid darkgray',
                                        borderRadius: '50px',
                                        textAlign: 'center',
                                        '&:hover': {
                                            background: 'darkgray',
                                            transition: 'all 0.3s ease-in-out',
                                            color: 'white'
                                        },
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        height: '25px',
                                        marginTop: '5px'
                                    }}
                                >
                                    <Typography
                                        display={'inline'}
                                        color={'inherit'}
                                        fontWeight={600}
                                        sx={{
                                            textDecoration: 'none',
                                            marginTop: '-10px',
                                            lineHeight: '35px',
                                            alignSelf: 'center',
                                            height: '25px'
                                        }}
                                    >
                                        {value.ownerInitials}
                                    </Typography>
                                </Box>
                            </Link>
                        )}
                    </Tooltip>
                );
            }
        },
        {
            key: 'orderCreated',
            name: 'Created',
            width: 90,
            sortable: true,
            resizable: false,
            formatter(props) {
                const value = props.row;
                let date = '';
                if (value.orderCreated && value.orderCreated !== '') {
                    date = moment.unix(parseInt(value.orderCreated)).format();
                }
                return <Typography display={'inline'}>{date}</Typography>;
            }
        },
        {
            key: 'shippingDate',
            name: 'Shipping',
            width: handlePermissionCheck(userFieldsVisibility!, 'shippingDate') ? 110 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'shippingDate') ? 110 : 0,
            sortable: true,
            resizable: false,
            formatter(props) {
                const value = props.row;
                let date = '';
                if (value.shippingDate && value.shippingDate !== '') {
                    date = moment.unix(parseInt(value.shippingDate)).format();
                }
                return (
                    <>
                        {date && (
                            <Tooltip title={value.shippingStatus} placement="top">
                                {isUserClient(user?.role!) ? (
                                    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <Typography style={{ display: 'flex' }}>
                                            {date}
                                            <Box
                                                sx={{
                                                    width: '10px',
                                                    height: '10px',
                                                    display: 'block',
                                                    marginTop: '3px',
                                                    marginLeft: '4px',
                                                    bgcolor: handleGetStatusColor(value.shippingStatusVal),
                                                    borderRadius: 36
                                                }}
                                            ></Box>
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Link
                                        to={`/shipping/${value.shippingId}`}
                                        style={{ textDecoration: 'inherit', color: 'inherit', display: 'inline-block' }}
                                    >
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography style={{ display: 'flex' }}>
                                                {date}
                                                <Box
                                                    sx={{
                                                        width: '10px',
                                                        height: '10px',
                                                        display: 'block',
                                                        marginTop: '3px',
                                                        marginLeft: '4px',
                                                        bgcolor: handleGetStatusColor(value.shippingStatusVal),
                                                        borderRadius: 36
                                                    }}
                                                ></Box>
                                            </Typography>
                                        </Box>
                                    </Link>
                                )}
                            </Tooltip>
                        )}
                    </>
                );
            }
        },
        {
            key: 'shippingNo',
            name: 'Ship No.',
            width: handlePermissionCheck(userFieldsVisibility!, 'shippingDate') ? 70 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'shippingDate') ? 70 : 0,
            resizable: false,
            sortable: true,
            formatter(props) {
                const value = props.row;
                return (
                    <>
                        {isUserClient(user?.role!)}
                        {value.shippingNo !== 'N/A' && (
                            <>
                                {isUserClient(user?.role!) ? (
                                    <Typography
                                        sx={{
                                            display: 'inline-block',
                                            textAlign: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        {value.shippingNo}
                                    </Typography>
                                ) : (
                                    <Link
                                        to={`/shipping/${value.shippingId}`}
                                        style={{
                                            textDecoration: 'inherit',
                                            color: 'inherit',
                                            display: 'inline-block',
                                            textAlign: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        <Typography>{value.shippingNo}</Typography>
                                    </Link>
                                )}
                            </>
                        )}
                    </>
                );
            }
        },
        {
            key: 'lastDrawingChangeDate',
            name: 'Drawing Changed',
            width: handlePermissionCheck(userFieldsVisibility!, 'orderFactoryFiles') ? 125 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'orderFactoryFiles') ? 125 : 0,
            sortable: true,
            resizable: false,
            formatter(props) {
                const value = props.row;
                let date = '';
                if (value.lastDrawingChangeDate && value.lastDrawingChangeDate !== 'null') {
                    date = moment.unix(parseInt(value.lastDrawingChangeDate)).format();
                }
                return (
                    <Tooltip title={date} placement="top">
                        <Typography display={'inline'}>{date}</Typography>
                    </Tooltip>
                );
            }
        },
        {
            key: 'drawings',
            name: 'Drawings',
            width: handlePermissionCheck(userFieldsVisibility!, 'orderFactoryFiles') ? 140 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'orderFactoryFiles') ? 140 : 0,
            resizable: false,
            sortable: true,
            formatter(props) {
                const value = props.row;
                return (
                    <Tooltip
                        PopperProps={{
                            sx: {
                                whiteSpace: 'pre-wrap'
                            }
                        }}
                        title={
                            value.drawings !== '1/2 Approved'
                                ? drawingTooltip.find((tooltip) => tooltip.id === slugify(value.drawings, { delimiter: '_' }))?.explanation
                                : value.drawingsComment
                        }
                        placement="top"
                    >
                        <Typography
                            sx={{
                                bgcolor: handleGetStatusColor(value.drawings),
                                color: 'white',
                                borderRadius: '50px',
                                textTransform: 'capitalize',
                                fontSize: '12px !important',
                                wordWrap: 'none',
                                wordBreak: 'keep-all',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                padding: '2px 10px',
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >
                            {value.drawings}
                        </Typography>
                    </Tooltip>
                );
            }
        },
        {
            key: 'mounting',
            name: 'Mounting',
            width: handlePermissionCheck(userFieldsVisibility!, 'mounting') ? 130 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'mounting') ? 130 : 0,
            resizable: false,
            sortable: true,
            formatter(props) {
                const value = props.row;
                return (
                    <Tooltip
                        PopperProps={{
                            sx: {
                                whiteSpace: 'pre-wrap'
                            }
                        }}
                        title={value.mounting === 'Planned' ? value.mountingComment : value.mounting}
                    >
                        {value.mounting === 'Planned' || value.mounting === 'Completed' ? (
                            <Typography
                                display={'inline'}
                                component={Link}
                                fontWeight={600}
                                to={`/mounting/scheduler?orderId=${value.orderId}`}
                                sx={{
                                    textDecoration: 'inherit',
                                    color: 'white',
                                    bgcolor: handleGetStatusColor(value.mounting),
                                    borderRadius: '50px',
                                    textTransform: 'capitalize',
                                    fontSize: '12px !important',
                                    wordWrap: 'none',
                                    wordBreak: 'keep-all',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'center',
                                    padding: '2px 10px',
                                    display: 'inline-block',
                                    width: '100%'
                                }}
                            >
                                {value.mounting}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    bgcolor: handleGetStatusColor(value.mounting),
                                    color: 'white',
                                    borderRadius: '50px',
                                    textTransform: 'capitalize',
                                    fontSize: '12px !important',
                                    wordWrap: 'none',
                                    wordBreak: 'keep-all',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'center',
                                    padding: '2px 10px',
                                    display: 'inline-block',
                                    width: '100%'
                                }}
                            >
                                {value.mounting}
                            </Typography>
                        )}
                    </Tooltip>
                );
            }
        },
        {
            key: 'orderStatus',
            name: 'Status',
            width: handlePermissionCheck(userFieldsVisibility!, 'orderStatus') ? 100 : 0,
            minWidth: handlePermissionCheck(userFieldsVisibility!, 'orderStatus') ? 100 : 0,
            resizable: false,
            sortable: true,
            formatter(props) {
                const value = props.row;
                return (
                    <Tooltip
                        title={
                            value.orderStatus === 'On Hold' &&
                            value.onHoldReason !== null &&
                            value.onHoldReason !== 'null' &&
                            value.onHoldReason !== ''
                                ? `On Hold. Reason: ${value.onHoldReason}`
                                : (value.orderStatus === 'Cancelled' || value.orderStatus === 'Passive Req.') &&
                                  value.statusReason !== null &&
                                  value.statusReason !== 'null' &&
                                  value.statusReason !== ''
                                ? `${value.orderStatus}. Reason: ${value.statusReason}`
                                : value.orderStatus
                        }
                        placement="top"
                    >
                        <Typography
                            sx={{
                                bgcolor: handleGetStatusColor(value.orderStatus),
                                color: value.orderStatus === 'Cancelled' ? '#333333' : 'white',
                                borderRadius: '50px',
                                textTransform: 'capitalize',
                                fontSize: '12px !important',
                                wordWrap: 'none',
                                wordBreak: 'keep-all',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                padding: '2px 10px',
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >
                            {value.mainStatus !== 'Cancelled' ? <>{value.orderStatus}</> : <>{value.mainStatus}</>}
                        </Typography>
                    </Tooltip>
                );
            }
        }
    ];

    function createRows(): CompiledOrder[] {
        const rows: CompiledOrder[] = [];

        for (let i = 0; i < tableData.rows.length; i++) {
            rows.push({
                orderNum: `${tableData.rows[i].orderNum}`,
                orderIdNum: `${tableData.rows[i].orderIdNum}`,
                orderTitle: `${tableData.rows[i].orderTitle}`,
                clientTitle: `${tableData.rows[i].clientTitle}`,
                shippingAddress: `${tableData.rows[i].shippingAddress}`,
                ownerInitials: `${tableData.rows[i].ownerInitials}`,
                orderCreated: `${tableData.rows[i].orderCreated}`,
                shippingDate: `${tableData.rows[i].shippingDate}`,
                orderId: tableData.rows[i].orderId,
                clientID: tableData.rows[i].clientID,
                employeeId: tableData.rows[i].employeeId,
                orderOwner: tableData.rows[i].orderOwner,
                orderEmployees: tableData.rows[i].orderEmployees,
                shippingId: tableData.rows[i].shippingId,
                shippingStatus: `${tableData.rows[i].shippingStatus}`,
                shippingStatusVal: `${tableData.rows[i].shippingStatusVal}`,
                orderStatusVal: `${tableData.rows[i].orderStatusVal}`,
                shippingNo: `${tableData.rows[i].shippingNo}`,
                lastDrawingChangeDate: `${tableData.rows[i].lastDrawingChangeDate}`,
                drawings: `${tableData.rows[i].drawings}`,
                drawingsComment: `${tableData.rows[i].drawingsComment}`,
                mounting: `${tableData.rows[i].mounting}`,
                mountingComment: `${tableData.rows[i].mountingComment}`,
                orderStatus: `${tableData.rows[i].orderStatus}`,
                mainStatus: `${tableData.rows[i].mainStatus}`,
                onHoldReason: `${tableData.rows[i].onHoldReason}`,
                statusReason: `${tableData.rows[i].statusReason}`,
                countryId: tableData.rows[i].countryId,
                factoryId: tableData.rows[i].factoryId,
                productCount: tableData.rows[i].productCount,
                requiresAttention: tableData.rows[i].requiresAttention,
                factory: undefined
            });
        }

        return rows;
    }

    const initialTableData: CompiledOrder[] = createRows();
    const [filteredData, setFilteredData] = useState<CompiledOrder[]>(initialTableData);

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
        setSortColumns([...sortColumns.slice(-1)]);
    }, []);

    const sortedRows = useMemo((): readonly CompiledOrder[] => {
        let sortedRows: CompiledOrder[] = [...filteredData];

        if (sortColumns.length > 0) {
            const { columnKey, direction } = sortColumns[0];
            switch (columnKey) {
                case 'orderNum':
                    sortedRows = sortedRows.sort((a, b) => {
                        const regex = /^(\d+)(.*)$/; // Regex to capture the initial digits and any following text

                        const matchA = a['orderIdNum'].match(regex);
                        const matchB = b['orderIdNum'].match(regex);

                        // Check if both matches are successful
                        if (matchA && matchB) {
                            const numA = parseInt(matchA[1], 10);
                            const numB = parseInt(matchB[1], 10);

                            // First compare the numeric parts
                            if (numA !== numB) {
                                return numA - numB; // Ascending order
                            }

                            // If numeric parts are equal, fall back to comparing the full string
                            return a['orderIdNum'].localeCompare(b['orderIdNum']);
                        } else {
                            // Fallback to string comparison if either orderIdNum doesn't match the expected format
                            return a['orderIdNum'].localeCompare(b['orderIdNum']);
                        }
                    });
                    break;
                case 'clientTitle':
                    sortedRows = sortedRows.sort((a, b) => {
                        return a[columnKey].localeCompare(b[columnKey]);
                    });
                    break;
                case 'orderCreated':
                    sortedRows = sortedRows.sort((a, b) => {
                        // Convert Unix timestamp strings to numbers for primary comparison
                        const timeA = parseInt(a[columnKey], 10);
                        const timeB = parseInt(b[columnKey], 10);

                        if (timeA !== timeB) {
                            return timeA - timeB;
                        }

                        // If 'orderCreated' times are the same, then sort by 'orderIdNum'
                        const regex = /^(\d+)(.*)$/; // Regex to capture the initial digits and any following text

                        const matchA = a['orderIdNum'].match(regex);
                        const matchB = b['orderIdNum'].match(regex);

                        if (matchA && matchB) {
                            const numA = parseInt(matchA[1], 10);
                            const numB = parseInt(matchB[1], 10);

                            // First compare the numeric parts
                            if (numA !== numB) {
                                return numA - numB; // For consistent sorting with the backend, consider direction here
                            }

                            // If numeric parts are equal, fall back to comparing the full string
                            return a['orderIdNum'].localeCompare(b['orderIdNum']);
                        } else {
                            // Fallback to string comparison if either orderIdNum doesn't match the expected format
                            return a['orderIdNum'].localeCompare(b['orderIdNum']);
                        }
                    });
                    break;
                case 'shippingDate':
                    sortedRows = sortedRows.sort((a, b) => {
                        return a[columnKey].localeCompare(b[columnKey]);
                    });
                    break;
                case 'shippingNo':
                    sortedRows = sortedRows.sort((a, b) => {
                        return a[columnKey].localeCompare(b[columnKey]);
                    });
                    break;
                case 'lastDrawingChangeDate':
                    sortedRows = sortedRows.sort((a, b) => {
                        return b[columnKey].localeCompare(a[columnKey]);
                    });
                    break;
                case 'drawings':
                    sortedRows = sortedRows.sort((a, b) => {
                        return a[columnKey].localeCompare(b[columnKey]);
                    });
                    break;
                case 'mounting':
                    sortedRows = sortedRows.sort((a, b) => {
                        return a[columnKey].localeCompare(b[columnKey]);
                    });
                    break;
                case 'orderStatus':
                    sortedRows = sortedRows.sort((a, b) => {
                        return a[columnKey].localeCompare(b[columnKey]);
                    });
                    break;
                default:
            }
            sortedRows = direction === 'DESC' ? sortedRows.reverse() : sortedRows;
        }

        sortedRows = sortedRows.sort((a, b) => {
            return a['requiresAttention'] === b['requiresAttention'] ? 0 : a['requiresAttention'] ? -1 : 1;
        });

        return sortedRows;
    }, [filteredData, sortColumns]);

    useEffect(() => {
        setFilteredData(createRows());
    }, [tableData]);

    const [selectedRows, setSelectedRows] = useAtom(selectedRowsAtom);
    const [filterCriteria] = useAtom(filterCriteriaAtom);

    useEffect(() => {
        // Combine the filtering logic here using both filter criteria
        const filtered = initialTableData.filter((item) => {
            const searchTerm = filterCriteria.orderNum.toLowerCase();
            const itemOrderNum = item.orderNum.toLowerCase();
            const itemOrdertitle = item.orderTitle.toLowerCase();
            const clientTitle = item.clientTitle.toLowerCase();
            const orderOwner = filterCriteria.orderOwner;
            const orderEmployee = filterCriteria.orderEmployee;
            const country = filterCriteria.country;
            const factory = filterCriteria.factory;
            const mainStatus = filterCriteria.mainStatus;
            const orderStatus = filterCriteria.orderStatus;
            const shippingStatus = filterCriteria.shippingStatus;
            const requiresAttention = filterCriteria.requiresAttention;

            return (
                (searchTerm === '' ||
                    itemOrderNum.includes(searchTerm) ||
                    searchTerm === '' ||
                    itemOrdertitle.includes(searchTerm) ||
                    searchTerm === '' ||
                    clientTitle.includes(searchTerm)) &&
                (orderOwner === 'All' || item.employeeId.toString() === orderOwner) &&
                (orderEmployee === 'All' || item.orderEmployees.includes(parseInt(orderEmployee))) &&
                (country === 'All' || item.countryId.toString() === country) &&
                (factory === 'All' || item.factoryId.toString() === factory) &&
                (mainStatus === 'All' || item.mainStatus.toString() === mainStatus) &&
                (orderStatus === 'All' || item.orderStatusVal.toString() === orderStatus) &&
                (shippingStatus === 'All' || item.shippingStatusVal.toString() === shippingStatus) &&
                (requiresAttention === false || item.requiresAttention === requiresAttention)
            );
        });

        // Update the filtered data
        setFilteredData(filtered);
    }, [filterCriteria, tableData]);

    return (
        <Grid sx={{ height: 'calc(100vh - 157px)' }}>
            <DataGrid
                style={{
                    height: '100%',
                    scrollbarGutter: 'stable'
                }}
                columns={column}
                rows={sortedRows}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                rowKeyGetter={(row: CompiledOrder) => {
                    return row.orderId.toString();
                }}
                className="fill-grid rdg-light"
                sortColumns={sortColumns}
                onSortColumnsChange={onSortColumnsChange}
            />
        </Grid>
    );
};

export const OrderDrawer = NiceModal.create(({ orderId }: { orderId: number }) => {
    const modal = useModal();

    return (
        <Drawer
            open={modal.visible}
            onClose={modal.remove}
            anchor="right"
            sx={{ minWidth: '90%', width: '100%' }}
            className="order-drawer"
            PaperProps={{
                sx: {
                    width: '100%'
                }
            }}
        >
            <DrawerCard title="View order" onClose={modal.remove} sx={{ overflowX: 'hidden', minWidth: '90%' }}>
                <OrderManagePage orderId={orderId} handleClose={modal.remove} />
            </DrawerCard>
        </Drawer>
    );
});

export default OrdersTable;
