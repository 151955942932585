import { Chat, SvgIconComponent } from '@mui/icons-material';
import { Badge, Chip, List, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';

type Props = {
    Icon: SvgIconComponent;
    chipLabel: string;
    label?: string;
    onClick: () => void;
} & ListItemButtonProps;

const MenuButton = ({ Icon, chipLabel, label, onClick, ...props }: Props) => {
    return (
        <List>
            <Badge
                badgeContent={chipLabel}
                color={'error'}
                showZero
                sx={{
                    mx: 2,
                    mr: 1,
                    '& .MuiBadge-badge': {
                        right: 3,
                        top: 8
                    }
                }}
            >
                <ListItemButton
                    sx={(theme) => ({
                        borderRadius: `36px`,
                        border: `1px solid ${theme.palette.primary.light}`,
                        py: 0,
                        my: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'inherit',
                        color: 'black !important',
                        position: 'relative'
                    })}
                    color="primary"
                    onClick={onClick}
                    {...props}
                >
                    <ListItemIcon
                        sx={{
                            my: 'auto',
                            minWidth: 20,
                            mr: '6px',
                            '& path': { color: 'black' }
                        }}
                        color="black"
                    >
                        <Icon sx={{ height: 20 }} />
                    </ListItemIcon>
                    {label && (
                        <ListItemText
                            primary={
                                <Typography
                                    variant={'body1'}
                                    color="inherit"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textDecoration: 'none'
                                    }}
                                >
                                    {label}
                                </Typography>
                            }
                        />
                    )}
                </ListItemButton>
            </Badge>
        </List>
    );
};

export default MenuButton;
