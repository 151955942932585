// material-ui
import LogoSvg from '../assets/images/logo.svg';
import { Box, BoxProps } from '@mui/material';

type Props = { caption?: boolean } & BoxProps;

const Logo = ({ caption = false, ...props }: Props) => {
    return <Box {...props} sx={{ width: 242, height: 36, ...props.sx }} component={'img'} src={caption ? LogoSvg : LogoSvg} />;
};

export default Logo;
