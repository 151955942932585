import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material';
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';

import { FC, PropsWithChildren, useCallback, useMemo } from 'react';

// Import the Slate editor factory.
import { BaseEditor, createEditor, Descendant, Editor, Element as SlateElement, Transforms } from 'slate';

import { HistoryEditor, withHistory } from 'slate-history';

// Import the Slate components and React plugin.
import {
    Code,
    FormatAlignCenter,
    FormatAlignJustify,
    FormatAlignLeft,
    FormatAlignRight,
    FormatBold,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuote,
    FormatUnderlined,
    LooksOne,
    LooksTwo,
    Photo
} from '@mui/icons-material';
import isHotkey from 'is-hotkey';
import {
    Editable,
    ReactEditor,
    RenderElementProps,
    Slate,
    useFocused,
    useSelected,
    useSlate,
    useSlateStatic,
    withReact
} from 'slate-react';
import type { CustomElement, ImageElement } from '../../types/overrides/slate';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
    'mod+1': 'heading-one',
    'mod+2': 'heading-two',
    'mod+shift+k': 'block-quote',
    'mod+shift+i': 'image'
};

const markFormats = ['bold', 'italic', 'underline', 'code'];
const blockFormats = ['heading-one', 'heading-two', 'block-quote', 'numbered-list', 'bulleted-list', 'left', 'center', 'right', 'justify'];

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

interface Props {
    initialValue: Descendant[];
    setValue?: (value: string) => void;
    readOnly?: boolean;
    gutter?: boolean;
}

const SlateWysiwyg: FC<PropsWithChildren<Props>> = ({ children, initialValue, setValue, readOnly = false }) => {
    const renderElement = useCallback((props: any) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
    const editor = useMemo(() => withImages(withHistory(withReact(createEditor()))), []);
    return (
        <Slate
            editor={editor}
            value={initialValue}
            onChange={(value) => {
                const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
                if (isAstChange) {
                    const JSONvalue = JSON.stringify(value);
                    if (setValue) setValue(JSONvalue);
                }
            }}
        >
            {!readOnly && (
                <>
                    <Box
                        sx={{
                            py: 2,
                            px: 1
                        }}
                    >
                        <Tooltip title="Ctrl+B">
                            <span>
                                <MarkButton format="bold" Icon={<FormatBold />} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Ctrl+I">
                            <span>
                                <MarkButton format="italic" Icon={<FormatItalic />} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Ctrl+U">
                            <span>
                                <MarkButton format="underline" Icon={<FormatUnderlined />} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Ctrl+`">
                            <span>
                                <MarkButton format="code" Icon={<Code />} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Ctrl+1">
                            <span>
                                <BlockButton format="heading-one" Icon={<LooksOne />} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Ctrl+2">
                            <span>
                                <BlockButton format="heading-two" Icon={<LooksTwo />} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Ctrl+Shift+K">
                            <span>
                                <BlockButton format="block-quote" Icon={<FormatQuote />} />
                            </span>
                        </Tooltip>
                        <BlockButton format="numbered-list" Icon={<FormatListNumbered />} />
                        <BlockButton format="bulleted-list" Icon={<FormatListBulleted />} />
                        <BlockButton format="left" Icon={<FormatAlignLeft />} />
                        <BlockButton format="center" Icon={<FormatAlignCenter />} />
                        <BlockButton format="right" Icon={<FormatAlignRight />} />
                        <BlockButton format="justify" Icon={<FormatAlignJustify />} />
                        <Tooltip title="Ctrl+Shift+I">
                            <span>
                                <InsertImageButton editor={editor} />
                            </span>
                        </Tooltip>
                        {children}
                    </Box>
                    <Divider />
                </>
            )}
            <Box
                sx={{
                    p: 2,
                    '& div': {
                        minHeight: 400
                    },
                    '*': { whiteSpace: 'pre-wrap !important' },
                    '& h1': {
                        display: 'block !important',
                        fontSize: '2em !important',
                        marginTop: '0.67em !important',
                        marginBottom: '0.67em !important',
                        marginLeft: '0 !important',
                        marginRight: '0 !important',
                        fontWeight: 'bold !important'
                    },
                    '& h2': {
                        display: 'block !important',
                        fontSize: '1.5em !important',
                        marginTop: '0.83em !important',
                        marginBottom: '0.83em !important',
                        marginLeft: '0 !important',
                        marginRight: '0 !important',
                        fontWeight: 'bold !important'
                    },
                    '& h3': {
                        display: 'block !important',
                        fontSize: '1.17em !important',
                        marginTop: '1em !important',
                        marginBottom: '1em !important',
                        marginLeft: '0 !important',
                        marginRight: '0 !important',
                        fontWeight: 'bold !important'
                    },
                    '& h4': {
                        display: 'block !important',
                        marginTop: '1.33em !important',
                        marginBottom: '1.33em !important',
                        marginLeft: '0 !important',
                        marginRight: '0 !important',
                        fontWeight: 'bold !important'
                    },
                    '& h5': {
                        display: 'block !important',
                        fontSize: '.83em !important',
                        marginTop: '1.67em !important',
                        marginBottom: '1.67em !important',
                        marginLeft: '0 !important',
                        marginRight: '0 !important',
                        fontWeight: 'bold !important'
                    },
                    '& h6': {
                        display: 'block !important',
                        fontSize: '.67em !important',
                        marginTop: '2.33em !important',
                        marginBottom: '2.33em !important',
                        marginLeft: '0 !important',
                        marginRight: '0 !important',
                        fontWeight: 'bold !important'
                    },
                    '& p': {
                        display: 'block !important',
                        marginTop: '1em !important',
                        marginBottom: '1em !important',
                        marginLeft: '0 !important',
                        marginRight: '0 !important'
                    }
                }}
            >
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    spellCheck
                    autoFocus
                    readOnly={readOnly}
                    onKeyDown={(event) => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event as any)) {
                                event.preventDefault();
                                const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
                                if (markFormats.includes(mark)) {
                                    toggleMark(editor, mark);
                                } else if (blockFormats.includes(mark)) {
                                    toggleBlock(editor, mark);
                                } else if (mark === 'image') {
                                    const url = window.prompt('Enter the URL of the image:');
                                    if (url && !isImageUrl(url)) {
                                        alert('URL is not an image');
                                        return;
                                    }
                                    url && insertImage(editor, url);
                                }
                            }
                        }
                    }}
                />
            </Box>
        </Slate>
    );
};

const toggleBlock = (editor: BaseEditor & ReactEditor & HistoryEditor, format: string) => {
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
        split: true
    });
    let newProperties: any;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format
        };
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format
        };
    }
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
        const block: CustomElement = {
            // @ts-ignore
            type: format,
            children: [{ text: '' }]
        };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor: BaseEditor & ReactEditor & HistoryEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor: BaseEditor & ReactEditor & HistoryEditor, format: string, blockType = 'type') => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n: any) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType as keyof typeof n] === format
        })
    );

    return !!match;
};

const isMarkActive = (editor: BaseEditor & ReactEditor & HistoryEditor, format: string) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format as keyof typeof marks] === true : false;
};

const Element = ({ attributes, children, element }: RenderElementProps) => {
    // @ts-ignore
    const style = { textAlign: element.align };
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        //  @ts-ignore
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        case 'image':
            return <Image children={children} attributes={attributes} element={element} />;
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
    }
};

const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, Icon }: { format: string; Icon: JSX.Element }) => {
    const editor = useSlate();
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
    return (
        <IconButton
            sx={{ opacity: isActive ? 1 : 0.6, mx: 0.5 }}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            {Icon}
        </IconButton>
    );
};

const MarkButton = ({ format, Icon }: { format: string; Icon: JSX.Element }) => {
    const editor = useSlate();
    const isActive = isMarkActive(editor, format);
    return (
        <IconButton
            sx={{ opacity: isActive ? 1 : 0.6, mx: 0.5 }}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            {Icon}
        </IconButton>
    );
};

const withImages = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
    const { insertData, isVoid } = editor;

    editor.isVoid = (element) => {
        return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = (data) => {
        const text = data.getData('text/plain');
        const { files } = data;

        if (files && files.length > 0) {
            // @ts-ignore
            for (const file of files) {
                const reader = new FileReader();
                const [mime] = file.type.split('/');

                if (mime === 'image') {
                    reader.addEventListener('load', () => {
                        const url = reader.result;
                        if (typeof url == 'string') insertImage(editor, url);
                    });

                    reader.readAsDataURL(file);
                }
            }
        } else if (isImageUrl(text)) {
            insertImage(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};

const insertImage = (editor: BaseEditor & ReactEditor & HistoryEditor, url: string) => {
    const text = { text: '' };
    const image: ImageElement = { type: 'image', url, children: [text] };
    Transforms.insertNodes(editor, image);
};

const Image = ({ attributes, children, element }: RenderElementProps) => {
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);
    const selected = useSelected();
    const focused = useFocused();
    return (
        <Box {...attributes}>
            {children}
            <Box contentEditable={false} sx={{ position: 'relative' }}>
                <img
                    // @ts-ignore
                    src={element.url}
                    style={{
                        display: 'block',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
                    }}
                    alt="Provided"
                />
                <Button
                    onMouseDown={() => Transforms.removeNodes(editor, { at: path })}
                    sx={{
                        display: selected && focused ? 'block' : 'none',
                        position: 'absolute',
                        top: '0.5em',
                        left: '0.5em'
                    }}
                    variant="contained"
                >
                    Delete
                </Button>
            </Box>
        </Box>
    );
};

const InsertImageButton = ({ editor }: { editor: BaseEditor & ReactEditor & HistoryEditor }) => {
    return (
        <IconButton
            sx={{ mx: 0.5 }}
            onClick={(event) => {
                event.preventDefault();
                const url = window.prompt('Enter the URL of the image:');
                if (url && !isImageUrl(url)) {
                    alert('URL is not an image');
                    return;
                }
                url && insertImage(editor, url);
            }}
        >
            <Photo />
        </IconButton>
    );
};

const isImageUrl = (url: string) => {
    if (!url) return false;
    if (!isUrl(url)) return false;
    const ext = new URL(url).pathname.split('.').pop();
    return imageExtensions.includes(ext!);
};

export default SlateWysiwyg;
