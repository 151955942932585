import { NavItemType } from 'types/index.types';
import { LocalShipping } from '@mui/icons-material';

import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { jotaiStore } from 'App';
import { filterCriteriaAtom } from 'ui-component/shipping/ShippingTable';

const ShippingTablePage = Loadable(lazy(() => import('views/pages/shipping/ShippingTablePage')));

const shippingList: NavItemType = {
    id: 'shippingList',
    title: 'Shipping List',
    icon: LocalShipping,
    type: 'item',
    url: '/shipping',
    breadcrumbs: false,
    component: <ShippingTablePage />,
    onClick: () =>
        jotaiStore.set(filterCriteriaAtom, {
            shippingNum: '',
            factory: 'All',
            country: 'All',
            status: 'All'
        }),
    probhitedRoles: ['client', 'mounting_worker']
};

export default shippingList;
