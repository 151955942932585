import { bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
// import mapReducer from '../slices/map/map.slice';
import * as lightboxActions from '../store/slices/lightbox';
// import * as schoolActions from '../slices/school/school.actions';
// import { schoolReducer } from '../slices/school/school.slice';

const rootActions = {
    ...lightboxActions
};

const useActions = () => {
    const dispatch = useDispatch();

    return useMemo(
        () => bindActionCreators(rootActions, dispatch),

        [dispatch]
    );
};

export default useActions;
