import { Dashboard } from '@mui/icons-material';
import { NavItemType } from 'types/index.types';

import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const DashboardPage = Loadable(lazy(() => import('views/pages/dashboard/DashboardPage')));

const orders: NavItemType = {
    id: 'dashboard',
    title: 'Dashboard',
    icon: Dashboard,
    type: 'item',
    url: '/dashboard',
    breadcrumbs: false,
    component: <DashboardPage />
};

export default orders;
