export const rolesExplanation = [
    {
        id: 'super_admin',
        title: 'Super Admin',
        explanation: 'Can Create, Read, Update and Delete without limits. Including Deleting or Updating other Super Admins.'
    },
    {
        id: 'country_manager',
        title: 'Country Manager',
        explanation:
            'Can Create, Read, Update and Delete All Records within the Countries user is assigned to or record to which the user is added directly.'
    },
    {
        id: 'factory_worker',
        title: 'Factory Worker',
        explanation:
            'Can Create, Read, Update and Delete All Records within the Factories user is assigned to or record to which the user is added directly.'
    },
    {
        id: 'designer_admin',
        title: 'Designer Admin',
        explanation:
            'Can Create, Read, Update and Delete All Records within the Countries and Factories user is assigned to or record to which the user is added directly.'
    },
    {
        id: 'client',
        title: 'Client',
        explanation: 'Can See Limited, Personally attached Records.'
    },
    {
        id: 'other',
        title: 'All Other Roles',
        explanation: 'Can Create, Read, Update and Delete only records to which the user is added directly.'
    }
];
