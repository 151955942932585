import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import User1 from 'assets/images/users/user-round.svg';
import useAuth from 'hooks/useAuth';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import NiceModal from '@ebay/nice-modal-react';
import { Logout, Notes, Settings } from '@mui/icons-material';
import { slugToLabel } from 'helpers/global.helper';
import useConfig from 'hooks/useConfig';
import { toast } from 'react-toastify';
import { useFetchVersionQuery } from 'store/api/system.api';
import { VersionLogModal } from 'views/pages/version/VersionLogPage';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { logout, user } = useAuth();

    const { data: version } = useFetchVersionQuery({ user_id: user?.id! }, { skip: !user });
    const [versionRead, setVersionRead] = useState(true);
    const isVersionRead = (localStorage.getItem('current_version_read') === 'true' ? true : false) && versionRead;

    useEffect(() => {
        if (!version) return;
        const currentVersion = localStorage.getItem('current_version');
        const currentVersionRead = localStorage.getItem('current_version_read') === 'true' ? true : false;

        if (currentVersion === null) {
            localStorage.setItem('current_version', version.version);
            localStorage.setItem('current_version_read', 'false');
            return;
        }

        if (currentVersion === version.version && currentVersionRead === true) setVersionRead(true);
        else if (currentVersion !== version.version) {
            localStorage.setItem('current_version', version.version);
            localStorage.setItem('current_version_read', 'false');
            if (version.notifications_disabled !== true)
                toast.info(`Scaleerp updated to version ${version.version}. Click to learn more`, {
                    onClick: () => {
                        NiceModal.show(VersionLogModal);

                        if (currentVersionRead === false) {
                            localStorage.setItem('current_version_read', 'true');
                            return;
                        }
                    }
                });
        } else setVersionRead(false);
    }, [version]);

    const [open, setOpen] = useState(false);

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);
    const handleLogout = async () => {
        try {
            await logout(true);
        } catch (err) {
            console.error(err);
        }
    };

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, route: string = '') => {
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Badge
                color="error"
                variant="dot"
                overlap="circular"
                sx={{
                    '& .MuiBadge-badge': {
                        right: 13,
                        top: 13,
                        width: 10,
                        height: 10,
                        display: isVersionRead ? 'none' : 'initial'
                    }
                }}
            >
                <Avatar
                    src={user?.avatar || User1}
                    sx={{
                        ...theme.typography.mediumAvatar,
                        cursor: 'pointer',
                        m: 1,
                        width: 28,
                        height: 28
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                    alt="user-images"
                />
            </Badge>
            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            {open && (
                                <Paper elevation={4} sx={{ boxShadow: 12 }}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Good Morning,</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {user?.name}
                                                </Typography>
                                            </Stack>
                                            {user?.role && <Typography variant="subtitle2">{slugToLabel(user?.role)}</Typography>}
                                        </Stack>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ p: 2, pt: 0 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `${borderRadius}px` }}
                                                selected={pathname === '/user/manage'}
                                                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                    handleListItemClick(event, '/user/manage')
                                                }
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        color: 'black !important'
                                                    }}
                                                >
                                                    <Settings sx={{ width: '1.3rem', height: '1.3rem' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Account settings</Typography>} />
                                            </ListItemButton>
                                            {user?.role !== 'client' && (
                                                <>
                                                    {version && (
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            onClick={() => {
                                                                setOpen(false);
                                                                NiceModal.show(VersionLogModal);
                                                                const currentVersionRead =
                                                                    localStorage.getItem('current_version_read') === 'true' ? true : false;

                                                                if (currentVersionRead === false) {
                                                                    localStorage.setItem('current_version_read', 'true');
                                                                    return;
                                                                }
                                                            }}
                                                        >
                                                            <ListItemIcon sx={{ color: 'black !important' }}>
                                                                <Notes sx={{ width: '1.3rem', height: '1.3rem' }} />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            gap: 0.5
                                                                        }}
                                                                    >
                                                                        <Typography variant="body2">Version Log</Typography>
                                                                    </Box>
                                                                }
                                                            />
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    height: '100%',
                                                                    mt: 0.5,
                                                                    p: 1,
                                                                    bgcolor: isVersionRead ? 'initial' : 'red',
                                                                    color: isVersionRead ? 'initial' : 'white',
                                                                    borderRadius: `${borderRadius}px`
                                                                }}
                                                            >
                                                                <Typography variant="h5" color={'inherit'}>
                                                                    {version?.version}
                                                                </Typography>
                                                            </Box>
                                                        </ListItemButton>
                                                    )}
                                                </>
                                            )}
                                            <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} onClick={handleLogout}>
                                                <ListItemIcon
                                                    sx={{
                                                        color: 'black !important'
                                                    }}
                                                >
                                                    <Logout sx={{ width: '1.3rem', height: '1.3rem' }} />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Log out</Typography>} />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </Paper>
                            )}
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
