export const messages = {
    success: {
        added: 'Successfully added',
        deleted: 'Successfully deleted',
        edited: 'Successfully edited',
        saved: 'Successfully saved',
        updated: 'Successfully updated',
        fetched: 'Successfully fetched',
        submitted: 'Successfully submitted',
        imported: 'Successfully imported',
        exported: 'Successfully exported',
        created: 'Successfully created',
        loaded: 'Successfully loaded',
        uploaded: 'Successfully uploaded',
        downloaded: 'Successfully downloaded'
    },
    error: {
        addError: 'Error adding',
        deleteError: 'Error deleting',
        editError: 'Error editing',
        saveError: 'Error saving',
        updateError: 'Error updating',
        fetchError: 'Error fetching',
        submitError: 'Error submitting',
        importError: 'Error importing',
        exportError: 'Error exporting',
        loadingError: 'Error loading',
        createError: 'Error creating',
        uploadError: 'Error uploading',
        downloadError: 'Error downloading'
    },
    warning: {
        deleteConfirmation: "You won't be able to revert this!",
        unsavedChanges: 'You have unsaved changes. Are you sure you want to leave?'
    },
    pending: {
        addPending: 'Adding...',
        deletePending: 'Deleting...',
        editPending: 'Editing...',
        savePending: 'Saving...',
        updatePending: 'Updating...',
        fetchPending: 'Fetching...',
        submitPending: 'Submitting...',
        importPending: 'Importing...',
        exportPending: 'Exporting...',
        createPending: 'Creating...',
        loadingPending: 'Loading...',
        uploadPending: 'Uploading...',
        downloadPending: 'Downloading...'
    }
};

export type MessageKey =
    | keyof typeof messages.success
    | keyof typeof messages.error
    | keyof typeof messages.warning
    | keyof typeof messages.pending;

export function getMessage(key: MessageKey): string {
    // @ts-ignore
    return messages.success[key] || messages.error[key] || messages.warning[key] || messages.pending[key] || 'Message not found';
}
