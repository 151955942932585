import { Box } from '@mui/material';

interface CellExpanderFormatterProps {
    tabIndex: number;
    expanded: boolean;
    onCellExpand: () => void;
}

export function CellExpanderFormatter({ tabIndex, expanded, onCellExpand }: CellExpanderFormatterProps) {
    function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onCellExpand();
        }
    }

    return (
        <Box
            sx={{
                float: 'right',
                display: 'table',
                blockSize: '100%',
                span: {
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    cursor: 'pointer'
                }
            }}
        >
            <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
                <span tabIndex={tabIndex}>{expanded ? '\u25BC' : '\u25B6'}</span>
            </span>
        </Box>
    );
}
