// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import ThemeCustomization from 'themes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-chat-elements/dist/main.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'react-contexify/dist/ReactContexify.css';
import { createStore, Provider as JotaiProvider } from 'jotai';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import NiceModal from '@ebay/nice-modal-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LightboxProvider from 'providers/LightboxProvider';
import 'utils/modals';
import { ScrollingProvider } from 'react-scroll-section';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'style.css';
import Wrapper from 'Wrapper';

// ==============================|| APP ||============================== //

export const jotaiStore = createStore();

const App = () => (
    <ThemeCustomization>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <ScrollingProvider offset={-140}>
                            <LightboxProvider>
                                <JotaiProvider store={jotaiStore}>
                                    <NiceModal.Provider>
                                        <>
                                            <Wrapper>
                                                <Routes />
                                            </Wrapper>
                                            <ToastContainer autoClose={2000} position="bottom-right" />
                                        </>
                                    </NiceModal.Provider>
                                </JotaiProvider>
                            </LightboxProvider>
                        </ScrollingProvider>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </LocalizationProvider>
    </ThemeCustomization>
);

export default App;
